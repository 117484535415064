import { FC, HTMLAttributes } from 'react'
import { useTheme } from '@mui/material/styles'

import { LoaderSize } from './Loader.types'
import * as styles from './styles'

export type LoaderProps = {
  size?: LoaderSize
} & Pick<HTMLAttributes<HTMLDivElement>, 'aria-label'>

const Loader: FC<LoaderProps> = ({ size = 24, 'aria-label': ariaLabel }) => {
  const theme = useTheme()

  return (
    <div role="img" aria-label={ariaLabel} css={styles.loader(size)}>
      <div css={styles.circle1(theme)} />
      <div css={styles.circle2(theme)} />
      <div css={styles.circle3(theme)} />
      <div css={styles.circle4(theme)} />
    </div>
  )
}
export default Loader
