// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const BunksDefault = svgLazyLoader('sleeping/bunks_default')
const HightFirstClass = svgLazyLoader('sleeping/hight_first_class')
const HightSecondClass = svgLazyLoader('sleeping/hight_second_class')
const Hostel = svgLazyLoader('sleeping/hostel')
const LowFirstClass = svgLazyLoader('sleeping/low_first_class')
const LowRequired = svgLazyLoader('sleeping/low_required')
const LowSecondClass = svgLazyLoader('sleeping/low_second_class')
const MiddleSecondClass = svgLazyLoader('sleeping/middle_second_class')

const ALL_SLEEPING_ICONS = [
  'bunks_default',
  'hight_first_class',
  'hight_second_class',
  'hostel',
  'low_first_class',
  'low_required',
  'low_second_class',
  'middle_second_class',
] as const
export type SleepingIcon = (typeof ALL_SLEEPING_ICONS)[number]

const isSleepingIcon = (sleepingKey: string): sleepingKey is SleepingIcon =>
  ALL_SLEEPING_ICONS.includes(sleepingKey as SleepingIcon)

const ICONS: LazyIconsGroup<SleepingIcon> = {
  bunks_default: BunksDefault,
  hight_first_class: HightFirstClass,
  hight_second_class: HightSecondClass,
  hostel: Hostel,
  low_first_class: LowFirstClass,
  low_required: LowRequired,
  low_second_class: LowSecondClass,
  middle_second_class: MiddleSecondClass,
}

export const getSleepingIcon = (sleepingKey: string | undefined): LazyIcon | undefined =>
  sleepingKey && isSleepingIcon(sleepingKey) ? ICONS[sleepingKey] : undefined

export const getSleepingIcons = (): LazyIconsGroup<SleepingIcon> => ICONS
