// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const SatHappy = svgLazyLoader('satisfaction/sat_happy')
const SatNeutral = svgLazyLoader('satisfaction/sat_neutral')
const SatUnhappy = svgLazyLoader('satisfaction/sat_unhappy')

const ALL_SATISFACTION_ICONS = ['sat_happy', 'sat_neutral', 'sat_unhappy'] as const
export type SatisfactionIcon = (typeof ALL_SATISFACTION_ICONS)[number]

const isSatisfactionIcon = (satisfactionKey: string): satisfactionKey is SatisfactionIcon =>
  ALL_SATISFACTION_ICONS.includes(satisfactionKey as SatisfactionIcon)

const ICONS: LazyIconsGroup<SatisfactionIcon> = {
  sat_happy: SatHappy,
  sat_neutral: SatNeutral,
  sat_unhappy: SatUnhappy,
}

export const getSatisfactionIcon = (satisfactionKey: string | undefined): LazyIcon | undefined =>
  satisfactionKey && isSatisfactionIcon(satisfactionKey) ? ICONS[satisfactionKey] : undefined

export const getSatisfactionIcons = (): LazyIconsGroup<SatisfactionIcon> => ICONS
