import Locale from '@Types/locale'

export const DEFAULT_LOCALE: Locale = 'fr-fr'

// This record should contain all locales. Record type makes it break if a locale is missing
const allLocalesRecord: Record<Locale, true> = {
  'fr-fr': true,
  'fr-be': true,
  'en-be': true,
  'de-de': true,
  'it-it': true,
  'fr-lu': true,
  'fr-ch': true,
  'de-ch': true,
  'en-ch': true,
  'en-en': true,
  'es-es': true,
  'nl-be': true,
  'nl-nl': true,
}

export const allLocales = Object.keys(allLocalesRecord) as Locale[]
