import { createContext, FC, SVGProps, useContext, useMemo } from 'react'
import dynamic from 'next/dynamic'

import { LazyIcon, LazyIconProps } from '@DS/components/icons/icons.types'
import { ReactComponent as PlaceHolderIcon } from '@Svg/placeholder.svg'

import * as styles from './icons/styles'

type ContextType = Pick<LazyIconProps, 'aria-hidden' | 'className'> | null
const LoadingIconContext = createContext<ContextType | undefined>(undefined)

const useContextType = () => {
  const context = useContext(LoadingIconContext)

  if (context === undefined) {
    throw new Error('useContextType must be used within a LoadingIconContext')
  }

  return context
}

const LoadingIcon: FC = () => {
  const context = useContextType()

  return <PlaceHolderIcon {...(!!context && context)} />
}

export const svgLazyLoader = (subPath: string): LazyIcon => {
  const DynamicComponent = dynamic(
    async () =>
      (await import(`/node_modules/invictus-design-system-web/src/svg/${subPath.replace(/\.svg$/, '')}.svg`))
        .ReactComponent,
    {
      loading: () => <LoadingIcon />,
      ssr: false,
    }
  )

  const SuspenseLazySVG: FC<SVGProps<SVGSVGElement>> = (props) => {
    const partialsProps: ContextType = useMemo(() => {
      const { 'aria-hidden': ariaHidden, className } = props

      return { 'aria-hidden': ariaHidden, className }
    }, [props])

    return (
      <LoadingIconContext.Provider value={partialsProps}>
        <DynamicComponent {...props} css={styles.isWhcm()} />
      </LoadingIconContext.Provider>
    )
  }

  return SuspenseLazySVG as LazyIcon
}
