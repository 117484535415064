import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem } from 'polished'

import { GREEN_YELLOW } from '@Styles/constants/colors'

/*
 * Le divider Material ne doit plus être utilisé directement donc on lui met une couleur FLUO volontairement.
 * Il faut privilégier l'utilisation de designSystem/shapes/separator/Separator
 */
export const overridesDivider = (): ComponentsOverrides['MuiDivider'] => ({
  root: {
    borderColor: GREEN_YELLOW,
    height: rem(20),
  },
})
