import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { IconButton, InputAdornment } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as ClearIcon } from '@Svg/close.svg'

import * as styles from './styles'

export type ClearButtonProps = {
  name: string
  onClear: VoidFunction
  disabled?: boolean
  ariaLabel?: string
  fieldName?: string
}

const ClearButton: FC<ClearButtonProps> = ({ ariaLabel, name, onClear, disabled = false, fieldName, ...rest }) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  const [isFocusVisible, setIsFocusVisible] = useState(false)

  const setFocusOn = () => {
    setIsFocusVisible(true)
  }

  const setFocusOff = () => {
    setIsFocusVisible(false)
  }

  return (
    <InputAdornment position="end" {...rest}>
      <IconButton
        aria-label={
          ariaLabel ||
          (fieldName &&
            formatMessage(
              {
                id: 'input_clearButton_ariaLabel_with_fieldName',
              },
              { fieldName }
            )) ||
          formatMessage({
            id: 'input_clearButton_ariaLabel',
          })
        }
        css={[styles.inputButton(theme), styles.buttonFocus(theme, isFocusVisible)]}
        data-test={`${name}-clear-button`}
        onClick={onClear}
        disabled={disabled}
        size="large"
        onFocusVisible={setFocusOn}
        onBlur={setFocusOff}
      >
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  )
}

export default ClearButton
