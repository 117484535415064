import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'
import { COLORS } from 'src/widgets-external/booking/_internal/BookingHeader.types'

import { STANDARD_RADIUS } from '@DS/styles/constants/radius'
import { SPACING_1, SPACING_2 } from '@DS/styles/constants/spacing'
import { DARK_BLUE } from '@Styles/constants/colors'

const getHeaderColor = (theme: Theme, color: COLORS = 'default'): string =>
  color === 'default' ? DARK_BLUE : theme.palette.primary.main

export const bookingHeader = (theme: Theme, color?: COLORS): SerializedStyles =>
  css({
    alignItems: 'start',
    backgroundColor: getHeaderColor(theme, color),
    borderRadius: `${STANDARD_RADIUS} ${STANDARD_RADIUS} 0 0`,
    color: theme.palette.primary.contrastText,
    cursor: 'default',
    display: 'flex',
    flexFlow: 'column',
    height: rem(80),
    padding: `${SPACING_1} ${SPACING_2} 0`,
    position: 'relative',
    zIndex: 0,
  })

export const titleContainer = css({
  alignItems: 'center',
  cursor: 'default',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: SPACING_1,
})
