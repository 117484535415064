import { ReactNode } from 'react'

export const bffToHtmlId = (id: string): string => id.replace(/[, ]/g, '_')

// @see Inspired by https://gist.github.com/slavikshen/7b29b06215b9e7a08455
export const getInnerText = (children: ReactNode): string => {
  if (!children) {
    return ''
  }

  let text = ''
  let subChildren = null

  if (typeof children === 'string') {
    text += children
  } else if (typeof children === 'object' && Array.isArray(children)) {
    subChildren = children
  } else if (typeof children === 'object' && !Array.isArray(children)) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const { props } = children as any

    subChildren = props && props.children
  }

  if (subChildren && Array.isArray(subChildren)) {
    subChildren.forEach((subChild) => {
      text += getInnerText(subChild)
    })
  } else if (subChildren) {
    text += getInnerText(subChildren)
  }

  return text
}
