import {
  canUseNavigator,
  DeviceSettings as DeviceSettingsSdk,
  getTrackingDeviceType,
  getTrackingOs,
} from 'invictus-sdk-typescript'
import UAParser from 'ua-parser-js'

import { DEFAULT_MARKET } from '@Constants/market'
import Market from '@Types/market'

type AppIdType = 'front-web' | 'next'

type GetDeviceSettingsProps = {
  appId?: AppIdType
  clientMarket?: Market
  virtualEnvName?: string
}

const SSR_FALLBACK_VALUE = 'NC (SSR)'

const isSsr = (appId: AppIdType) => appId === 'next'

export const getDeviceSettings = ({
  appId = 'front-web',
  clientMarket = DEFAULT_MARKET,
  virtualEnvName = 'master',
}: GetDeviceSettingsProps = {}): DeviceSettingsSdk => {
  const userAgent = !isSsr(appId) && canUseNavigator() && new UAParser(window.navigator.userAgent)
  const userOs = userAgent && userAgent.getOS()

  return new DeviceSettingsSdk(
    clientMarket,
    'web',
    appId,
    (typeof process !== 'undefined' && process.env.ACCOUNT_TYPE) || 'production',
    userOs ? getTrackingOs(userOs.name, userOs.version) : SSR_FALLBACK_VALUE,
    userAgent ? getTrackingDeviceType(userAgent.getDevice().type) : SSR_FALLBACK_VALUE,
    virtualEnvName
  )
}
