import { FC } from 'react'
import { Input, InputAdornment, InputBaseProps, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useUuid from '@Hooks/useUuid'

import RemoveInputButton from './RemoveInputButton'
import * as styles from './styles'

export type InputPlaceProps = InputBaseProps & {
  placeholder: string
  prefix: string
  value?: string
  onClear: VoidFunction
}

const InputPlace: FC<InputPlaceProps> = ({ placeholder, prefix, value, onClear, id, ...rest }) => {
  const theme = useTheme()
  const inputUuid = useUuid()
  const inputId = id || inputUuid

  const showEndAdornment = !rest.disabled && !!value
  const endAdornment = showEndAdornment ? (
    <InputAdornment css={styles.suffix} position="end">
      <RemoveInputButton onClick={onClear} />
    </InputAdornment>
  ) : null

  const startAdornment = prefix ? (
    <InputAdornment css={styles.prefix(theme, rest.disabled)} position="start" component="label" htmlFor={inputId}>
      <Typography>{prefix}</Typography>
    </InputAdornment>
  ) : null

  return (
    <Input
      {...rest}
      css={styles.inputPlace(theme, rest.disabled)}
      disableUnderline
      endAdornment={endAdornment}
      placeholder={placeholder}
      startAdornment={startAdornment}
      type="text"
      value={value ?? ''}
      id={inputId}
    />
  )
}

export default InputPlace
