import { useEffect, useState } from 'react'

import Locale from '@Types/locale'
import { getTranslationMessages } from '@Utils/i18n'

type Messages = Record<string, string>

type AllMessagesState = {
  locale: string
  messages?: Messages
}

export const useAllMessages = (locale: Locale): AllMessagesState => {
  const [state, setState] = useState<AllMessagesState>({ locale })
  useEffect(() => {
    const getAllMessagesAsync = async () => {
      const messages = await getTranslationMessages(locale)
      setState({ locale, messages })
    }
    getAllMessagesAsync()
  }, [locale])

  return state
}
