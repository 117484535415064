import { cardActionAreaClasses } from '@mui/material/CardActionArea'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem } from 'polished'

import { STANDARD_RADIUS } from '@DS/styles/constants/radius'
import { SPACING_2 } from '@DS/styles/constants/spacing'
import { highContrast } from '@Styles/a11y'
import { getRawFocusVisibleStyle } from '@Styles/focus'
import { getInnerBorderRadius } from '@Utils/border'

export const FOCUS_OFFSET = rem(2)

export const overridesCard = (): ComponentsOverrides['MuiCard'] => ({
  root: {
    boxShadow: 'none',
    border: 'none',
  },
})

export const overridesCardContent = (): ComponentsOverrides['MuiCardContent'] => ({
  root: {
    padding: SPACING_2,
    '&:last-child': {
      paddingBottom: SPACING_2,
    },
  },
})

export const overridesCardActionArea = (theme: Theme): ComponentsOverrides['MuiCardActionArea'] => ({
  focusHighlight: {
    display: 'none',
  },
  root: {
    ...highContrast({
      border: 'solid 2px transparent',
      borderRadius: STANDARD_RADIUS,
    }),
    [`&.${cardActionAreaClasses.focusVisible}`]: {
      /**
       * First child has radius on top borders
       */
      '&:first-of-type::before': {
        borderTopLeftRadius: getInnerBorderRadius(STANDARD_RADIUS, FOCUS_OFFSET),
        borderTopRightRadius: getInnerBorderRadius(STANDARD_RADIUS, FOCUS_OFFSET),
      },
      /**
       * Default has no radius
       */
      '&::before': getRawFocusVisibleStyle(theme.palette.primary.main, '0'),
      /**
       * Last child has radius on bottom borders
       */
      '&:last-child::before': {
        borderBottomLeftRadius: getInnerBorderRadius(STANDARD_RADIUS, FOCUS_OFFSET),
        borderBottomRightRadius: getInnerBorderRadius(STANDARD_RADIUS, FOCUS_OFFSET),
      },
    },
    '&:hover': {
      backgroundColor: theme.customPalette.onElements.selected,
      ...highContrast({
        backgroundColor: 'HighLight',
      }),
    },
  },
})
