import { DEFAULT_LOCALE } from '@Constants/locale'
import { CountryForLocale } from '@Types/country'
import { LangForLocale, TranslationMessages } from '@Types/lang'
import Locale from '@Types/locale'

type ParseLocale<LocaleString extends Locale> = {
  lang: LangForLocale<LocaleString>
  country: CountryForLocale<LocaleString>
}

export const parseLocale = <LocaleString extends Locale>(locale: LocaleString): ParseLocale<LocaleString> =>
  ({
    lang: locale.split('-')[0],
    country: locale.split('-')[1],
  } as ParseLocale<LocaleString>)

const loadTranslationMessages = async <LocaleString extends Locale>(
  locale?: LocaleString
): Promise<TranslationMessages<LangForLocale<LocaleString>>> => {
  const { lang } = parseLocale(locale || DEFAULT_LOCALE)

  return (await import(`../i18n/generated/common-${lang}.json`)).default
}

export const getTranslationMessages = async <LocaleString extends Locale>(
  locale: LocaleString
): Promise<TranslationMessages<LangForLocale<LocaleString>>> => {
  try {
    if (locale === DEFAULT_LOCALE) {
      return await loadTranslationMessages()
    }

    const [localeMessages, fallbackMessages] = await Promise.all([
      loadTranslationMessages(locale),
      loadTranslationMessages(),
    ])

    return { ...fallbackMessages, ...localeMessages }
  } catch (error) {
    // TODO: Log error?
    // Fallback when the translation file doesn't exist
    return await loadTranslationMessages()
  }
}
