import { css, Keyframes, keyframes } from '@emotion/react'
import { rem } from 'polished'

export const global = css([
  {
    div: {
      boxSizing: 'border-box',
    },
  },
  {
    '*': {
      boxSizing: 'inherit',
    },
    '*::after': {
      boxSizing: 'inherit',
    },
    '*::before': {
      boxSizing: 'inherit',
    },
  },
  {
    '.shadow_root': {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
    },
  },
])

const fadeIn: Keyframes = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
})

export const animatedContainer = css({
  animation: `${fadeIn} 0.7s cubic-bezier(0.32, 0, 0.67, 0) 1`,
  margin: '0 auto',
  maxWidth: rem(1000),
})
