import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { IconButton, IconButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as RoundTripIcon } from '@Svg/navigationAndAction/roundtrip.svg'
import * as commonStyles from '@Utils/icons/styles'

import * as styles from './styles'

export type SwapProps = IconButtonProps & {
  isHorizontal?: boolean
  ariaLabelOrigin?: string
  ariaLabelDestination?: string
}

const Swap: FC<SwapProps> = ({ ariaLabelOrigin, ariaLabelDestination, isHorizontal, onClick, ...rest }) => {
  const [animationPlayState, setAnimationPlayState] = useState<AnimationPlayState>('idle')
  const theme = useTheme()
  const { formatMessage } = useIntl()

  const DEFAULT_EMPTY_LABEL = formatMessage({ id: 'accessibility_swapOd_defaultEmpty_SrLabel' })

  const runAnimation = () => setAnimationPlayState('running')

  const finishAnimation = () => setAnimationPlayState('finished')

  return (
    <IconButton
      {...rest}
      onClick={(...args) => {
        runAnimation()
        onClick?.(...args)
      }}
      onAnimationEnd={finishAnimation}
      css={styles.swapButton(theme)}
      aria-label={formatMessage(
        { id: 'accessibility_swapOd_SrLabel' },
        { origin: ariaLabelOrigin || DEFAULT_EMPTY_LABEL, destination: ariaLabelDestination || DEFAULT_EMPTY_LABEL }
      )}
      size="large"
    >
      <RoundTripIcon
        css={[styles.swapIcon(theme, animationPlayState, isHorizontal, rest.disabled), commonStyles.isWhcm()]}
      />
    </IconButton>
  )
}

export default Swap
