import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { paperClasses } from '@mui/material/Paper'
import { selectClasses } from '@mui/material/Select'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { rem, size } from 'polished'

import { spacing, SPACING_1, SPACING_2 } from '@DS/styles/constants/spacing'
import { typographyOverrides } from '@Material/typography'

export const root = (theme: Theme, isFilled = false): SerializedStyles =>
  css({
    [`.${inputLabelClasses.outlined}`]: {
      transform: `translate(14px, 20px) scale(1)`,
    },
    [`.${inputLabelClasses.outlined}.${inputLabelClasses.shrink}`]: {
      '& > :first-of-type': {
        ...(typographyOverrides(theme)?.subtitle1 as CSSProperties),
      },
      transform: `translate(14px, -6px) scale(0.96)`,
    },
    [`.${outlinedInputClasses.notchedOutline}`]: {
      border: `1px solid ${theme.customPalette.onElements.outline}`,
      // Force default values for SSR
      borderRadius: 'inherit',
      bottom: 0,
      left: 0,
      margin: 0,
      overflow: 'hidden',
      padding: '0 8px',
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: '-5px',
    },
    ...(isFilled && {
      [`.${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.text.primary,
      },
    }),
  })

export const menu = (theme: Theme): SxProps<Theme> => ({
  marginTop: '7px',
  [`.${paperClasses.root}`]: {
    border: `${rem(1)} solid ${theme.customPalette.onElements.outline}`,
  },
})

export const select = (theme: Theme): SerializedStyles =>
  css({
    '& > svg': {
      fill: theme.palette.primary.main,
      top: `calc(50% - ${rem(12)})`,
      width: rem(24),
    },
    [`.${selectClasses.disabled}`]: {
      fill: theme.palette.text.disabled,
    },
    maxHeight: rem(56),
  })

export const dropdownTitle = (theme: Theme): SerializedStyles =>
  css({
    marginBottom: SPACING_1,
    [theme.breakpoints.up('md')]: {
      marginBottom: SPACING_2,
    },
  })

export const dropdownMenuItem = css({
  '& > :first-of-type': {
    marginRight: spacing(1.25),
  },
  alignItems: 'end',
  display: 'flex',
})

export const dropdownMenuItemIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.text.secondary,
    ...size(rem(24)),
  })

export const dropdownMenuItemText = css({
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
