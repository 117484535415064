import { FC, RefObject, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { useVirtualizer } from '@tanstack/react-virtual'
import { addMonths, differenceInCalendarMonths, isBefore } from 'date-fns'

import { useIsInModal } from '@DS/components/navigation/modal/ModalContext'
import { useIsLargeUp } from '@Hooks/useResponsive'

import Month, { MonthProps } from '../atoms/month/Month'
import { ESTIMATED_MONTH_SIZE, OVERSCAN_VIRTUALIZED_MONTHS } from '../DatePicker.constants'

import * as styles from './styles'

export type MonthsProps = Omit<MonthProps, 'month'> & {
  numberOfMonths: number
  pageIndex: number
  today: Date
  scrollElementRef?: RefObject<HTMLElement>
}

const Months: FC<MonthsProps> = ({
  numberOfMonths,
  onDaySelect,
  pageIndex,
  startDate,
  today,
  scrollElementRef,
  ...monthProps
}) => {
  const isPastStartDate = !!startDate && isBefore(startDate, today)
  const firstMonth = isPastStartDate ? startDate : today
  const additionalMonths = isPastStartDate ? differenceInCalendarMonths(today, startDate) : 0
  const months = [...Array(numberOfMonths + additionalMonths).keys()]
  const isInModal = useIsInModal()
  const theme = useTheme()
  const isLargeUp = useIsLargeUp(theme)
  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: months.length,
    overscan: OVERSCAN_VIRTUALIZED_MONTHS,
    getScrollElement: () => scrollElementRef?.current || parentRef.current,
    estimateSize: () => ESTIMATED_MONTH_SIZE,
  })

  const getMonthComponent = (index: number) => (
    <Month
      key={`month_${index}`}
      {...monthProps}
      month={addMonths(firstMonth, index)}
      startDate={startDate}
      onDaySelect={onDaySelect}
    />
  )

  return (
    <div ref={parentRef} css={styles.months(theme, isInModal)}>
      {!isInModal && isLargeUp ? (
        months.map((i) => {
          if (i === Math.min(pageIndex, numberOfMonths - 2) || i === Math.min(pageIndex + 1, numberOfMonths - 1)) {
            return (
              <div key={i} data-test={`month-${i}`}>
                {getMonthComponent(i)}
              </div>
            )
          }

          return null
        })
      ) : (
        <div css={styles.rowVirtualizerContainer(rowVirtualizer.getTotalSize())}>
          {rowVirtualizer.getVirtualItems().map(({ key, index, start }) => (
            <div
              key={key}
              data-index={index}
              data-test={`month-${index}`}
              ref={rowVirtualizer.measureElement}
              css={styles.rowVirtualizerItem(start)}
            >
              {getMonthComponent(index)}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Months
