// eslint-disable-next-line import/no-duplicates
import { Locale } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { de, enUS, es, fr, it, nl } from 'date-fns/locale'

import Lang from '@Types/lang'

export const dateFnsLocale: Record<Lang, Locale> = {
  fr,
  de,
  en: enUS,
  it,
  nl,
  es,
}
