import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem } from 'polished'

export const overridesCssBaseLine = (): ComponentsOverrides['MuiCssBaseline'] => ({
  body: {
    // Pour être ISO Mui v4
    // https://mui.com/material-ui/migration/v5-component-changes/#update-body-font-size
    fontSize: rem(14),
    lineHeight: 1.43,
    // CSS3 specs' forced line break but not fully supported
    overflowWrap: 'anywhere',
    // Fallback: Legacy name for the overflow-wrap
    wordBreak: 'break-word',
  },
})
