import { CSSObject } from '@mui/material'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

import { typographyOverrides } from '@Material/typography'

export const overridesInputLabel = (theme: Theme): ComponentsOverrides['MuiInputLabel'] => ({
  root: {
    ...(typographyOverrides(theme)?.body2 as CSSObject),

    [`&.${inputLabelClasses.error}`]: {
      color: theme.palette.text.primary,
    },
    color: theme.palette.text.secondary,
  },
  outlined: {
    // Pour être ISO Mui v4
    transform: 'translate(14px, 20px) scale(1)',
    [`&.${inputLabelClasses.shrink}`]: {
      transform: 'translate(14px, -6px) scale(0.75);',
      [`&:not(.${inputLabelClasses.disabled})`]: {
        color: theme.palette.text.primary,
      },
    },
  },
})
