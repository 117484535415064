import { format, isPast, isSameDay, isSameMonth, isValid, parse, parseISO } from 'date-fns'

import { humanDateFormat, humanPattern, monthHumanDateFormat, monthHumanPattern } from '@Constants/date'
import { DateFormat } from '@Types/date'

export const ISO_DATE_HOUR_FORMAT = 'yyyy-MM-dd-HH-mm'
const ISO_DATE_FORMAT = 'yyyy-MM-dd'
const HUMAN_DATE_REGEX = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/

/**
 * @deprecated this function is not used
 * https://jira.vsct.fr/browse/IVTS-45353
 */
export const isPaymentCardExpired = (date: string | undefined): boolean => {
  if (!date || !/\d{2}\/\d{2}/.test(date)) {
    return false
  }
  const expirationDate = parse(date, 'MM/yy', new Date())

  return !isSameMonth(expirationDate, new Date()) && isPast(expirationDate)
}

export const humanDateToIsoDate = (value: string | undefined): string => {
  if (!value) {
    return ''
  }

  if (!HUMAN_DATE_REGEX.test(value.replace(/ /g, ''))) {
    return value
  }
  const date = parse(value, humanDateFormat, new Date())

  if (isValid(date)) {
    return format(date, ISO_DATE_FORMAT)
  }

  return value
}

export const parseHumanDateToDate = (value: string | undefined): Date | undefined => {
  if (!value) {
    return undefined
  }

  const date = parse(value, humanDateFormat, new Date())

  return isValid(date) ? date : undefined
}

export const dateToIsoDate = (date?: Date): string => {
  if (!date || !isValid(date)) {
    return ''
  }

  return format(date, ISO_DATE_FORMAT)
}

export const dateToHumanDate = (value: string | undefined): string => {
  if (!value) {
    return ''
  }
  const date = parseISO(value)

  if (isValid(date)) {
    return format(date, humanDateFormat)
  }

  return value
}

export const isoDateToHumanDate = (value: string | undefined): string => {
  if (!value) {
    return ''
  }

  if (!ISO_DATE_REGEX.test(value)) {
    return value
  }

  const date = parseISO(value)

  if (isValid(date)) {
    return format(date, humanDateFormat)
  }

  return value
}

export const isoDateTimeToIsoDate = (value: string): string => {
  const date = parseISO(value)

  if (isValid(date)) {
    return format(date, ISO_DATE_FORMAT)
  }

  return value
}

/**
 * @deprecated this function is not used
 * https://jira.vsct.fr/browse/IVTS-45353
 */
export const areDatesOnSameDay = (dateLeft?: string, dateRight?: string): boolean =>
  !!(dateLeft && dateRight && isSameDay(new Date(dateLeft), new Date(dateRight)))

export const isISODateValid = (date: string): boolean =>
  date && ISO_DATE_REGEX.test(date) ? isValid(parseISO(date)) : false

export const getPattern = (dateFormat: DateFormat): string => {
  if (dateFormat === monthHumanDateFormat) {
    return monthHumanPattern
  }

  return humanPattern
}
