// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const AsymetricalRoundtrip = svgLazyLoader('mobility/asymetrical_roundtrip')
const CarTransport = svgLazyLoader('mobility/car_transport')
const City = svgLazyLoader('mobility/city')
const Duration = svgLazyLoader('mobility/duration')
const ElectricBike = svgLazyLoader('mobility/electric_bike')
const FoldedScooter = svgLazyLoader('mobility/folded_scooter')
const FrequentRoute = svgLazyLoader('mobility/frequent_route')
const FromPin = svgLazyLoader('mobility/from_pin')
const FromStation = svgLazyLoader('mobility/from_station')
const Geolocation = svgLazyLoader('mobility/geolocation')
const Journey = svgLazyLoader('mobility/journey')
const Map = svgLazyLoader('mobility/map')
const Metro = svgLazyLoader('mobility/metro')
const OneOffRoute = svgLazyLoader('mobility/one_off_route')
const PayAsYouGo = svgLazyLoader('mobility/pay_as_you_go')
const PlaceCircleOutline = svgLazyLoader('mobility/place_circle_outline')
const Position = svgLazyLoader('mobility/position')
const Route = svgLazyLoader('mobility/route')
const Scooter = svgLazyLoader('mobility/scooter')
const Station = svgLazyLoader('mobility/station')
const StationChange = svgLazyLoader('mobility/station_change')
const StationFull = svgLazyLoader('mobility/station_full')
const StationProblem = svgLazyLoader('mobility/station_problem')
const Suitcase = svgLazyLoader('mobility/suitcase')
const SuitcaseFull = svgLazyLoader('mobility/suitcase_full')
const ToStation = svgLazyLoader('mobility/to_station')
const ToStation1 = svgLazyLoader('mobility/to_station1')
const ToStation2 = svgLazyLoader('mobility/to_station2')
const ToStation3 = svgLazyLoader('mobility/to_station3')
const TripsPlanned = svgLazyLoader('mobility/trips_planned')
const ViaStation = svgLazyLoader('mobility/via_station')

const ALL_MOBILITY_ICONS = [
  'asymetrical_roundtrip',
  'car_transport',
  'city',
  'duration',
  'electric_bike',
  'folded_scooter',
  'frequent_route',
  'from_pin',
  'from_station',
  'geolocation',
  'journey',
  'map',
  'metro',
  'one_off_route',
  'pay_as_you_go',
  'place_circle_outline',
  'position',
  'route',
  'scooter',
  'station',
  'station_change',
  'station_full',
  'station_problem',
  'suitcase',
  'suitcase_full',
  'to_station',
  'to_station1',
  'to_station2',
  'to_station3',
  'trips_planned',
  'via_station',
] as const
export type MobilityIcon = (typeof ALL_MOBILITY_ICONS)[number]

const isMobilityIcon = (mobilityKey: string): mobilityKey is MobilityIcon =>
  ALL_MOBILITY_ICONS.includes(mobilityKey as MobilityIcon)

const ICONS: LazyIconsGroup<MobilityIcon> = {
  asymetrical_roundtrip: AsymetricalRoundtrip,
  car_transport: CarTransport,
  city: City,
  duration: Duration,
  electric_bike: ElectricBike,
  folded_scooter: FoldedScooter,
  frequent_route: FrequentRoute,
  from_pin: FromPin,
  from_station: FromStation,
  geolocation: Geolocation,
  journey: Journey,
  map: Map,
  metro: Metro,
  one_off_route: OneOffRoute,
  pay_as_you_go: PayAsYouGo,
  place_circle_outline: PlaceCircleOutline,
  position: Position,
  route: Route,
  scooter: Scooter,
  station: Station,
  station_change: StationChange,
  station_full: StationFull,
  station_problem: StationProblem,
  suitcase: Suitcase,
  suitcase_full: SuitcaseFull,
  to_station: ToStation,
  to_station1: ToStation1,
  to_station2: ToStation2,
  to_station3: ToStation3,
  trips_planned: TripsPlanned,
  via_station: ViaStation,
}

export const getMobilityIcon = (mobilityKey: string | undefined): LazyIcon | undefined =>
  mobilityKey && isMobilityIcon(mobilityKey) ? ICONS[mobilityKey] : undefined

export const getMobilityIcons = (): LazyIconsGroup<MobilityIcon> => ICONS
