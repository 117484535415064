import { css, keyframes, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { LoaderSize } from './Loader.types'

const ANIMATION_DURATION = '.85s'
const ANIMATION_ITERATION_COUNT = 'infinite'
const ANIMATION_EASING = 'cubic-bezier(0.62, 0.4, 0.55,-0.74)'

const rotationAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

const translateAnimation1 = keyframes({
  '0%': {
    marginLeft: 0,
    marginTop: 0,
  },
  '100%': {
    marginLeft: '40%',
    marginTop: '40%',
  },
})

const translateAnimation2 = keyframes({
  '0%': {
    marginRight: 0,
    marginTop: 0,
  },
  '100%': {
    marginRight: '40%',
    marginTop: '40%',
  },
})

const translateAnimation3 = keyframes({
  '0%': {
    marginBottom: 0,
    marginLeft: 0,
  },
  '100%': {
    marginBottom: '40%',
    marginLeft: '40%',
  },
})

const translateAnimation4 = keyframes({
  '0%': {
    marginBottom: 0,
    marginRight: 0,
  },
  '100%': {
    marginBottom: '40%',
    marginRight: '40%',
  },
})

export const loader = (sizeAnimation: LoaderSize): SerializedStyles =>
  css({
    animation: `${rotationAnimation} ${ANIMATION_DURATION} ${ANIMATION_ITERATION_COUNT}`,
    margin: rem(1),
    ...size(rem(sizeAnimation)),
  })

const circle = (theme: Theme): SerializedStyles =>
  css({
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    position: 'absolute',
    ...size('30%'),
  })

const animationRunningBase = (theme: Theme): SerializedStyles =>
  css(
    {
      animationDirection: 'alternate',
      animationDuration: ANIMATION_DURATION,
      animationIterationCount: ANIMATION_ITERATION_COUNT,
      animationTimingFunction: ANIMATION_EASING,
    },
    circle(theme)
  )

export const circle1 = (theme: Theme): SerializedStyles =>
  css(
    {
      animationName: translateAnimation1,
      left: 0,
    },
    animationRunningBase(theme)
  )

export const circle2 = (theme: Theme): SerializedStyles =>
  css(
    {
      animationName: translateAnimation2,
      right: 0,
    },
    animationRunningBase(theme)
  )

export const circle3 = (theme: Theme): SerializedStyles =>
  css(
    {
      animationName: translateAnimation3,
      bottom: 0,
    },
    animationRunningBase(theme)
  )

export const circle4 = (theme: Theme): SerializedStyles =>
  css(
    {
      animationName: translateAnimation4,
      bottom: 0,
      right: 0,
    },
    animationRunningBase(theme)
  )
