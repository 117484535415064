import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { tabClasses } from '@mui/material/Tab'
import { rem } from 'polished'

import { HALF_RADIUS, MEDIUM_RADIUS, STANDARD_RADIUS } from '@DS/styles/constants/radius'
import { spacing, SPACING_2 } from '@DS/styles/constants/spacing'
import { highContrast } from '@Styles/a11y'
import { getRawFocusVisibleStyle } from '@Styles/focus'

export const overridesTabs = (theme: Theme): ComponentsOverrides['MuiTabs'] => ({
  root: {
    minHeight: 0,
  },
  indicator: {
    ...highContrast({ backgroundColor: 'Highlight' }),
    backgroundColor: theme.palette.primary.main,
    borderRadius: HALF_RADIUS,
    display: 'flex',
    height: rem(4),
    justifyContent: 'center',
    '& > span': {
      borderRadius: MEDIUM_RADIUS,
      maxWidth: '80%',
      width: '100%',
    },
  },
})

export const overridesTab = (theme: Theme): ComponentsOverrides['MuiTab'] => ({
  root: {
    boxShadow: `inset 0 -${rem(4)} 0 ${theme.palette.background.paper}`,
    flexGrow: 1,
    minHeight: 0,
    minWidth: rem(100),
    padding: `${spacing(1.5)} ${SPACING_2}`, // 12px car il y a la bordure inset de 4px
    textTransform: 'none',
    [`&.${tabClasses.selected}`]: {
      fill: theme.palette.primary.main,
    },
    '&:focus-visible::before': getRawFocusVisibleStyle(
      theme.palette.primary.main,
      `${STANDARD_RADIUS} ${STANDARD_RADIUS} 0 0`,
      '0'
    ),
    '&:hover': {
      backgroundColor: theme.customPalette.onElements.selected,
      borderRadius: `${STANDARD_RADIUS} ${STANDARD_RADIUS} 0 0`,
    },
  },
  textColorPrimary: {
    color: theme.palette.text.primary,
  },
})
