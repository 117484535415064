import { FC } from 'react'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as BrandLogoIcon } from '@Svg/brand_logo.svg'

import * as styles from './styles'

const BrandLogo: FC = () => {
  const theme = useTheme()

  return <BrandLogoIcon css={styles.brandIcon(theme)} />
}

export default BrandLogo
