import { CSSObject } from '@emotion/react'
import { radioClasses } from '@mui/material/Radio'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem } from 'polished'

import { SPACING_BASE } from '@DS/styles/constants/spacing'

export const RADIO_PADDING_VALUE = 12
export const RADIO_SPACING_VALUE = SPACING_BASE

// Offset permettant d'annuler la marge d'espacement des checkbox et radios pour certains usages
export const RADIO_REPOSITIONNING_OFFSET = rem(RADIO_SPACING_VALUE + 3)

export const getHoverStyle = (theme: Theme): CSSObject => ({
  backgroundColor: 'initial',
  color: theme.palette.text.secondary,
})

export const getCheckedHoverStyle = (theme: Theme): CSSObject => ({
  backgroundColor: 'initial',
  color: theme.palette.primary.dark,
})

export const overridesRadio = (theme: Theme): ComponentsOverrides['MuiRadio'] => ({
  root: {
    // Zone de tap = taille radio (24) + padding (12 * 2) = 48 -> conforme recommandation accessibilité
    // Espacement visuel = padding (12) + margin (-4) = 8 -> conforme DS
    margin: rem(RADIO_SPACING_VALUE - RADIO_PADDING_VALUE),
    padding: rem(RADIO_PADDING_VALUE),
    '&:hover': getHoverStyle(theme),
    color: theme.customPalette.onElements.outline,
  },
  colorPrimary: {
    [`&.${radioClasses.checked}`]: {
      '&:hover': getCheckedHoverStyle(theme),
      color: theme.palette.primary.main,
    },
  },
})
