import { createContext, FC, ReactNode, useContext, useMemo } from 'react'
import { initFullSdk, Sdk, User, UserSdk } from 'invictus-sdk-typescript'

import { getFrontendApiConfiguration } from '@Utils/api'

type SdkState = Partial<Sdk>

export const SdkContext = createContext<SdkState | undefined>(undefined)

export const useSdkState = (): SdkState => {
  const context = useContext(SdkContext)

  if (context === undefined) {
    throw new Error('useSdkState must be used within a SdkProvider')
  }

  return context
}

type SdkProviderProps<CustomSdk extends Partial<SdkState> = SdkState> = {
  customSdk: CustomSdk
  children: ReactNode
}

export const SdkProvider = <CustomSdk extends Partial<SdkState> = SdkState>({
  customSdk,
  children,
}: SdkProviderProps<CustomSdk>): JSX.Element => <SdkContext.Provider value={customSdk}>{children}</SdkContext.Provider>

type SdkFullProviderWithGivenUserProps = {
  user: User
  children: ReactNode
}

const apiConfiguration = getFrontendApiConfiguration()

export const SdkFullProviderWithGivenUser: FC<SdkFullProviderWithGivenUserProps> = ({ user, children }) => {
  const userSdk = useMemo(() => new UserSdk(user), [user])
  const fullSdk = useMemo(() => initFullSdk(apiConfiguration, userSdk), [userSdk])

  return <SdkProvider customSdk={fullSdk}>{children}</SdkProvider>
}
