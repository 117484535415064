import { LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const TrainLocomotive = svgLazyLoader('trains/train_locomotive')
const TrainHead = svgLazyLoader('trains/train_head')
const TrainHeadHighlighted = svgLazyLoader('trains/train_head_highlighted')
const TrainHeadWithDriver = svgLazyLoader('trains/train_head_with_driver')
const TrainHeadWithDriverHighlighted = svgLazyLoader('trains/train_head_with_driver_highlighted')
const TrainBody = svgLazyLoader('trains/train_body')
const TrainBodyHighlighted = svgLazyLoader('trains/train_body_highlighted')
const TrainTail = svgLazyLoader('trains/train_tail')
const TrainTailHighlighted = svgLazyLoader('trains/train_tail_highlighted')
const TrainFull = svgLazyLoader('trains/train_full')
const TrainFullHighlighted = svgLazyLoader('trains/train_full_highlighted')

const ALL_TRAINS_ICONS = [
  'trainLocomotive',
  'trainHead',
  'trainHeadHighlighted',
  'trainHeadWithDriver',
  'trainHeadWithDriverHighlighted',
  'trainBody',
  'trainBodyHighlighted',
  'trainTail',
  'trainTailHighlighted',
  'trainFull',
  'trainFullHighlighted',
] as const

type TrainsIcon = (typeof ALL_TRAINS_ICONS)[number]

const ICONS: LazyIconsGroup<TrainsIcon> = {
  trainLocomotive: TrainLocomotive,
  trainHead: TrainHead,
  trainHeadHighlighted: TrainHeadHighlighted,
  trainHeadWithDriver: TrainHeadWithDriver,
  trainHeadWithDriverHighlighted: TrainHeadWithDriverHighlighted,
  trainBody: TrainBody,
  trainBodyHighlighted: TrainBodyHighlighted,
  trainTail: TrainTail,
  trainTailHighlighted: TrainTailHighlighted,
  trainFull: TrainFull,
  trainFullHighlighted: TrainFullHighlighted,
}

export const getTrainsIcons = (): LazyIconsGroup<TrainsIcon> => ICONS
