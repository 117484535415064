import { FC, HTMLAttributes, ReactNode } from 'react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as AlertIcon } from '@Svg/mea/alert.svg'

import * as styles from './styles'

type InputHelperTextProps = {
  hasError?: boolean
  helperText: ReactNode
}

const InputHelperText: FC<InputHelperTextProps & HTMLAttributes<HTMLDivElement>> = ({
  hasError = false,
  helperText,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <span css={styles.helperText(theme)} {...rest}>
      {hasError && <AlertIcon css={styles.helperTextAlertIcon(theme)} />}
      <Typography variant="subtitle1" component="span">
        {helperText}
      </Typography>
    </span>
  )
}

export default InputHelperText
