import { type AxiosInstance } from 'axios'

import { BASE_PATH, type BaseAPI } from './generated/base'
import { addCredentials } from './configuration'
import { type Configuration } from './generated'
import { type UserSdk } from './user'

export type AbstractSdkConstructorParameters = ConstructorParameters<typeof AbstractSdk>

export type ControllerApiConstructor<ControllerApi extends BaseAPI> = new (
  ...args: ConstructorParameters<typeof BaseAPI>
) => ControllerApi

export abstract class AbstractSdk<ControllerApi extends BaseAPI = BaseAPI> {
  protected readonly api: ControllerApi

  private readonly configuration: Configuration

  private readonly axios?: AxiosInstance

  protected abstract getControllerApiConstructor(): ControllerApiConstructor<ControllerApi>

  constructor(configuration: Configuration, protected readonly userSdk: UserSdk, axios?: AxiosInstance) {
    addCredentials(configuration)

    const ControllerApiConstruct = this.getControllerApiConstructor()
    this.api = new ControllerApiConstruct(configuration, BASE_PATH, axios)
    this.configuration = configuration
    this.userSdk = userSdk
    this.axios = axios
  }

  // TODO migrer getControllerApiConstructor pour utiliser cette méthode et supprimer le Generic <ControllerApi..>
  create<Type extends BaseAPI = BaseAPI>(ControllerApiConstruct: ControllerApiConstructor<Type>): Type {
    return new ControllerApiConstruct(this.configuration, BASE_PATH, this.axios)
  }
}
