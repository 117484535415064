import { parseToRgb } from 'polished'

import { BLACK, WHITE } from '@Styles/constants/colors'

export const isContrasted = (color = '#ffffff'): boolean => {
  const { red, blue, green } = parseToRgb(color.slice(0, 1) === '#' ? color : `#${color}`)

  // Get YIQ ratio
  const yiq = (red * 299 + green * 587 + blue * 114) / 1000

  return yiq >= 128
}

export const colorOnBackground = (bkgColor = '#ffffff'): string => (isContrasted(bkgColor) ? BLACK : WHITE)

export const backgroundUnderColor = (textColor = '#000000'): string => (isContrasted(textColor) ? BLACK : WHITE)
