import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Metro1Icon = svgLazyLoader('transports/metro_1')
const Metro2Icon = svgLazyLoader('transports/metro_2')
const Metro3Icon = svgLazyLoader('transports/metro_3')
const Metro3BIcon = svgLazyLoader('transports/metro_3bis')
const Metro4Icon = svgLazyLoader('transports/metro_4')
const Metro5Icon = svgLazyLoader('transports/metro_5')
const Metro6Icon = svgLazyLoader('transports/metro_6')
const Metro7Icon = svgLazyLoader('transports/metro_7')
const Metro7BIcon = svgLazyLoader('transports/metro_7bis')
const Metro8Icon = svgLazyLoader('transports/metro_8')
const Metro9Icon = svgLazyLoader('transports/metro_9')
const Metro10Icon = svgLazyLoader('transports/metro_10')
const Metro11Icon = svgLazyLoader('transports/metro_11')
const Metro12Icon = svgLazyLoader('transports/metro_12')
const Metro13Icon = svgLazyLoader('transports/metro_13')
const Metro14Icon = svgLazyLoader('transports/metro_14')
const Metro15Icon = svgLazyLoader('transports/metro_15')
const Metro16Icon = svgLazyLoader('transports/metro_16')
const Metro17Icon = svgLazyLoader('transports/metro_17')
const Metro18Icon = svgLazyLoader('transports/metro_18')
const MetroOrlyvalIcon = svgLazyLoader('transports/metro_orlyval')
const RerAIcon = svgLazyLoader('transports/rer_a')
const RerBIcon = svgLazyLoader('transports/rer_b')
const RerCIcon = svgLazyLoader('transports/rer_c')
const RerDIcon = svgLazyLoader('transports/rer_d')
const RerEIcon = svgLazyLoader('transports/rer_e')
const TramwayT1Icon = svgLazyLoader('transports/tramway_1')
const TramwayT2Icon = svgLazyLoader('transports/tramway_2')
const TramwayT3AIcon = svgLazyLoader('transports/tramway_3a')
const TramwayT3BIcon = svgLazyLoader('transports/tramway_3b')
const TramwayT4Icon = svgLazyLoader('transports/tramway_4')
const TramwayT5Icon = svgLazyLoader('transports/tramway_5')
const TramwayT6Icon = svgLazyLoader('transports/tramway_6')
const TramwayT7Icon = svgLazyLoader('transports/tramway_7')
const TramwayT8Icon = svgLazyLoader('transports/tramway_8')
const TramwayT9Icon = svgLazyLoader('transports/tramway_9')
const TramwayT10Icon = svgLazyLoader('transports/tramway_10')
const TramwayT11Icon = svgLazyLoader('transports/tramway_11')
const TramwayT12Icon = svgLazyLoader('transports/tramway_12')
const TramwayT13Icon = svgLazyLoader('transports/tramway_13')
const TransilienHIcon = svgLazyLoader('transports/transilien_h')
const TransilienJIcon = svgLazyLoader('transports/transilien_j')
const TransilienKIcon = svgLazyLoader('transports/transilien_k')
const TransilienLIcon = svgLazyLoader('transports/transilien_l')
const TransilienNIcon = svgLazyLoader('transports/transilien_n')
const TransilienPIcon = svgLazyLoader('transports/transilien_p')
const TransilienRIcon = svgLazyLoader('transports/transilien_r')
const TransilienUIcon = svgLazyLoader('transports/transilien_u')
const TransilienVIcon = svgLazyLoader('transports/transilien_v')

export const ALL_LINE_ICONS = [
  'transilien-h',
  'transilien-j',
  'transilien-k',
  'transilien-l',
  'transilien-n',
  'transilien-p',
  'transilien-r',
  'transilien-u',
  'transilien-v',
  'rapid_transit-h',
  'rapid_transit-j',
  'rapid_transit-k',
  'rapid_transit-l',
  'rapid_transit-n',
  'rapid_transit-p',
  'rapid_transit-r',
  'rapid_transit-u',
  'rapid_transit-v',
  'tramway-t1',
  'tramway-t2',
  'tramway-t3a',
  'tramway-t3b',
  'tramway-t4',
  'tramway-t5',
  'tramway-t6',
  'tramway-t7',
  'tramway-t8',
  'tramway-t9',
  'tramway-t10',
  'tramway-t11',
  'tramway-t12',
  'tramway-t13',
  'rer-a',
  'rer-b',
  'rer-c',
  'rer-d',
  'rer-e',
  'metro-1',
  'metro-2',
  'metro-3',
  'metro-3b',
  'metro-4',
  'metro-5',
  'metro-6',
  'metro-7',
  'metro-7b',
  'metro-8',
  'metro-9',
  'metro-10',
  'metro-11',
  'metro-12',
  'metro-13',
  'metro-14',
  'metro-15',
  'metro-16',
  'metro-17',
  'metro-18',
  'metro-orlyval',
] as const

type LineIcon = (typeof ALL_LINE_ICONS)[number]

const isLineIcon = (lineKey: string): lineKey is LineIcon => ALL_LINE_ICONS.includes(lineKey as LineIcon)

const ICONS: LazyIconsGroup<LineIcon> = {
  'transilien-h': TransilienHIcon,
  'transilien-j': TransilienJIcon,
  'transilien-k': TransilienKIcon,
  'transilien-l': TransilienLIcon,
  'transilien-n': TransilienNIcon,
  'transilien-p': TransilienPIcon,
  'transilien-r': TransilienRIcon,
  'transilien-u': TransilienUIcon,
  'transilien-v': TransilienVIcon,
  'rapid_transit-h': TransilienHIcon,
  'rapid_transit-j': TransilienJIcon,
  'rapid_transit-k': TransilienKIcon,
  'rapid_transit-l': TransilienLIcon,
  'rapid_transit-n': TransilienNIcon,
  'rapid_transit-p': TransilienPIcon,
  'rapid_transit-r': TransilienRIcon,
  'rapid_transit-u': TransilienUIcon,
  'rapid_transit-v': TransilienVIcon,
  'tramway-t1': TramwayT1Icon,
  'tramway-t2': TramwayT2Icon,
  'tramway-t3a': TramwayT3AIcon,
  'tramway-t3b': TramwayT3BIcon,
  'tramway-t4': TramwayT4Icon,
  'tramway-t5': TramwayT5Icon,
  'tramway-t6': TramwayT6Icon,
  'tramway-t7': TramwayT7Icon,
  'tramway-t8': TramwayT8Icon,
  'tramway-t9': TramwayT9Icon,
  'tramway-t10': TramwayT10Icon,
  'tramway-t11': TramwayT11Icon,
  'tramway-t12': TramwayT12Icon,
  'tramway-t13': TramwayT13Icon,
  'rer-a': RerAIcon,
  'rer-b': RerBIcon,
  'rer-c': RerCIcon,
  'rer-d': RerDIcon,
  'rer-e': RerEIcon,
  'metro-1': Metro1Icon,
  'metro-2': Metro2Icon,
  'metro-3': Metro3Icon,
  'metro-3b': Metro3BIcon,
  'metro-4': Metro4Icon,
  'metro-5': Metro5Icon,
  'metro-6': Metro6Icon,
  'metro-7': Metro7Icon,
  'metro-7b': Metro7BIcon,
  'metro-8': Metro8Icon,
  'metro-9': Metro9Icon,
  'metro-10': Metro10Icon,
  'metro-11': Metro11Icon,
  'metro-12': Metro12Icon,
  'metro-13': Metro13Icon,
  'metro-14': Metro14Icon,
  'metro-15': Metro15Icon,
  'metro-16': Metro16Icon,
  'metro-17': Metro17Icon,
  'metro-18': Metro18Icon,
  'metro-orlyval': MetroOrlyvalIcon,
}

export const getLegIcon = (lineKey: string | undefined): LazyIcon | undefined =>
  lineKey && isLineIcon(lineKey) ? ICONS[lineKey] : undefined

export const getAllLegIcon = (): LazyIconsGroup<LineIcon> => ICONS
