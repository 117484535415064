// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Roadmate = svgLazyLoader('paymentCard/roadmate')
const AldAutomotive = svgLazyLoader('paymentCard/ald_automotive')
const AlmaPay = svgLazyLoader('paymentCard/alma_pay')
const Amex = svgLazyLoader('paymentCard/amex')
const AmexSecured = svgLazyLoader('paymentCard/amex_secured')
const Ancv = svgLazyLoader('paymentCard/ancv')
const ApplePay = svgLazyLoader('paymentCard/apple_pay')
const Betterway = svgLazyLoader('paymentCard/betterway')
const Cb = svgLazyLoader('paymentCard/cb')
const CbSecured = svgLazyLoader('paymentCard/cb_secured')
const CbGeneric = svgLazyLoader('paymentCard/cb_generic')
const Ebleue = svgLazyLoader('paymentCard/ebleue')
const Ideal = svgLazyLoader('paymentCard/ideal')
const Klarna = svgLazyLoader('paymentCard/klarna')
const Maestro = svgLazyLoader('paymentCard/maestro')
const Mastercard = svgLazyLoader('paymentCard/mastercard')
const MastercardSecured = svgLazyLoader('paymentCard/mastercard_secured')
const Paylib = svgLazyLoader('paymentCard/paylib')
const Paypal = svgLazyLoader('paymentCard/paypal')
const SodexoPassMobilite = svgLazyLoader('paymentCard/sodexo_pass_mobilite')
const Swile = svgLazyLoader('paymentCard/swile')
const TotalEnergie = svgLazyLoader('paymentCard/total_energie')
const VPay = svgLazyLoader('paymentCard/v_pay')
const Visa = svgLazyLoader('paymentCard/visa')
const VisaSecured = svgLazyLoader('paymentCard/visa_secured')
const VisaElectron = svgLazyLoader('paymentCard/visa_electron')
const Worklife = svgLazyLoader('paymentCard/worklife')

const ALL_PAYMENT_CARD_ICONS = [
  'roadmate',
  'ald_automotive',
  'alma_pay',
  'amex',
  'amex-secured',
  'ancv',
  'apple_pay',
  'betterway',
  'cb',
  'cb-secured',
  'cb_generic',
  'ebleue',
  'ideal',
  'klarna',
  'maestro',
  'mastercard',
  'mastercard-secured',
  'paylib',
  'paypal',
  'sodexo_pass_mobilite',
  'swile',
  'total_energie',
  'v_pay',
  'visa',
  'visa-secured',
  'visa_electron',
  'worklife',
] as const
export type PaymentCardIcon = (typeof ALL_PAYMENT_CARD_ICONS)[number]

const isPaymentCardIcon = (paymentCardKey: string): paymentCardKey is PaymentCardIcon =>
  ALL_PAYMENT_CARD_ICONS.includes(paymentCardKey as PaymentCardIcon)

const ICONS: LazyIconsGroup<PaymentCardIcon> = {
  roadmate: Roadmate,
  ald_automotive: AldAutomotive,
  alma_pay: AlmaPay,
  amex: Amex,
  'amex-secured': AmexSecured,
  ancv: Ancv,
  apple_pay: ApplePay,
  betterway: Betterway,
  cb: Cb,
  'cb-secured': CbSecured,
  cb_generic: CbGeneric,
  ebleue: Ebleue,
  ideal: Ideal,
  klarna: Klarna,
  maestro: Maestro,
  mastercard: Mastercard,
  'mastercard-secured': MastercardSecured,
  paylib: Paylib,
  paypal: Paypal,
  sodexo_pass_mobilite: SodexoPassMobilite,
  swile: Swile,
  total_energie: TotalEnergie,
  v_pay: VPay,
  visa: Visa,
  'visa-secured': VisaSecured,
  visa_electron: VisaElectron,
  worklife: Worklife,
}

export const getPaymentCardIcon = (paymentCardKey: string | undefined): LazyIcon | undefined =>
  paymentCardKey && isPaymentCardIcon(paymentCardKey) ? ICONS[paymentCardKey] : undefined

export const getPaymentCardIcons = (): LazyIconsGroup<PaymentCardIcon> => ICONS
