import { ReactElement } from 'react'

import { Pathname } from '@Types/navigation/navigation'

export type ModalNavData<ModalNavContextDataType = unknown> = {
  setNavContextData: (data: ModalNavContextDataType, routeName?: string) => void
  getNavContextData: (routeName?: string) => ModalNavContextDataType
  clearNavContextData: () => void
}

export type ModalNavControls = {
  goToModalPage: (pageName: Pathname) => void
  goToDynamicModalPage: (dynamicPage: ReactElement) => void
  goToParentPage: VoidFunction
  goToNextModalPage: (slug: string) => void
}

export type ModalControls = ModalNavControls & {
  closeModal: VoidFunction
}

export const DRAWER_TRANSITION_DURATION = 300
