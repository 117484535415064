import { Property } from 'csstype'

export const getInnerBorderRadius = (
  outerBorderRadius:
    | string[]
    | Property.BorderRadius<string | number>
    | NonNullable<Property.BorderRadius<string | number> | undefined>[]
    | undefined,
  margin:
    | string[]
    | Property.Margin<string | number>
    | NonNullable<Property.Margin<string | number> | undefined>[]
    | undefined
):
  | string[]
  | Property.BorderRadius<string | number>
  | NonNullable<Property.BorderRadius<string | number> | undefined>[]
  | undefined => {
  if (
    !outerBorderRadius ||
    outerBorderRadius === '0' ||
    outerBorderRadius === 0 ||
    !margin ||
    margin === '0' ||
    margin === 0
  ) {
    return outerBorderRadius
  }

  return `calc(${outerBorderRadius} - ${margin})`
}
