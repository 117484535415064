// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const FirstClass = svgLazyLoader('classes/first_class')
const FirstClassSimple = svgLazyLoader('classes/first_class_simple')
const FirstClassSymbol = svgLazyLoader('classes/first_class_symbol')
const SecondClass = svgLazyLoader('classes/second_class')
const SecondClassSymbol = svgLazyLoader('classes/second_class_symbol')

const ALL_CLASSES_ICONS = [
  'first_class',
  'first_class_simple',
  'first_class_symbol',
  'second_class',
  'second_class_symbol',
] as const
export type ClassesIcon = (typeof ALL_CLASSES_ICONS)[number]

const isClassesIcon = (classesKey: string): classesKey is ClassesIcon =>
  ALL_CLASSES_ICONS.includes(classesKey as ClassesIcon)

const ICONS: LazyIconsGroup<ClassesIcon> = {
  first_class: FirstClass,
  first_class_simple: FirstClassSimple,
  first_class_symbol: FirstClassSymbol,
  second_class: SecondClass,
  second_class_symbol: SecondClassSymbol,
}

export const getClassesIcon = (classesKey: string | undefined): LazyIcon | undefined =>
  classesKey && isClassesIcon(classesKey) ? ICONS[classesKey] : undefined

export const getClassesIcons = (): LazyIconsGroup<ClassesIcon> => ICONS
