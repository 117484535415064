import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { TypeBackground } from '@mui/material/styles/createPalette'
import { rem } from 'polished'

export type SeparatorSize = 'extraSmall' | 'small' | 'large' | 'extraLarge'

export const separator = (
  background: keyof TypeBackground,
  isOutline: boolean,
  isVertical: boolean,
  size: SeparatorSize,
  theme: Theme
): SerializedStyles => {
  const thicknessCssProp = isVertical ? 'width' : 'height'

  return css({
    ...(isOutline
      ? {
          borderColor: theme.customPalette.onElements.outline,
          [thicknessCssProp]: rem(1),
          ...(isVertical && {
            borderBottom: 'none',
            borderRightWidth: rem(1),
          }),
        }
      : {
          backgroundColor: theme.palette.background[background],
          borderStyle: 'none',
          [thicknessCssProp]: {
            extraLarge: rem(16),
            extraSmall: rem(1),
            large: rem(8),
            small: rem(2),
          }[size],
        }),
    ...(isVertical && {
      height: 'auto',
      minHeight: rem(16),
    }),
  })
}

export const separatorDashedOrDotted = (
  theme: Theme,
  isVertical: boolean,
  borderType: 'dashed' | 'dotted'
): SerializedStyles =>
  css({
    backgroundColor: 'initial',
    ...(isVertical
      ? {
          borderLeft: `1px ${borderType} ${theme.customPalette.onElements.outline}`,
          borderRight: 'none',
          height: 'auto',
          minHeight: rem(16),
        }
      : {
          borderBottom: `1px ${borderType} ${theme.customPalette.onElements.outline}`,
          height: 0,
        }),
  })
