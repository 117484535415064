import { ReactElement, useCallback, useRef, useState } from 'react'

import { ModalProps } from '@DS/components/navigation/modal/Modal'
import { ModalControls, ModalNavControls, ModalNavData } from '@DS/components/navigation/modal/Modal.types'
import { Pathname } from '@Types/navigation/navigation'
import { WithRequired } from '@Utils/type'

export type UseModalStateReturn = {
  isModalOpen: boolean
  currentPageName: Pathname
  currentPageSlug: string
  openModal: (pageName?: Pathname) => void
  modalProps: WithRequired<ModalProps, 'modalNavControls'>
} & ModalControls

/**
 * To be used to control a modal with multiple pages
 */
export const useModal = (): UseModalStateReturn => {
  const [isModalOpen, setIsModalVisible] = useState(false)

  const [currentPageName, setCurrentPageName] = useState<Pathname>('/')
  const [dynamicPage, setDynamicPage] = useState<ReactElement | undefined>()

  const navContextData = useRef<Record<string, unknown>>({})
  const currentPageSlug = currentPageName.split('/').pop() as string

  // Page is sub page if path pattern is "main/subpage" or "/main/subpage"
  const hasToDisplayBackButton = !!dynamicPage || currentPageName.split('/').filter((e) => e).length > 1

  const getNavContextData = useCallback(
    (pageName?: string) => navContextData.current[pageName || currentPageName],

    [currentPageName]
  )

  const setNavContextData = useCallback(
    (data: unknown, pageName?: string) => {
      navContextData.current[pageName || currentPageName] = data
    },
    [currentPageName]
  )

  const clearNavContextData = useCallback(() => {
    navContextData.current = {}
  }, [])

  const openModal = useCallback((pageName: Pathname | undefined = '/') => {
    setDynamicPage(undefined)
    setCurrentPageName(pageName)
    setIsModalVisible(true)
  }, [])

  const closeModal = useCallback(() => {
    clearNavContextData()

    setIsModalVisible(false)
    setDynamicPage(undefined)
  }, [clearNavContextData])

  const goToModalPage = (pageName: Pathname) => {
    setDynamicPage(undefined)
    setIsModalVisible(true)
    setCurrentPageName(pageName)
  }

  const goToDynamicModalPage = (dynamicPageEl: ReactElement) => {
    setIsModalVisible(true)
    setDynamicPage(dynamicPageEl)
    setCurrentPageName(`${currentPageName}/dynamic`)
  }

  // TODO: Rédiger le TODO (inception de TODO 🤯)
  const goToNextModalPage = (slug: string) => {
    setDynamicPage(undefined)
    setIsModalVisible(true)
    setCurrentPageName(`${currentPageName}/${slug}`)
  }

  const goToParentPage = () => {
    if (!hasToDisplayBackButton || currentPageName === '/') {
      return
    }
    setDynamicPage(undefined)
    setCurrentPageName(`/${currentPageName.substring(1, currentPageName.lastIndexOf('/'))}`)
  }

  const modalNavControls: ModalNavControls = {
    goToModalPage,
    goToParentPage,
    goToDynamicModalPage,
    goToNextModalPage,
  }

  const modalNavData: ModalNavData = {
    getNavContextData,
    setNavContextData,
    clearNavContextData,
  }

  return {
    isModalOpen,
    openModal,
    currentPageName,
    currentPageSlug,
    closeModal,
    ...modalNavControls,
    modalProps: {
      isOpen: isModalOpen,
      onClose: closeModal,
      dynamicPage,
      isBackIconDisplayed: hasToDisplayBackButton,
      onBackIconClick: goToParentPage,
      modalNavControls,
      modalNavData,
    },
  }
}
