import { FC, HTMLAttributes } from 'react'
import { ShortItineraryIconGenerate } from 'invictus-sdk-typescript'

import * as styles from '../styles'

type GeneratedTransportIconProps = ShortItineraryIconGenerate & {
  isSmallGenerated?: boolean
} & HTMLAttributes<HTMLSpanElement>

const GeneratedTransportIcon: FC<GeneratedTransportIconProps> = ({
  color,
  radius,
  label,
  textColor,
  isSmallGenerated = false,
  ...rest
}) => (
  <span css={styles.generatedTransportIcon(color, textColor, radius, isSmallGenerated)} {...rest}>
    {label}
  </span>
)

export default GeneratedTransportIcon
