import { css, SerializedStyles } from '@emotion/react'
import { accordionClasses } from '@mui/material/Accordion'
import { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import { cardActionAreaClasses } from '@mui/material/CardActionArea'
import { formControlLabelClasses } from '@mui/material/FormControlLabel'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SPACING_1, SPACING_2, SPACING_BASE } from '@DS/styles/constants/spacing'
import { RADIO_PADDING_VALUE } from '@Material/radio'
import { selectionAccessibilityBar } from '@Styles/selection'

import { ControlPlacement } from './BasicCardCheckboxAndRadio.types'

export * from '../styles'

export const BORDER_SIZE = rem(2)
const ICON_SIZE = rem(32)

export const borderColor = (color: string): SerializedStyles =>
  css({
    boxShadow: `0 0 0 ${BORDER_SIZE} ${color}`,
  })

export const backgroundColor = (color: string): SerializedStyles =>
  css({
    backgroundColor: color,
  })

export const basicCardButtonActionArea = css(size('100%'))

export const alertOrSelectedFocusVisibleStyle = (theme: Theme): SerializedStyles =>
  css({
    [`&.${cardActionAreaClasses.focusVisible}`]: {
      '&::before': {
        borderColor: theme.palette.common.white,
      },
    },
  })

export const selected = (theme: Theme, hasFocusVisible = false): SerializedStyles =>
  css(
    {
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      color: theme.palette.primary.contrastText,
    },
    selectionAccessibilityBar(theme, hasFocusVisible)
  )

export const alert = (theme: Theme): SerializedStyles =>
  css({
    '& svg': {
      fill: theme.customPalette.onElements.elevated,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    color: theme.customPalette.onElements.elevated,
  })

export const basicCardContent = (radioPlacement: ControlPlacement, hasNoContentPadding = false): SerializedStyles =>
  css(
    {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
      justifyContent: 'space-between',
    },
    radioPlacement === 'left' && {
      flexDirection: 'row-reverse',
    },
    hasNoContentPadding && {
      padding: 0,
    }
  )

const getMarginOffset = (spacingValue = 0): string => rem(spacingValue * SPACING_BASE - RADIO_PADDING_VALUE)

const defaultMarginOffset = getMarginOffset()

export const basicCardControl = (placement: ControlPlacement, spacingValue: number): SerializedStyles =>
  css({
    margin: `${defaultMarginOffset} ${
      placement === 'right' ? defaultMarginOffset : getMarginOffset(spacingValue)
    } ${defaultMarginOffset} ${placement === 'left' ? defaultMarginOffset : getMarginOffset(spacingValue)}`,
  })

export const icon = (theme: Theme, isHovered = false): SerializedStyles =>
  css(
    size(ICON_SIZE),
    {
      color: theme.palette.primary.main,
      fill: 'currentcolor',
      flexShrink: 0,
    },
    isHovered && {
      color: theme.palette.primary.dark,
    }
  )

export const controlFormLabel = css({
  [`& .${formControlLabelClasses.label}`]: {
    flex: 1,
    flexWrap: 'wrap',
    minWidth: 0,
  },
  display: 'flex',
  margin: 0,
  padding: SPACING_2,
})

export const toggle = css({
  margin: `-${SPACING_1} -${SPACING_1} -${SPACING_1} 0`,
})

export const basicAddExpandableCard = css({
  [`&.${accordionClasses.root}:not(:first-of-type)`]: {
    marginTop: SPACING_2,
  },
})

export const basicAddExpandableCardSummary = css({
  [`.${accordionSummaryClasses.expandIconWrapper}`]: {
    paddingLeft: SPACING_1,
  },
  [`.${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: 'none',
    transition: 'none',
  },
})

export const basicAddExpandableCardDetails = css({
  padding: SPACING_2,
})

export const noPadding = css({
  ':last-child': {
    padding: 0,
  },
})
