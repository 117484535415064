import { useRouter } from '@Hooks/useRouterWrapper'
import Locale from '@Types/locale'
import { Pathname } from '@Types/navigation/navigation'
import { getAbsoluteUrl } from '@Utils/router'

type UseFullRefreshReloadReturn = {
  goToWithFullReload: (path: Pathname, locale?: string) => void
}

export const useFullRefreshReload = (): UseFullRefreshReloadReturn => {
  const { locale } = useRouter()

  const goToWithFullReload = (path: Pathname) => {
    window.location.href = getAbsoluteUrl(path, locale as Locale)
  }

  return { goToWithFullReload }
}
