// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Agent = svgLazyLoader('typology/agent')
const AvatarCircle = svgLazyLoader('typology/avatar_circle')
const BikeAdd = svgLazyLoader('typology/bike_add')
const BikeCircle = svgLazyLoader('typology/bike_circle')
const BikeCircleFolded = svgLazyLoader('typology/bike_circle_folded')
const BikeDefault = svgLazyLoader('typology/bike_default')
const BikeNested = svgLazyLoader('typology/bike_nested')
const CompagnonAvatar = svgLazyLoader('typology/compagnon_avatar')
const CompartmentLarge = svgLazyLoader('typology/compartment_large')
const DefaultAvatarAdd = svgLazyLoader('typology/default_avatar_add')
const DefaultAvatarNested = svgLazyLoader('typology/default_avatar_nested')
const DefaultAvatarPlain = svgLazyLoader('typology/default_avatar_plain')
const DefaultGroupAvatar = svgLazyLoader('typology/default_group_avatar')
const DisabledPersonAdd = svgLazyLoader('typology/disabled_person_add')
const Familly1 = svgLazyLoader('typology/familly_1')
const Familly2 = svgLazyLoader('typology/familly_2')
const JuniorCompany = svgLazyLoader('typology/junior_company')
const Loyalty = svgLazyLoader('typology/loyalty')
const LuggageCircle = svgLazyLoader('typology/luggage_circle')
const PetAdd = svgLazyLoader('typology/pet_add')
const PetCircle = svgLazyLoader('typology/pet_circle')
const PetDefault = svgLazyLoader('typology/pet_default')
const PetNested = svgLazyLoader('typology/pet_nested')
const Pro = svgLazyLoader('typology/pro')

const ALL_TYPOLOGY_ICONS = [
  'agent',
  'avatar_circle',
  'bike_add',
  'bike_circle',
  'bike_circle_folded',
  'bike_default',
  'bike_nested',
  'compagnon_avatar',
  'compartment_large',
  'default_avatar_add',
  'default_avatar_nested',
  'default_avatar_plain',
  'default_group_avatar',
  'disabled_person_add',
  'familly_1',
  'familly_2',
  'junior_company',
  'loyalty',
  'luggage_circle',
  'pet_add',
  'pet_circle',
  'pet_default',
  'pet_nested',
  'pro',
] as const
export type TypologyIcon = (typeof ALL_TYPOLOGY_ICONS)[number]

const isTypologyIcon = (typologyKey: string): typologyKey is TypologyIcon =>
  ALL_TYPOLOGY_ICONS.includes(typologyKey as TypologyIcon)

const ICONS: LazyIconsGroup<TypologyIcon> = {
  agent: Agent,
  avatar_circle: AvatarCircle,
  bike_add: BikeAdd,
  bike_circle: BikeCircle,
  bike_circle_folded: BikeCircleFolded,
  bike_default: BikeDefault,
  bike_nested: BikeNested,
  compagnon_avatar: CompagnonAvatar,
  compartment_large: CompartmentLarge,
  default_avatar_add: DefaultAvatarAdd,
  default_avatar_nested: DefaultAvatarNested,
  default_avatar_plain: DefaultAvatarPlain,
  default_group_avatar: DefaultGroupAvatar,
  disabled_person_add: DisabledPersonAdd,
  familly_1: Familly1,
  familly_2: Familly2,
  junior_company: JuniorCompany,
  loyalty: Loyalty,
  luggage_circle: LuggageCircle,
  pet_add: PetAdd,
  pet_circle: PetCircle,
  pet_default: PetDefault,
  pet_nested: PetNested,
  pro: Pro,
}

export const getTypologyIcon = (typologyKey: string | undefined): LazyIcon | undefined =>
  typologyKey && isTypologyIcon(typologyKey) ? ICONS[typologyKey] : undefined

export const getTypologyIcons = (): LazyIconsGroup<TypologyIcon> => ICONS
