import { PaletteOptions } from '@mui/material/styles'

import {
  ANTHRACITE,
  BLUE,
  BLUE_LIGHT_MODE,
  DARK_BLUE,
  DARK_RED_ALERT_LIGHT_MODE,
  DISABLED,
  DISABLED_LIGHT_MODE,
  GREEN_SUCCESS,
  GREEN_SUCCESS_LIGHT_MODE,
  GREEN_YELLOW,
  GREY,
  GREY_LIGHT_MODE,
  LAVENDER_CHARM,
  LIGHT_BLUE,
  LIGHT_BLUE_LIGHT_MODE,
  LIGHT_GREY_LIGHT_MODE,
  NOBLE_GREY,
  ORANGE_WARNING,
  ORANGE_WARNING_LIGHT_MODE,
  RED_ALERT,
  RED_ALERT_LIGHT_MODE,
  WHITE,
  WHITE_LIGHT_MODE,
} from '@Styles/constants/colors'

const darkTheme: PaletteOptions = {
  primary: {
    main: LIGHT_BLUE, // Figma: Primary
    light: GREEN_YELLOW, // Do not use
    contrastText: DARK_BLUE, // Figma: OnPrimary
    dark: BLUE, // Figma: PrimaryDark
  },
  // Pas de couleurs secondaires dans le DS.
  // On met volontairement une couleur flashy pour voir si c'est utilisé par erreur
  secondary: {
    main: GREEN_YELLOW,
    light: GREEN_YELLOW,
    dark: GREEN_YELLOW,
    contrastText: GREEN_YELLOW,
  },
  text: {
    primary: WHITE, // Figma: OnBackground et OnSurface
    secondary: GREY, // Figma: Text Secondary
    disabled: DISABLED, // Figma: Disabled
  },
  success: {
    main: GREEN_SUCCESS, // Figma: Success
  },
  warning: {
    main: ORANGE_WARNING, // Figma: Warning
  },
  error: {
    main: RED_ALERT, // Figma: Error
  },
  action: {
    active: WHITE, // Figma: not yet defined
    disabled: DISABLED, // Figma: Disabled
    disabledBackground: undefined,
  },
  background: {
    default: DARK_BLUE, // Figma: Background
    paper: ANTHRACITE, // Figma: Surface
  },
}

const lightTheme: PaletteOptions = {
  primary: {
    main: LIGHT_BLUE_LIGHT_MODE, // Figma: Primary
    light: GREEN_YELLOW, // Do not use
    contrastText: WHITE_LIGHT_MODE, // Figma: OnPrimary
    dark: BLUE_LIGHT_MODE, // Figma: PrimaryDark
  },
  // Pas de couleurs secondaires dans le DS.
  // On met volontairement une couleur flashy pour voir si c'est utilisé par erreur
  secondary: {
    main: GREEN_YELLOW,
    light: GREEN_YELLOW,
    dark: GREEN_YELLOW,
    contrastText: GREEN_YELLOW,
  },
  text: {
    primary: DARK_BLUE, // Figma: OnBackground et OnSurface
    secondary: GREY_LIGHT_MODE, // Figma: Text Secondary
    disabled: DISABLED_LIGHT_MODE, // Figma: Disabled
  },
  success: {
    main: GREEN_SUCCESS_LIGHT_MODE, // Figma: Success
  },
  warning: {
    main: ORANGE_WARNING_LIGHT_MODE, // Figma: Warning
  },
  error: {
    main: RED_ALERT_LIGHT_MODE, // Figma: Error
    dark: DARK_RED_ALERT_LIGHT_MODE, // Figma: Error hover
  },
  action: {
    active: DARK_BLUE, // Figma: not yet defined
    disabled: DISABLED_LIGHT_MODE, // Figma: Disabled
    disabledBackground: undefined,
  },
  background: {
    default: LIGHT_GREY_LIGHT_MODE, // Figma: Background
    paper: WHITE_LIGHT_MODE, // Figma: Surface
  },
}

const proTheme: PaletteOptions = {
  primary: {
    main: LAVENDER_CHARM, // Figma: Primary
    light: GREEN_YELLOW, // Do not use
    contrastText: WHITE_LIGHT_MODE, // Figma: OnPrimary
    dark: LAVENDER_CHARM, // Figma: PrimaryDark
  },
  // Pas de couleurs secondaires dans le DS.
  // On met volontairement une couleur flashy pour voir si c'est utilisé par erreur
  secondary: {
    main: GREEN_YELLOW,
    light: GREEN_YELLOW,
    dark: GREEN_YELLOW,
    contrastText: GREEN_YELLOW,
  },
  text: {
    primary: DARK_BLUE, // Figma: OnBackground et OnSurface
    secondary: GREY_LIGHT_MODE, // Figma: Text Secondary
    disabled: DISABLED_LIGHT_MODE, // Figma: Disabled
  },
  success: {
    main: GREEN_SUCCESS_LIGHT_MODE, // Figma: Success
  },
  warning: {
    main: ORANGE_WARNING_LIGHT_MODE, // Figma: Warning
  },
  error: {
    main: RED_ALERT_LIGHT_MODE, // Figma: Error
    dark: DARK_RED_ALERT_LIGHT_MODE, // Figma: Error hover
  },
  action: {
    active: DARK_BLUE, // Figma: not yet defined
    disabled: DISABLED_LIGHT_MODE, // Figma: Disabled
    disabledBackground: undefined,
  },
  background: {
    default: WHITE_LIGHT_MODE, // Figma: Background
    paper: NOBLE_GREY, // Figma: Surface
  },
}

export const palette = ({ hasDarkMode, hasProMode }: { hasDarkMode: boolean; hasProMode: boolean }): PaletteOptions => {
  if (hasDarkMode) {
    return {
      mode: 'dark',
      ...darkTheme,
    }
  }

  if (hasProMode) {
    return {
      mode: 'light',
      ...proTheme,
    }
  }

  return {
    mode: 'light',
    ...lightTheme,
  }
}
