import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'
import { rem, size } from 'polished'

import { SPACING_0 } from '@DS/styles/constants/spacing'

export const DATEPICKER_GRID_CELL_SIZE = 32
const DATEPICKER_GRID_RANGE_OFFSET = 3

const ROW_HEIGHT = rem(DATEPICKER_GRID_CELL_SIZE + DATEPICKER_GRID_RANGE_OFFSET * 2)

export const root = (theme: Theme): SerializedStyles =>
  css({
    '&::after': {
      // Fond interval
      backgroundColor: theme.customPalette.onElements.selected,
      content: '""',
      display: 'block',
      height: ROW_HEIGHT,
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: `translateY(calc(-50% + ${rem(1)}))`,
      zIndex: 1,
    },
    '&::before': {
      // Rond sélection
      border: '1px solid transparent',
      borderRadius: '50%',
      boxSizing: 'content-box',
      content: '""',
      display: 'block',
      left: '50%',
      position: 'absolute',
      top: '50%',
      ...size(rem(DATEPICKER_GRID_CELL_SIZE)),
      transform: `translate(-50%,calc(-50% + ${rem(1)}))`,
      zIndex: 2,
    },
    background: 'transparent',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    padding: `${SPACING_0} 0 0`,
    position: 'relative',
    textAlign: 'center',
  })

export const dayText = css({
  [`&.${typographyClasses.root}`]: {
    height: ROW_HEIGHT,
    lineHeight: `calc(${ROW_HEIGHT} + ${rem(2)})`,
    position: 'relative',
    zIndex: 3,
  },
})

export const selectedPlain = (theme: Theme): SerializedStyles =>
  css({
    '&::before': {
      backgroundColor: theme.palette.primary.main,
    },
    color: theme.palette.primary.contrastText,
  })

export const selectedCircle = css({
  '&::before': {
    borderColor: 'currentColor',
    borderStyle: 'solid',
    borderWidth: rem(1),
  },
})

export const selectedPlainWithCircle = (theme: Theme): SerializedStyles =>
  css({
    '&::before': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 0 0 ${rem(1)} inset currentColor`,
    },
    color: theme.palette.primary.contrastText,
  })

export const inRange = css({
  '&::after': {
    opacity: 1,
  },
})

export const rangeStart = (theme: Theme): SerializedStyles =>
  css({
    '&::after': {
      left: '50%',
    },
    '&::before': {
      backgroundColor: theme.customPalette.onElements.selected,
      boxShadow: `0 0 0 ${rem(DATEPICKER_GRID_RANGE_OFFSET - 1)} ${theme.customPalette.onElements.selected}`,
    },
  })

export const rangeEnd = (theme: Theme): SerializedStyles =>
  css({
    '&::after': {
      right: '50%',
    },
    '&::before': {
      boxShadow: `0 0 0 ${rem(DATEPICKER_GRID_RANGE_OFFSET - 1)} ${theme.customPalette.onElements.selected}`,
    },
  })

export const hovered = (theme: Theme): SerializedStyles =>
  css({
    '&::before': {
      backgroundColor: theme.customPalette.onElements.selected,
    },
  })

export const disabled = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.disabled,
    cursor: 'not-allowed',
  })

export const today = css({
  [`&.${typographyClasses.root}`]: {
    '&::after': {
      backgroundColor: 'currentColor',
      bottom: rem(9),
      content: '""',
      height: rem(1),
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)',
      width: rem(16),
    },
    lineHeight: `calc(${ROW_HEIGHT} - ${rem(3)})`,
  },
})

export const indent = (_indent: number): SerializedStyles =>
  css({
    gridColumnStart: _indent,
  })
