import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import {
  AutocompleteResult,
  AutocompleteSearchOptions,
  AutocompleteVisitCount,
  SearchSuggestionsResult,
  SearchType,
} from 'invictus-sdk-typescript'

import { useAutocompleteSdk } from '@Hooks/sdk/useAutocompleteSdk'
import { QUERY_KEY } from '@Types/queryKey'

export type SearchAutocompleteQueryOptions<AutocompleteModelType = AutocompleteResult> = UseQueryOptions<
  AutocompleteResult,
  unknown,
  AutocompleteModelType,
  (string | boolean)[]
>

const defaultOptions = {
  retry: false,
  staleTime: 5 * 60 * 1000,
}

export const useSearchAutocompleteQuery = <AutocompleteModelType = AutocompleteResult>(
  searchValue: string,
  overriddenOptions?: SearchAutocompleteQueryOptions<AutocompleteModelType>,
  searchOptions: AutocompleteSearchOptions = {}
): UseQueryResult<AutocompleteModelType> => {
  const autocompleteSdk = useAutocompleteSdk()

  const options = { ...defaultOptions, ...overriddenOptions }
  const getAutocompleteItems = async () => autocompleteSdk.autocomplete(searchValue, searchOptions)

  const { location, keepStationsOnly, isInFranceOnly, returnsSuggestions } = searchOptions
  const searchOptionsKey = [
    'location',
    ...(location ? ['latitude', `${location.latitude}`, 'longitude', `${location.longitude}`] : []),
    'keepStationsOnly',
    !!keepStationsOnly,
    'isInFranceOnly',
    !!isInFranceOnly,
    'returnsSuggestions',
    !!returnsSuggestions,
  ]

  return useQuery([QUERY_KEY.searchAutocomplete, searchValue, ...searchOptionsKey], getAutocompleteItems, options)
}

type SearchAutocompleteSuggestionsQueryOptions = UseQueryOptions<
  SearchSuggestionsResult,
  unknown,
  SearchSuggestionsResult,
  (string | AutocompleteVisitCount | SearchType | undefined)[]
>

const defaultSuggestionsOptions: SearchAutocompleteSuggestionsQueryOptions = {
  retry: false,
  staleTime: 0,
}

export const useSearchAutocompleteSuggestionsQuery = (
  searchType: SearchType,
  autocompleteVisitCount?: AutocompleteVisitCount,
  overriddenOptions?: SearchAutocompleteSuggestionsQueryOptions
): UseQueryResult<SearchSuggestionsResult> => {
  const autocompleteSdk = useAutocompleteSdk()

  const options = { ...defaultSuggestionsOptions, ...overriddenOptions }

  const getSuggestionsItems = async () => autocompleteSdk.suggestions(searchType, autocompleteVisitCount)

  return useQuery({
    queryKey: [QUERY_KEY.searchAutocompleteSuggestions, searchType, autocompleteVisitCount],
    queryFn: getSuggestionsItems,
    ...options,
  })
}
