// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Accessibility = svgLazyLoader('services/accessibility')
const AirConditioner = svgLazyLoader('services/air_conditioner')
const AlarmClock = svgLazyLoader('services/alarm_clock')
const Bar = svgLazyLoader('services/bar')
const Bike = svgLazyLoader('services/bike')
const BikeCover = svgLazyLoader('services/bike_cover')
const BlanketAndPillow = svgLazyLoader('services/blanket_and_pillow')
const Box = svgLazyLoader('services/box')
const BusinessPriorityAccess = svgLazyLoader('services/business_priority_access')
const CalmSpace = svgLazyLoader('services/calm_space')
const Cleaning = svgLazyLoader('services/cleaning')
const CoffeeBar = svgLazyLoader('services/coffee_bar')
const ComfortKit = svgLazyLoader('services/comfort_kit')
const CompanionDog = svgLazyLoader('services/companion_dog')
const Electricity = svgLazyLoader('services/electricity')
const ElectricityAdd = svgLazyLoader('services/electricity_add')
const Elevator = svgLazyLoader('services/elevator')
const Entertainment = svgLazyLoader('services/entertainment')
const Escalator = svgLazyLoader('services/escalator')
const FoldedBike = svgLazyLoader('services/folded_bike')
const Game = svgLazyLoader('services/game')
const HomeAppliance = svgLazyLoader('services/home_appliance')
const ItinerantSale = svgLazyLoader('services/itinerant_sale')
const LargeLuggage = svgLazyLoader('services/large_luggage')
const Lift = svgLazyLoader('services/lift')
const LuggagesCatch = svgLazyLoader('services/luggages_catch')
const LuggagesOnBoard = svgLazyLoader('services/luggages_on_board')
const MobileAtering = svgLazyLoader('services/mobile_atering')
const Newspapers = svgLazyLoader('services/newspapers')
const Nursery = svgLazyLoader('services/nursery')
const OnboardServices = svgLazyLoader('services/onboard_services')
const PaidBike = svgLazyLoader('services/paid_bike')
const Parking = svgLazyLoader('services/parking')
const QuietPlaceCatch = svgLazyLoader('services/quiet_place_catch')
const SeatMeal = svgLazyLoader('services/seat_meal')
const Service = svgLazyLoader('services/service')
const Shower = svgLazyLoader('services/shower')
const SilentSpace = svgLazyLoader('services/silent_space')
const Takeaway = svgLazyLoader('services/takeaway')
const TgvFamily = svgLazyLoader('services/tgv_family')
const Toilette = svgLazyLoader('services/toilette')
const TravelingLounge = svgLazyLoader('services/traveling_lounge')
const Usb = svgLazyLoader('services/usb')
const Wifi = svgLazyLoader('services/wifi')

const ALL_SERVICES_ICONS = [
  'accessibility',
  'air_conditioner',
  'alarm_clock',
  'bar',
  'bike',
  'bike_cover',
  'blanket_and_pillow',
  'box',
  'business_priority_access',
  'calm_space',
  'cleaning',
  'coffee_bar',
  'comfort_kit',
  'companion_dog',
  'electricity',
  'electricity_add',
  'elevator',
  'entertainment',
  'escalator',
  'folded_bike',
  'game',
  'home_appliance',
  'itinerant_sale',
  'large_luggage',
  'lift',
  'luggages_catch',
  'luggages_on_board',
  'mobile_atering',
  'newspapers',
  'nursery',
  'onboard_services',
  'paid_bike',
  'parking',
  'quiet-place-catch',
  'seat_meal',
  'service',
  'shower',
  'silent_space',
  'takeaway',
  'tgv_family',
  'toilette',
  'traveling_lounge',
  'usb',
  'wifi',
] as const
export type ServicesIcon = (typeof ALL_SERVICES_ICONS)[number]

const isServicesIcon = (servicesKey: string): servicesKey is ServicesIcon =>
  ALL_SERVICES_ICONS.includes(servicesKey as ServicesIcon)

const ICONS: LazyIconsGroup<ServicesIcon> = {
  accessibility: Accessibility,
  air_conditioner: AirConditioner,
  alarm_clock: AlarmClock,
  bar: Bar,
  bike: Bike,
  bike_cover: BikeCover,
  blanket_and_pillow: BlanketAndPillow,
  box: Box,
  business_priority_access: BusinessPriorityAccess,
  calm_space: CalmSpace,
  cleaning: Cleaning,
  coffee_bar: CoffeeBar,
  comfort_kit: ComfortKit,
  companion_dog: CompanionDog,
  electricity: Electricity,
  electricity_add: ElectricityAdd,
  elevator: Elevator,
  entertainment: Entertainment,
  escalator: Escalator,
  folded_bike: FoldedBike,
  game: Game,
  home_appliance: HomeAppliance,
  itinerant_sale: ItinerantSale,
  large_luggage: LargeLuggage,
  lift: Lift,
  luggages_catch: LuggagesCatch,
  luggages_on_board: LuggagesOnBoard,
  mobile_atering: MobileAtering,
  newspapers: Newspapers,
  nursery: Nursery,
  onboard_services: OnboardServices,
  paid_bike: PaidBike,
  parking: Parking,
  'quiet-place-catch': QuietPlaceCatch,
  seat_meal: SeatMeal,
  service: Service,
  shower: Shower,
  silent_space: SilentSpace,
  takeaway: Takeaway,
  tgv_family: TgvFamily,
  toilette: Toilette,
  traveling_lounge: TravelingLounge,
  usb: Usb,
  wifi: Wifi,
}

export const getServicesIcon = (servicesKey: string | undefined): LazyIcon | undefined =>
  servicesKey && isServicesIcon(servicesKey) ? ICONS[servicesKey] : undefined

export const getServicesIcons = (): LazyIconsGroup<ServicesIcon> => ICONS
