import { FC, ReactNode } from 'react'
import { FormHelperText, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as AlertIcon } from '@Svg/mea/alert.svg'

import * as styles from './styles'

interface FormErrorHelperProps {
  dataTest?: string
  error?: boolean
  helperText: ReactNode
  id?: string
}

const FormErrorHelper: FC<FormErrorHelperProps> = ({ error, dataTest, id, helperText }) => {
  const theme = useTheme()

  return (
    <FormHelperText error={error} id={id} data-test={dataTest} css={styles.helperTextMessage(theme)}>
      <AlertIcon css={styles.helperTextAlertIcon(theme)} />
      <Typography variant="subtitle1" component="span">
        {helperText}
      </Typography>
    </FormHelperText>
  )
}

export default FormErrorHelper
