import { ComponentType, ElementType, FC, forwardRef } from 'react'
import { css } from '@emotion/react'
import { Button as MaterialButton, ButtonTypeMap as MaterialButtonTypeMap, ExtendButtonBase } from '@mui/material'
import { OverrideProps } from '@mui/material/OverridableComponent'
import { useTheme } from '@mui/material/styles'

import Loader from '@DS/components/components/loaders/loader/Loader'

import * as styles from './styles'

type Surface = 'onBackground' | 'onAlternativeColor' | 'onHeader'
type Variant = 'primary' | 'secondary'

/**
 * Props à ajouter à celles de base du composant Material
 */
type IncludingMaterialButtonProps = {
  loading?: boolean
  surface?: Surface
  icon?: ComponentType
  loaderAriaLabel?: string
}
/**
 * Props à exclure de celles de base du composant Material, car elles seraient ignorées
 */
type ExcludingMaterialButtonProps = 'variant' | 'color' | 'size' | 'startIcon' | 'disableElevation' | 'disabled'
/**
 * Props existant déjà dans le composant Material qu'on veut surcharger
 */
type OverridingMaterialButtonProps = {
  variant?: Variant
  // On ne permet pas que ce composant DS soit "disabled", voir avec
  // (Grégory PUJOL)[gregory_pujol@connect-tech.sncf] si besoin de lever la restriction
  disabled?: false
}
/**
 * "Re-build" des props de base du composant Material dans le but de les "customiser"
 */
type FixedMaterialButtonProps<ButtonComponentType extends ElementType> = MaterialButtonTypeMap<
  IncludingMaterialButtonProps,
  ButtonComponentType
>
/**
 * Type utilitaire qui servira à "builder" les props du composant actuel via le système d'extension interne de
 * Material, en "customisant" au passage les props de base du composant Material
 */
type ButtonTypeMap<ButtonComponentType extends ElementType = MaterialButtonTypeMap['defaultComponent']> = Omit<
  FixedMaterialButtonProps<ButtonComponentType>,
  'props'
> & {
  props: Omit<FixedMaterialButtonProps<ButtonComponentType>['props'], ExcludingMaterialButtonProps> &
    OverridingMaterialButtonProps
}

/**
 * Type final des props du composant actuel, "buildé" depuis le système d'extension interne de Material
 */
export type ButtonProps<ButtonComponentType extends ElementType = MaterialButtonTypeMap['defaultComponent']> =
  OverrideProps<ButtonTypeMap<ButtonComponentType>, ButtonComponentType> & { component?: ButtonComponentType }

/**
 * On ne peut pas utiliser directement le type `ExtendButtonBase<ButtonTypeMap>` car TS n'arrive pas à gérer
 * l'"overloading" à ce niveau et génère une erreur de compatibilité
 */
const Button: FC<ButtonProps> = forwardRef(
  (
    {
      children,
      loading = false,
      surface = 'onBackground',
      icon: Icon,
      variant = 'primary',
      disableRipple = false,
      loaderAriaLabel,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme()
    const isPrimary = variant === 'primary'

    return (
      <>
        {loading ? (
          <div css={css([styles.loadingWrapper, rest.fullWidth && styles.fullWidth])} className={rest.className}>
            <Loader size={32} aria-label={loaderAriaLabel} />
          </div>
        ) : (
          <MaterialButton
            ref={ref}
            {...rest}
            disableFocusRipple
            disableRipple={disableRipple}
            startIcon={Icon && <Icon css={styles.buttonIcon} />}
            variant={variant === 'primary' ? 'contained' : 'outlined'}
            color="primary"
            css={css([
              styles.button(theme),
              surface === 'onBackground' && styles.onBackground(theme, isPrimary),
              surface === 'onAlternativeColor' && styles.onAlternative(theme, isPrimary),
              surface === 'onHeader' && styles.onHeader(theme, isPrimary),
            ])}
          >
            {children}
          </MaterialButton>
        )}
      </>
    )
  }
)

/**
 * Cast du type du composant pour une meilleure intégration à l'utilisation
 */
export default Button as ExtendButtonBase<ButtonTypeMap>
