import axios, { type AxiosInstance } from 'axios'

import { type BaseAPI } from './generated/base'
import { AbstractSdk, type AbstractSdkConstructorParameters } from './abstract'
import { AuthenticationSdk } from './authentication'
import { type Configuration } from './generated'
import { addAxiosRequestInterceptors } from './interceptors'
import { TrackingSdk } from './tracking'
import { type UserSdk } from './user'

export type SdkConstructor<Sdk extends AbstractSdk> = new (...args: AbstractSdkConstructorParameters) => Sdk

export abstract class AbstractSelfInitSdk<ControllerApi extends BaseAPI = BaseAPI> extends AbstractSdk<ControllerApi> {
  static init<Sdk extends AbstractSdk>(
    this: SdkConstructor<Sdk>,
    configuration: Configuration,
    userSdk: UserSdk,
    authenticationSdkParam?: AuthenticationSdk,
    axiosInstanceParam?: AxiosInstance
  ): Sdk {
    const axiosInstance = axiosInstanceParam || axios.create()
    const trackingSdk = new TrackingSdk(configuration, userSdk, axiosInstance)

    const authenticationSdk =
      authenticationSdkParam || new AuthenticationSdk(trackingSdk, configuration, userSdk, axiosInstance)

    if (!axiosInstanceParam) {
      addAxiosRequestInterceptors(axiosInstance, userSdk, authenticationSdk)
    }

    return new this(configuration, userSdk, axiosInstance)
  }

  constructor(...args: AbstractSdkConstructorParameters) {
    super(...args)
  }
}
