import { add, addMinutes, addMonths, type Duration, isAfter } from 'date-fns'

import { VisitorType } from '../enums'

import { type ExpirableDataInterface } from './tracking.types'

export abstract class ExpirableData implements ExpirableDataInterface {
  expirationDate: Date

  constructor(expirationDate: Date = new Date()) {
    this.expirationDate = expirationDate
  }

  isExpired = (): boolean => isAfter(new Date(), new Date(this.expirationDate))

  abstract resetExpirationDate(): void
}

export class StringExpirableData extends ExpirableData {
  value: string

  duration: Duration

  constructor(value = '', duration: Duration = { minutes: 30 }) {
    super(add(new Date(), duration))
    this.value = value
    this.duration = duration
  }

  resetExpirationDate(): StringExpirableData {
    this.expirationDate = add(new Date(), this.duration)

    return this
  }

  setExpirationDate(expirationDate: Date): StringExpirableData {
    this.expirationDate = expirationDate

    return this
  }
}

export class StringExpirableDataMapper {
  static toJSON: (data: StringExpirableData) => string = JSON.stringify

  static fromJSON = (json: string | undefined | null): StringExpirableData => {
    const result = new StringExpirableData()
    if (!json) return result

    try {
      return Object.assign(result, JSON.parse(json))
    } catch (e) {
      return new StringExpirableData()
    }
  }
}

export class FirstVisitData extends ExpirableData {
  resetExpirationDate(): FirstVisitData {
    this.expirationDate = addMinutes(new Date(), 30)

    return this
  }
}

export class FirstVisitDataMapper {
  static toJSON: (firstvisitData: FirstVisitData) => string = JSON.stringify

  static fromJSON = (json: string | undefined | null): FirstVisitData => {
    const result = new FirstVisitData(new Date())
    result.resetExpirationDate()

    if (!json) {
      return result
    }

    try {
      Object.assign(result, JSON.parse(json))
      if (!result.expirationDate) result.resetExpirationDate()

      return result
    } catch (e) {
      return result
    }
  }
}

export class VisitorTypeData extends ExpirableData {
  visitorType: VisitorType

  constructor(expirationDate: Date, visitorType: VisitorType) {
    super(expirationDate)
    this.visitorType = visitorType
  }

  resetExpirationDate(): VisitorTypeData {
    this.expirationDate = addMonths(new Date(), 12)

    return this
  }
}

export class VisitorTypeDataMapper {
  static toJSON: (visitorTypeData: VisitorTypeData) => string = JSON.stringify

  static fromJSON = (json: string | undefined | null): VisitorTypeData => {
    const result = new VisitorTypeData(new Date(), VisitorType.NEW_USER)
    result.resetExpirationDate()
    if (!json) return result

    try {
      return Object.assign(result, JSON.parse(json))
    } catch (e) {
      return new VisitorTypeData(new Date(), VisitorType.NEW_USER).resetExpirationDate()
    }
  }
}

export class EmailData extends StringExpirableData {
  constructor(value = '') {
    super(value, { months: 12 })
  }
}

export class ClidData extends StringExpirableData {
  constructor(value = '') {
    super(value, { minutes: 30 })
  }
}

export class GclidData extends StringExpirableData {
  constructor(value = '') {
    super(value, { days: 30 })
  }
}

export class CrmData extends StringExpirableData {
  constructor(value = '') {
    super(value, { minutes: 30 })
  }
}

export class CriteoIdData extends StringExpirableData {
  constructor(value = '') {
    super(value, { months: 13 })
  }
}

export class ZaidData extends StringExpirableData {
  constructor(value = '') {
    super(value, { minutes: 30 })
  }
}
