// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const FamilyArea = svgLazyLoader('area/family_area')
const KidsArea = svgLazyLoader('area/kids_area')
const ProArea = svgLazyLoader('area/pro_area')
const ProAreaFull = svgLazyLoader('area/pro_area_full')
const QuietArea = svgLazyLoader('area/quiet_area')

const ALL_AREA_ICONS = ['family_area', 'kids_area', 'pro_area', 'pro_area_full', 'quiet_area'] as const
export type AreaIcon = (typeof ALL_AREA_ICONS)[number]

const isAreaIcon = (areaKey: string): areaKey is AreaIcon => ALL_AREA_ICONS.includes(areaKey as AreaIcon)

const ICONS: LazyIconsGroup<AreaIcon> = {
  family_area: FamilyArea,
  kids_area: KidsArea,
  pro_area: ProArea,
  pro_area_full: ProAreaFull,
  quiet_area: QuietArea,
}

export const getAreaIcon = (areaKey: string | undefined): LazyIcon | undefined =>
  areaKey && isAreaIcon(areaKey) ? ICONS[areaKey] : undefined

export const getAreaIcons = (): LazyIconsGroup<AreaIcon> => ICONS
