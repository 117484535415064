export const LIGHT_BLUE = '#8DE8FE'
export const BLUE = '#40CDF2'
export const DARK_BLUE = '#0C131F'
export const BLUE_INFO = '#1364C3'
export const ANTHRACITE = '#242B35'
export const WHITE = '#F3F3F4'
export const GREY = '#9DA0A5'
export const SLATE_GREY = '#A5ACBC'
export const DARK_GREY = '#263441'
export const XTRA_DARK_BLUE = '#0C131F'
export const DISABLED = '#506876'
export const RED_ALERT = '#FF5072'
export const ORANGE_WARNING = '#ED8744'
export const GREEN_SUCCESS = '#6BDB64'
export const GREEN_ECO = '#AAEA93'
export const PURPLE = '#C099FF'
export const YELLOW = '#F6DF5A'
export const BLACK = '#000000'
export const EXTRA_ICON_1 = '#F3BB67'
export const EXTRA_ICON_2 = '#7EDAD4'
export const EXTRA_ICON_3 = '#E39BFD'
export const EXTRA_ICON_4 = '#CFCFE5'
export const BUSINESS_PREMIERE = '#464645'
export const TGVMAX1 = '#1B878F'
export const TGVMAX2 = '#D2E100'
export const OUIGO = '#E5006D'
export const ALLIANZ_ORANGE = '#FF7300'
export const INOUI1 = '#B31B43'
export const INOUI2 = '#AAA69D'
export const EUROSTAR = '#06183D'
export const TER = '#034EA2'
export const CARTE_ABONNEMENT = '#007398'
export const CARTE_LIBERTE = '#9B2743'
export const CARTE_AVANTAGE_BACKGROUND = '#1D1E1A'
export const CARTE_AVANTAGE_POINTS_BACKGROUND = '#000000'
export const CARTE_AVANTAGE = '#BACACA'
export const CARTE_AVANTAGE_LE_CLUB = '#B99A4E'
export const IDFM = '#56B3E5'
export const ON_ALTERNATIVE_COLOR_1 = '#0C131F'
export const ON_ALTERNATIVE_COLOR_2 = '#F3F3F4'
export const ON_ALTERNATIVE_COLOR_3 = '#0C131F'
export const ON_ALTERNATIVE_COLOR_4 = '#F3F3F4'
export const CART = '#4AEAB0'
export const CART_HOVER = '#4AC196'
export const ALLIANZ_BLUE = '#003781'
export const ON_SERVICE = '#B48BF6'
export const ON_COMMMUNICATION = '#EACE3C'
export const ON_INFO = '#60ACF2'
export const URANIAN_BLUE = '#B8D9F0'
export const ARCTIC_BLUE = '#DAEAF3'
export const CORNFLOWER_BLUE = '#A2BBEE'
export const WARNING_BACKGROUND = '#221F23'
export const SUCCESS_BACKGROUND = '#152726'
export const INFO_BACKGROUND = '#172435'
export const ERROR_BACKGROUND = '#240D12'
export const IV_WARNING_BACKGROUND = '#221F23'
export const IV_SUCCESS_BACKGROUND = '#152726'
export const IV_INFO_BACKGROUND = '#172435'
export const IV_ERROR_BACKGROUND = '#240D12'
export const IV_NEUTRAL_BACKGROUND = '#141D29'
export const HOME_PAGE_BACKGROUND = '#0C131F'
export const INVERTED_COLORS_ON_BACKGROUND = '#0C131F'
export const INVERTED_COLORS_SURFACE = '#FFFFFF'
export const HOME_PAGE_ILLUSTRATION = '#F6DF5A'
export const SEARCH_BUTTON = '#8DE8FE'
export const INVERTED_CURSOR = '#14708A'
export const INVERTED_PLACEHOLDER = '#5E6878'
export const INVERTED_COLORS_TEXT_SECONDARY = '#5E6878'
export const INVERTED_COLORS_ELEVATED = '#F3F3F8'
export const INVERTED_COLORS_SELECTED = '#E5E5EF'
export const INVERTED_COLORS_ACTION = '#14708A'
export const INVERTED_COLORS_ACTION_HOVER = '#006179'
export const INVERTED_COLORS_BACKGROUND = '#F3F3F8'
export const INVERTED_COLORS_SUCCESS = '#007C33'
export const INVERTED_COLORS_WARNING = '#D6663D'
export const INVERTED_COLORS_ERROR = '#CD0037'
export const NOTIFICATION = '#FF5072'
export const WARNING = '#ED8744'
export const SUCCESS = '#6BDB64'
export const ALTERNATIVE_COLORS_AGENT = '#E78288'
export const SURFACE = '#242B35'

export const LIGHT_BLUE_LIGHT_MODE = '#14708A'
export const RIPPLE_GREY = '#BCC0D0'
export const BLUE_LIGHT_MODE = '#006179'
export const BLUE_INFO_LIGHT_MODE = '#1364C3'
export const WHITE_LIGHT_MODE = '#FFF'
export const LIGHT_GREY_LIGHT_MODE = '#F3F3F8'
export const DISABLED_LIGHT_MODE = '#90B2BA'
export const GREY_LIGHT_MODE = '#5E6878'
export const SLATE_GREY_LIGHT_MODE = '#848B9B'
export const OVERLAY_GREY_LIGHT_MODE = '#464D5E'
export const GREY_SELECTED_LIGHT_MODE = '#E5E5EF'
export const RED_ALERT_LIGHT_MODE = '#CD0037'
export const DARK_RED_ALERT_LIGHT_MODE = '#9E022C'
export const ORANGE_WARNING_LIGHT_MODE = '#AE4713'
export const GREEN_SUCCESS_LIGHT_MODE = '#047834'
export const GREEN_ECO_LIGHT_MODE = '#118833'
export const PURPLE_LIGHT_MODE = '#762BE2'
export const YELLOW_LIGHT_MODE = '#F1C83C'
export const EXTRA_ICON_1_LIGHT_MODE = '#F2970F'
export const EXTRA_ICON_2_LIGHT_MODE = '#3FBAB4'
export const EXTRA_ICON_3_LIGHT_MODE = '#DE82FF'
export const EXTRA_ICON_4_LIGHT_MODE = '#F282B4'
export const ON_EXTRA_ICON_2_LIGHT_MODE = '#14AAA3'
export const ON_ALTERNATIVE_COLOR_1_LIGHT_MODE = '#0C131F'
export const ON_ALTERNATIVE_COLOR_2_LIGHT_MODE = '#FFFFFF'
export const ON_ALTERNATIVE_COLOR_3_LIGHT_MODE = '#FFFFFF'
export const ON_ALTERNATIVE_COLOR_4_LIGHT_MODE = '#0C131F'
export const ON_SERVICE_LIGHT_MODE = '#5316AB'
export const ON_COMMUNICATION_LIGHT_MODE = '#EBBA0E'
export const ON_INFO_LIGHT_MODE = '#1253A0'
export const HOME_PAGE_ILLUSTRATION_LIGHT_MODE = '#FFFFFF'
export const WARNING_BACKGROUND_LIGHT_MODE = '#FFFFFF'
export const SUCCESS_BACKGROUND_LIGHT_MODE = '#FFFFFF'
export const INFO_BACKGROUND_LIGHT_MODE = '#FFFFFF'
export const ERROR_BACKGROUND_LIGHT_MODE = '#FFFFFF'
export const IV_WARNING_BACKGROUND_LIGHT_MODE = '#FFF2EC'
export const IV_SUCCESS_BACKGROUND_LIGHT_MODE = '#EEF7F2'
export const IV_INFO_BACKGROUND_LIGHT_MODE = '#EFF3FA'
export const IV_ERROR_BACKGROUND_LIGHT_MODE = '#FFF0F0'
export const IV_NEUTRAL_BACKGROUND_LIGHT_MODE = '#EAEAF3'
export const SEARCH_BUTTON_LIGHT_MODE = '#0C131F'
export const INVERTED_COLORS_BACKGROUND_LIGHT_MODE = '#0C131F'
export const INVERTED_COLORS_SELECTED_LIGHT_MODE = '#293846'
export const HOME_PAGE_BACKGROUND_LIGHT_MODE = '#14708A'
export const INVERTED_CURSOR_LIGHT_MODE = '#14708A'
export const INVERTED_PLACEHOLDER_LIGHT_MODE = '#5E6878'
export const INVERTED_COLORS_SUCCESS_LIGHT_MODE = '#6BDB64'
export const INVERTED_COLORS_WARNING_LIGHT_MODE = '#ED8744'
export const INVERTED_COLORS_ERROR_LIGHT_MODE = '#FF5072'
export const INVERTED_COLORS_TEXT_SECONDARY_LIGHT_MODE = '#9DA0A5'
export const INVERTED_COLORS_ELEVATED_LIGHT_MODE = '#242B35'
export const INVERTED_COLORS_SURFACE_LIGHT_MODE = '#242B35'
export const INVERTED_COLORS_ACTION_LIGHT_MODE = '#8DE8FE'
export const INVERTED_COLORS_ACTION_HOVER_LIGHT_MODE = '#40CDF2'
export const INVERTED_COLORS_ON_BACKGROUND_LIGHT_MODE = '#F3F3F4'
export const NOTIFICATION_LIGHT_MODE = '#CD0037'
export const ALTERNATIVE_COLORS_AGENT_LIGHT_MODE = '#E78288'
export const SURFACE_LIGHT_MODE = '#FFFFFF'

export const GREEN_YELLOW = '#ADFF2F' // Couleur fluo positionnée sur les couleurs du thème material que l'on ne souhaite pas utiliser

export const LAVENDER_CHARM = '#4553D7'
export const NOBLE_GREY = '#F1F2FD'
