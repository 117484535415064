import { FC } from 'react'

import AutocompleteResult, { AutocompleteResultModel } from '../atoms/AutocompleteResult'

import { AutocompleteBaseProps } from './Autocomplete.types'
import GenericAutocomplete from './GenericAutocomplete'

export type AutocompleteProps = AutocompleteBaseProps<AutocompleteResultModel>

const Autocomplete: FC<AutocompleteProps> = ({ autocompleteRef, children, ...props }) => (
  <GenericAutocomplete AutocompleteItemComponent={AutocompleteResult} {...props} ref={autocompleteRef}>
    {children}
  </GenericAutocomplete>
)

export default Autocomplete
