// HEADERS
export const CHANNEL_HEADER = 'x-client-channel' // mobile or web
export const LOCALE_HEADER = 'x-market-locale' // fr_FR or es_ES
export const EMAIL_HIDDEN_HEADE = 'x-email-hidden' // SHA1
export const EMAIL_STRONG_HEADER = 'x-email-strong' // SHA256
export const EMAIL_STRONGER_HEADER = 'x-email-stronger' // HMAC-SHA256
export const CONSENT_VENDORS_HEADER = 'x-con-v'
export const CONSENT_STRING_HEADER = 'x-con-s'
export const CONSENT_ID_HEADER = 'x-con-id'
export const CONSENT_EXEMPT_ID_HEADER = 'x-con-ex'
export const ENV_HEADER = 'x-api-env'
export const CLIENT_APP_ID_HEADER = 'x-client-app-id' // front-web
export const DEVICE_OS_VERSION_HEADER = 'x-device-os-version' // Android Samsung xv25
export const DEVICE_CLASS_HEADER = 'x-device-class' // [smartphone|tablet]
export const APP_VERSION_HEADER = 'x-app-version' // 20201002-unknown [date build]-[hashgit]
export const BFF_KEY_HEADER = 'x-bff-key'
export const NAVIGATION_PREVIOUS_HEADER = 'x-nav-previous-page'
export const NAVIGATION_CURRENT_HEADER = 'x-nav-current-path'
export const NAVIGATION_PAGE_PARAMS = 'x-nav-page-params'
export const NAVIGATION_SESSION = 'x-nav-session-id'
export const VISITOR_TYPE_HEADER = 'x-visitor-type'
export const EVENTS_PUSH_HEADER = 'x-events-push'
export const EVENTS_CLICK_HEADER = 'x-events-click'
export const TRACE_ID_HEADER = 'x-trace-id'
export const ATTRIBUTION_MEDIUM_HEADER = 'x-attribution-medium'
export const ATTRIBUTION_SOURCE_HEADER = 'x-attribution-source'
export const ATTRIBUTION_CAMPAIGN_HEADER = 'x-attribution-campaign'
export const ATTRIBUTION_CONTENT_HEADER = 'x-attribution-content'
export const ATTRIBUTION_TERM_HEADER = 'x-attribution-term'
export const ATTRIBUTION_REFERRER_HEADER = 'x-attribution-referrer'
export const ATTRIBUTION_CLID_HEADER = 'x-attribution-clid'
export const ATTRIBUTION_CRM_HEADER = 'x-attribution-crm'
export const ATTRIBUTION_PREX_HEADER = 'x-attribution-prex'
export const ATTRIBUTION_ID_COMPTEUR_HEADER = 'x-attribution-id-compteur'
export const ATTRIBUTION_EFFI_ID_HEADER = 'x-attribution-effi-id'
export const ATTRIBUTION_EFFI_ID2_HEADER = 'x-attribution-effi-id2'
export const ATTRIBUTION_GCLID_HEADER = 'x-attribution-gclid'
export const FLAGSHIP_HEADER = 'x-flagship'
export const CRITEO_ID_HEADER = 'x-criteo-id'
export const VIRTUAL_ENV_NAME = 'virtual-env-name'
export const ZEMANTA_ID_HEADER = 'x-zemanta-id'
export const SEARCH_USAGE_HEADER = 'x-search-usage'

// QUERY PARAMS
export const WIZ_MEDIUM = 'wiz_medium'
export const WIZ_SOURCE = 'wiz_source'
export const WIZ_COMPAIGN = 'wiz_campaign'
export const WIZ_TERM = 'wiz_term'
export const WIZ_CONTENT = 'wiz_content'
export const WIZ_CLID = 'wiz_clid'
export const WIZ_CRM = 'wiz_crm'
export const GCLID = 'gclid'
export const PREX = 'prex'
export const ID_COMPTEUR = 'id_compteur'
export const EFFI_ID = 'effi_id'
export const EFFI_ID2 = 'effi_id2'
export const POST_BACK_ID = 'postbackid'
export const ZEMANTA_ID = 'zaid'
