import { useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const useIsSmallUp = (theme: Theme): boolean => useMediaQuery(theme.breakpoints.up('sm'))

export const useIsMediumUp = (theme: Theme): boolean => useMediaQuery(theme.breakpoints.up('md'))

export const useIsLargeUp = (theme: Theme): boolean => useMediaQuery(theme.breakpoints.up('lg'))

// Using this hook with NextJS will break ServerSide Rendering
export const useIsLargeUpCSR = (theme: Theme): boolean => useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true })
