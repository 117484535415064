import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { SPACING_1 } from '@DS/styles/constants/spacing'

import { CommonCardBackground } from './types'

export const cardBackground = (theme: Theme, background: CommonCardBackground): SerializedStyles =>
  css({
    background: theme.palette.background[background],
  })

// Not applicable to BasicCardButton & BasicCard because they don't need an internal CardContent by default and they are 54px high
export const cardContentBase = (hasNoSpacingIcon = false): SerializedStyles =>
  css({
    // TODO : gap: SPACING_1,
    '& > * + *': {
      marginLeft: hasNoSpacingIcon ? 0 : SPACING_1,
    },
    alignItems: 'center',
    display: 'flex',
    flex: '1 1',
    minHeight: rem(56),
  })

export const cardContentChildrenWrapperBase = css({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 1,
  // 34px is a magic number without needed pass the icon size
  width: 'calc(100% - 34px)',
})

export const bottomChild = css({
  minHeight: rem(32),
})
