import { Configuration, DrupalConfiguration } from 'invictus-sdk-typescript'

import { getPublicRuntimeConfig, getServerRuntimeConfig } from '@Utils/config'

export const getFrontendApiConfiguration = (): Configuration =>
  new Configuration({
    basePath: getPublicRuntimeConfig().frontendApiURL,
  })

export const getBackendApiConfiguration = (): Configuration =>
  new Configuration({
    basePath: getServerRuntimeConfig().backendApiURL,
  })

export const getDrupalApiConfiguration = (): DrupalConfiguration.Configuration => {
  const { drupalApiURL, drupalApiKey } = getServerRuntimeConfig()

  return new DrupalConfiguration.Configuration({
    apiKey: drupalApiKey,
    basePath: drupalApiURL,
  })
}
