// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Waiting = svgLazyLoader('walk/waiting')
const Walking = svgLazyLoader('walk/walking')

const ALL_WALK_ICONS = ['waiting', 'walking'] as const
export type WalkIcon = (typeof ALL_WALK_ICONS)[number]

const isWalkIcon = (walkKey: string): walkKey is WalkIcon => ALL_WALK_ICONS.includes(walkKey as WalkIcon)

const ICONS: LazyIconsGroup<WalkIcon> = {
  waiting: Waiting,
  walking: Walking,
}

export const getWalkIcon = (walkKey: string | undefined): LazyIcon | undefined =>
  walkKey && isWalkIcon(walkKey) ? ICONS[walkKey] : undefined

export const getWalkIcons = (): LazyIconsGroup<WalkIcon> => ICONS
