import { FC, HTMLAttributes, ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Interpolation, Theme } from '@emotion/react'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useIsMediumUp } from '@Hooks/useResponsive'

import * as styles from '../dateSearch/styles'

import RemoveInputButton from './RemoveInputButton'

export type DateTimeButtonProps = {
  placeholder: string
  startIcon?: ReactNode
  buttonLabelId?: string
  value: string
  onClick: VoidFunction
  onClear?: VoidFunction
  isDisabled?: boolean
  isVisible?: boolean
  isWrapped?: boolean
  isCompactedOnMobile?: boolean
  css?: Interpolation<Theme>
} & HTMLAttributes<HTMLButtonElement>

const DateTimeButton: FC<DateTimeButtonProps> = ({
  placeholder,
  startIcon,
  buttonLabelId = 'trip_outwardDateLabel',
  value,
  onClick,
  onClear,
  isDisabled = false,
  isVisible = false,
  isWrapped = false,
  isCompactedOnMobile = true,
  ...rest
}) => {
  const theme = useTheme()
  const isMediumUp = useIsMediumUp(theme)
  const { formatMessage } = useIntl()

  if (!isVisible) {
    return null
  }

  const isClearButtonVisible = !isDisabled && !!value && !!onClear

  const getValueLabel = () => {
    if (!isCompactedOnMobile || (isMediumUp && buttonLabelId)) {
      return (
        <span css={styles.labelId(theme, isDisabled)}>
          <FormattedMessage
            css={styles.labelId(theme, isDisabled)}
            id={buttonLabelId}
            values={{ date: <span css={styles.value(theme, isDisabled)}>{value}</span> }}
          />
        </span>
      )
    }

    return <span css={styles.value(theme, isDisabled)}>{value}</span>
  }

  return (
    <div css={styles.buttonWrapper(isWrapped)}>
      <button
        {...rest}
        css={styles.button(theme, !!value, isDisabled)}
        disabled={isDisabled}
        type="button"
        onClick={onClick}
      >
        {startIcon}
        <Typography component="span">{value ? getValueLabel() : placeholder}</Typography>
      </button>
      {isClearButtonVisible ? (
        <RemoveInputButton
          css={styles.removeButton}
          onClick={(event) => {
            event.stopPropagation()
            onClear()
          }}
          aria-label={formatMessage({
            id: 'input_clearButton_inward_ariaLabel',
          })}
        />
      ) : null}
    </div>
  )
}

export default DateTimeButton
