import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SeverityType } from '@DS/types/severity'
import { getColorBySeverity } from '@DS/utils/severity'

export const container = css({
  display: 'inherit',
  position: 'relative',
})

export const exponentWrapper = (theme: Theme): SerializedStyles =>
  css({
    alignItems: 'center',
    backgroundColor: theme.palette.text.primary,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    right: rem(6),
    top: 0,
    ...size(rem(14)),
  })

export const exponent = (theme: Theme): SerializedStyles =>
  css({
    color: theme.customPalette.onElements.elevated,
    height: rem(10),
    position: 'relative',
    top: rem(-1),
  })

export const icon = (severity: SeverityType, hasWhiteBackground: boolean, theme: Theme): SerializedStyles =>
  css({
    fill: getColorBySeverity(theme, severity, hasWhiteBackground),
    flexShrink: 0,
    ...size(rem(24)),
  })
