/**
 * Next.js `useRouter` hook mock.
 *
 * It mocks the useRouter hook used in LinkBase component, which is used in AppBar component.
 * It prevents the error of loading the hook, even we don't use it in the widget.
 *
 * @returns {Record<string, never>} empty object
 */
export function useRouter(): Record<string, never> {
  return {}
}
