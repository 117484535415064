// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Multiply = svgLazyLoader('navigationAndAction/multiply')
const Pdf = svgLazyLoader('navigationAndAction/pdf')
const AccountOff = svgLazyLoader('navigationAndAction/account_off')
const AccountOn = svgLazyLoader('navigationAndAction/account_on')
const Add = svgLazyLoader('navigationAndAction/add')
const AddAGeolocation = svgLazyLoader('navigationAndAction/add_a_geolocation')
const AddAJourney = svgLazyLoader('navigationAndAction/add_a_journey')
const AddALuggage = svgLazyLoader('navigationAndAction/add_a_luggage')
const AddCircle = svgLazyLoader('navigationAndAction/add_circle')
const AddFull = svgLazyLoader('navigationAndAction/add_full')
const Alert = svgLazyLoader('navigationAndAction/alert')
const AlertNotification = svgLazyLoader('navigationAndAction/alert_notification')
const ArrowBackToTop = svgLazyLoader('navigationAndAction/arrow_back_to_top')
const ArrowCircleDown = svgLazyLoader('navigationAndAction/arrow_circle_down')
const ArrowCircleLeft = svgLazyLoader('navigationAndAction/arrow_circle_left')
const ArrowCircleRight = svgLazyLoader('navigationAndAction/arrow_circle_right')
const ArrowCircleUp = svgLazyLoader('navigationAndAction/arrow_circle_up')
const ArrowDown = svgLazyLoader('navigationAndAction/arrow_down')
const ArrowLeft = svgLazyLoader('navigationAndAction/arrow_left')
const ArrowRight = svgLazyLoader('navigationAndAction/arrow_right')
const ArrowUp = svgLazyLoader('navigationAndAction/arrow_up')
const Back = svgLazyLoader('navigationAndAction/back')
const BackArrow = svgLazyLoader('navigationAndAction/back_arrow')
const Bin = svgLazyLoader('navigationAndAction/bin')
const Camera = svgLazyLoader('navigationAndAction/camera')
const Check = svgLazyLoader('navigationAndAction/check')
const Checkbox = svgLazyLoader('navigationAndAction/checkbox')
const Clock = svgLazyLoader('navigationAndAction/clock')
const ClockFull = svgLazyLoader('navigationAndAction/clock_full')
const CloseCircle = svgLazyLoader('navigationAndAction/close_circle')
const Document = svgLazyLoader('navigationAndAction/document')
const DoubleArrowLeft = svgLazyLoader('navigationAndAction/double_arrow_left')
const DoubleArrowRight = svgLazyLoader('navigationAndAction/double_arrow_right')
const Edit = svgLazyLoader('navigationAndAction/edit')
const Error = svgLazyLoader('navigationAndAction/error')
const Exchange = svgLazyLoader('navigationAndAction/exchange')
const Export = svgLazyLoader('navigationAndAction/export')
const ExternalLink = svgLazyLoader('navigationAndAction/external_link')
const Filter = svgLazyLoader('navigationAndAction/filter')
const Grid = svgLazyLoader('navigationAndAction/grid')
const Help = svgLazyLoader('navigationAndAction/help')
const Home = svgLazyLoader('navigationAndAction/home')
const HomeAdd = svgLazyLoader('navigationAndAction/home_add')
const Images = svgLazyLoader('navigationAndAction/images')
const Import = svgLazyLoader('navigationAndAction/import')
const Info = svgLazyLoader('navigationAndAction/info')
const Key = svgLazyLoader('navigationAndAction/key')
const LeapBack = svgLazyLoader('navigationAndAction/leap_back')
const Less = svgLazyLoader('navigationAndAction/less')
const LessCircle = svgLazyLoader('navigationAndAction/less_circle')
const LessFull = svgLazyLoader('navigationAndAction/less_full')
const Location = svgLazyLoader('navigationAndAction/location')
const Logout = svgLazyLoader('navigationAndAction/logout')
const Menu = svgLazyLoader('navigationAndAction/menu')
const MoreHorizontal = svgLazyLoader('navigationAndAction/more_horizontal')
const NextArrow = svgLazyLoader('navigationAndAction/next_arrow')
const OfferOff = svgLazyLoader('navigationAndAction/offer_off')
const OfferOn = svgLazyLoader('navigationAndAction/offer_on')
const Oneway = svgLazyLoader('navigationAndAction/oneway')
const Paperclip = svgLazyLoader('navigationAndAction/paperclip')
const PopupMenuButtonIcon = svgLazyLoader('navigationAndAction/popup_menu_button_icon')
const QuickReservation = svgLazyLoader('navigationAndAction/quick_reservation')
const Random = svgLazyLoader('navigationAndAction/random')
const Refresh = svgLazyLoader('navigationAndAction/refresh')
const RemoveCircle = svgLazyLoader('navigationAndAction/remove_circle')
const RemoveFull = svgLazyLoader('navigationAndAction/remove_full')
const RemoveInput = svgLazyLoader('navigationAndAction/remove_input')
const Roundtrip = svgLazyLoader('navigationAndAction/roundtrip')
const Search = svgLazyLoader('navigationAndAction/search')
const Settings = svgLazyLoader('navigationAndAction/settings')
const ShareAndroid = svgLazyLoader('navigationAndAction/share_android')
const ShareIos = svgLazyLoader('navigationAndAction/share_ios')
const Sort = svgLazyLoader('navigationAndAction/sort')
const SoundOff = svgLazyLoader('navigationAndAction/sound_off')
const TicketsOff = svgLazyLoader('navigationAndAction/tickets_off')
const TicketsOn = svgLazyLoader('navigationAndAction/tickets_on')
const ToTravelOff = svgLazyLoader('navigationAndAction/to_travel_off')
const ToTravelOn = svgLazyLoader('navigationAndAction/to_travel_on')
const TrafficCone = svgLazyLoader('navigationAndAction/traffic_cone')
const ValidFull = svgLazyLoader('navigationAndAction/valid_full')
const Video = svgLazyLoader('navigationAndAction/video')
const WarningCircle = svgLazyLoader('navigationAndAction/warning_circle')
const WarningSimple = svgLazyLoader('navigationAndAction/warning_simple')

const ALL_NAVIGATION_AND_ACTION_ICONS = [
  'multiply',
  'pdf',
  'account_off',
  'account_on',
  'add',
  'add-a-geolocation',
  'add-a-journey',
  'add-a-luggage',
  'add_circle',
  'add_full',
  'alert',
  'alert_notification',
  'arrow_back_to_top',
  'arrow_circle_down',
  'arrow_circle_left',
  'arrow_circle_right',
  'arrow_circle_up',
  'arrow_down',
  'arrow_left',
  'arrow_right',
  'arrow_up',
  'back',
  'back_arrow',
  'bin',
  'camera',
  'check',
  'checkbox',
  'clock',
  'clock_full',
  'close_circle',
  'document',
  'double_arrow_left',
  'double_arrow_right',
  'edit',
  'error',
  'exchange',
  'export',
  'external_link',
  'filter',
  'grid',
  'help',
  'home',
  'home_add',
  'images',
  'import',
  'info',
  'key',
  'leap_back',
  'less',
  'less_circle',
  'less_full',
  'location',
  'logout',
  'menu',
  'more_horizontal',
  'next_arrow',
  'offer_off',
  'offer_on',
  'oneway',
  'paperclip',
  'popup_menu_button_icon',
  'quick-reservation',
  'random',
  'refresh',
  'remove_circle',
  'remove_full',
  'remove_input',
  'roundtrip',
  'search',
  'settings',
  'share_android',
  'share_ios',
  'sort',
  'sound_off',
  'tickets_off',
  'tickets_on',
  'to_travel_off',
  'to_travel_on',
  'traffic_cone',
  'valid_full',
  'video',
  'warning_circle',
  'warning_simple',
] as const
export type NavigationAndActionIcon = (typeof ALL_NAVIGATION_AND_ACTION_ICONS)[number]

const isNavigationAndActionIcon = (navigationAndActionKey: string): navigationAndActionKey is NavigationAndActionIcon =>
  ALL_NAVIGATION_AND_ACTION_ICONS.includes(navigationAndActionKey as NavigationAndActionIcon)

const ICONS: LazyIconsGroup<NavigationAndActionIcon> = {
  multiply: Multiply,
  pdf: Pdf,
  account_off: AccountOff,
  account_on: AccountOn,
  add: Add,
  'add-a-geolocation': AddAGeolocation,
  'add-a-journey': AddAJourney,
  'add-a-luggage': AddALuggage,
  add_circle: AddCircle,
  add_full: AddFull,
  alert: Alert,
  alert_notification: AlertNotification,
  arrow_back_to_top: ArrowBackToTop,
  arrow_circle_down: ArrowCircleDown,
  arrow_circle_left: ArrowCircleLeft,
  arrow_circle_right: ArrowCircleRight,
  arrow_circle_up: ArrowCircleUp,
  arrow_down: ArrowDown,
  arrow_left: ArrowLeft,
  arrow_right: ArrowRight,
  arrow_up: ArrowUp,
  back: Back,
  back_arrow: BackArrow,
  bin: Bin,
  camera: Camera,
  check: Check,
  checkbox: Checkbox,
  clock: Clock,
  clock_full: ClockFull,
  close_circle: CloseCircle,
  document: Document,
  double_arrow_left: DoubleArrowLeft,
  double_arrow_right: DoubleArrowRight,
  edit: Edit,
  error: Error,
  exchange: Exchange,
  export: Export,
  external_link: ExternalLink,
  filter: Filter,
  grid: Grid,
  help: Help,
  home: Home,
  home_add: HomeAdd,
  images: Images,
  import: Import,
  info: Info,
  key: Key,
  leap_back: LeapBack,
  less: Less,
  less_circle: LessCircle,
  less_full: LessFull,
  location: Location,
  logout: Logout,
  menu: Menu,
  more_horizontal: MoreHorizontal,
  next_arrow: NextArrow,
  offer_off: OfferOff,
  offer_on: OfferOn,
  oneway: Oneway,
  paperclip: Paperclip,
  popup_menu_button_icon: PopupMenuButtonIcon,
  'quick-reservation': QuickReservation,
  random: Random,
  refresh: Refresh,
  remove_circle: RemoveCircle,
  remove_full: RemoveFull,
  remove_input: RemoveInput,
  roundtrip: Roundtrip,
  search: Search,
  settings: Settings,
  share_android: ShareAndroid,
  share_ios: ShareIos,
  sort: Sort,
  sound_off: SoundOff,
  tickets_off: TicketsOff,
  tickets_on: TicketsOn,
  to_travel_off: ToTravelOff,
  to_travel_on: ToTravelOn,
  traffic_cone: TrafficCone,
  valid_full: ValidFull,
  video: Video,
  warning_circle: WarningCircle,
  warning_simple: WarningSimple,
}

export const getNavigationAndActionIcon = (navigationAndActionKey: string | undefined): LazyIcon | undefined =>
  navigationAndActionKey && isNavigationAndActionIcon(navigationAndActionKey)
    ? ICONS[navigationAndActionKey]
    : undefined

export const getNavigationAndActionIcons = (): LazyIconsGroup<NavigationAndActionIcon> => ICONS
