import { DEFAULT_LOCALE } from '@Constants/locale'
import Locale from '@Types/locale'
import Market from '@Types/market'
import { parseLocale } from '@Utils/i18n'

export const formatToClientMarket = (locale: Locale = DEFAULT_LOCALE): Market => {
  const { lang, country } = parseLocale(locale)

  return `${lang}_${(country === 'en' ? 'ww' : country).toUpperCase()}` as Market
}
