import { FC, memo, useState } from 'react'
import { differenceInCalendarDays, set } from 'date-fns'

import Calendar, { CalendarProps } from './_internal/Calendar'
import { DEFAULT_TIME } from './DatePicker.constants'
import { Target } from './DatePicker.types'
import * as styles from './styles'

export type DatePickerProps = Omit<CalendarProps, 'currentTarget' | 'onDaySelect' | 'mode' | 'startDate'> &
  Partial<Pick<CalendarProps, 'mode' | 'startDate'>> & {
    initTarget?: Target
    onChange: (startDate: Date, endDate?: Date) => void
  } & Partial<Pick<CalendarProps, 'scrollElementRef'>>

const DatePicker: FC<DatePickerProps> = ({
  endDate,
  initTarget = 'INIT',
  mode = 'ROUND_TRIP',
  onChange,
  scrollElementRef,
  startDate,
  ...rest
}) => {
  const [currentTarget, setCurrentTarget] = useState(initTarget)

  const getTarget = (day: Date): Target => {
    let target: Target = 'START'

    if (!startDate || mode === 'OUTWARD' || mode === 'SINGLE_START_DATE') {
      target = 'START'
    } else if (mode === 'INWARD' || mode === 'SINGLE_END_DATE') {
      target = 'END'
    } else if (mode === 'ROUND_TRIP') {
      target =
        differenceInCalendarDays(day, startDate) < 0 || currentTarget === 'INIT' || currentTarget === 'END'
          ? 'START'
          : 'END'
    }
    setCurrentTarget(target)

    return target
  }

  const setFixedHour = (day: Date): Date => set(day, { hours: DEFAULT_TIME, minutes: 0, seconds: 0, milliseconds: 0 })

  const onDaySelect = (day: Date) => {
    const target = getTarget(day)
    let newStartDate
    let newEndDate

    if (!startDate || target === 'START') {
      newStartDate = day
      newEndDate = mode === 'ROUND_TRIP' ? undefined : endDate
    } else {
      newStartDate = startDate
      newEndDate = day
    }

    onChange(setFixedHour(newStartDate), newEndDate ? setFixedHour(newEndDate) : undefined)
  }

  return (
    <div css={styles.root} aria-hidden>
      <Calendar
        scrollElementRef={scrollElementRef}
        currentTarget={currentTarget}
        endDate={endDate && setFixedHour(endDate)}
        mode={mode}
        onDaySelect={onDaySelect}
        startDate={startDate ? setFixedHour(startDate) : undefined}
        {...rest}
      />
    </div>
  )
}

export default memo<DatePickerProps>(DatePicker)
