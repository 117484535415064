import { css, SerializedStyles } from '@emotion/react'

import { highContrast } from '@Styles/a11y'

type FillColor = 'CurrentColor' | 'Highlight'

export const isWhcm = (fillColor: FillColor = 'CurrentColor'): SerializedStyles =>
  css({
    ...highContrast({
      fill: fillColor,
    }),
  })
