import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

import { highContrast } from '@Styles/a11y'

export const overridesSwitch = (theme: Theme): ComponentsOverrides['MuiSwitch'] => ({
  root: highContrast({
    backgroundColor: 'Highlight',
  }),
  switchBase: {
    color: theme.palette.text.secondary,
  },
})
