import { ComponentType } from 'react'
import { useIntl } from 'react-intl'

import { SeverityType } from '@DS/types/severity'
import { ReactComponent as AlertIcon } from '@Svg/mea/alert.svg'
import { ReactComponent as DelayIcon } from '@Svg/mea/delay.svg'
import { ReactComponent as InfoIcon } from '@Svg/mea/info_message.svg'
import { ReactComponent as InsuranceIcon } from '@Svg/mea/insurance.svg'
import { ReactComponent as PositiveIcon } from '@Svg/mea/positive.svg'
import { ReactComponent as RoadworksIcon } from '@Svg/mea/roadworks.svg'
import { ReactComponent as TrainDeleteIcon } from '@Svg/mea/train_delete.svg'
import { ReactComponent as WarningIcon } from '@Svg/mea/warning.svg'
import { ReactComponent as WarningRedIcon } from '@Svg/mea/warning_red.svg'
import { TranslationMessagesKeys } from '@Types/lang'

type UseSeverityIconReturn = {
  icon: ComponentType | undefined
  ariaLabel: string | undefined
}

// TODO IVTS-30067: Remove côté back les info et info_action, et alléger ce Record.
const ICONS: Record<SeverityType, ComponentType> = {
  alert: AlertIcon,
  delay: DelayIcon,
  info: InfoIcon,
  info_message: InfoIcon,
  info_action: InfoIcon,
  insurance: InsuranceIcon,
  positive: PositiveIcon,
  roadworks: RoadworksIcon,
  service: InfoIcon,
  train_delete: TrainDeleteIcon,
  warning: WarningIcon,
  warning_red: WarningRedIcon,
}

// TODO IVTS-30067: Remove côté back les info et info_action, et alléger ce Record.
const ARIA_LABELS: Record<SeverityType, keyof TranslationMessagesKeys> = {
  alert: 'accessibility_meaIcon_alert',
  delay: 'accessibility_meaIcon_delay',
  info: 'accessibility_meaIcon_info',
  info_message: 'accessibility_meaIcon_info',
  info_action: 'accessibility_meaIcon_info',
  insurance: 'accessibility_meaIcon_insurance',
  positive: 'accessibility_meaIcon_positive',
  roadworks: 'accessibility_meaIcon_roadworks',
  service: 'accessibility_meaIcon_info',
  train_delete: 'accessibility_meaIcon_train_delete',
  warning: 'accessibility_meaIcon_warning',
  warning_red: 'accessibility_meaIcon_warning',
}

export const useSeverityIcon = (severity?: SeverityType): UseSeverityIconReturn => {
  const { formatMessage } = useIntl()

  const isExistingSeverity = !!(severity && ARIA_LABELS[severity])

  if (!isExistingSeverity) {
    return {
      icon: ICONS.info_message,
      ariaLabel: formatMessage({ id: ARIA_LABELS.info_message }),
    }
  }

  return {
    icon: ICONS[severity],
    ariaLabel: formatMessage({ id: ARIA_LABELS[severity] }),
  }
}
