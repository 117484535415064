import { useCallback } from 'react'

import canUseDom from '@Utils/can-use-dom'

type UseSessionStorageReturn<ValueType> = {
  set: (value: ValueType) => void
  get: () => ValueType | undefined
  remove: VoidFunction
}

export const getFromSessionStorage = <ValueType>(key: string): ValueType | undefined => {
  if (canUseDom) {
    const value = sessionStorage.getItem(key)

    if (
      value &&
      (/^"/.test(value) ||
        /^{"/.test(value) ||
        /^\[{"/.test(value) ||
        /^true|false/.test(value) ||
        /^[-]?[0-9]*[.]?[0-9]+/.test(value))
    ) {
      return JSON.parse(value)
    }

    return (value as ValueType) || undefined
  }

  return undefined
}

export const setToSessionStorage = <ValueType>(key: string, value: ValueType): void => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

const useSessionStorage = <ValueType>(key: string): UseSessionStorageReturn<ValueType> => {
  const set = useCallback((value: ValueType) => setToSessionStorage(key, value), [key])

  const remove = useCallback(() => {
    sessionStorage.removeItem(key)
  }, [key])

  const get = useCallback(() => getFromSessionStorage<ValueType>(key), [key])

  return { remove, set, get } as UseSessionStorageReturn<ValueType>
}

export { useSessionStorage }
