import { bottomNavigationActionClasses } from '@mui/material/BottomNavigationAction'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { ellipsis } from 'polished'

export const overridesBottomNavigation = (theme: Theme): ComponentsOverrides['MuiBottomNavigation'] => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
})

export const overridesBottomNavigationAction = (theme: Theme): ComponentsOverrides['MuiBottomNavigationAction'] => ({
  root: {
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
    [`&.${bottomNavigationActionClasses.selected}`]: {
      color: theme.palette.primary.dark,
      fill: theme.palette.primary.dark,
    },
  },
  label: {
    ...ellipsis(),
  },
})
