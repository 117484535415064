import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getHours, parseISO, setHours } from 'date-fns'

import { MIN_DISPLAYED_TIME } from '@DS/components/components/datePicker/DatePicker.constants'
import { Target } from '@DS/components/components/datePicker/DatePicker.types'
import DateSearch, { DateSearchInputProps } from '@DS/components/navigation/search/dateSearch/DateSearch'
import { useModal } from '@Hooks/useModal'

import { LongDistanceDatePickerFormData } from './LongDistanceDatePicker.types'
import LongDistanceDatePickerWrapper, { LongDistanceDatePickerWrapperProps } from './LongDistanceDatePickerWrapper'

export type LongDistanceDatePickerProps = Omit<
  LongDistanceDatePickerWrapperProps,
  'modalProps' | 'onSubmit' | 'isOutwardDateVisible'
> &
  Required<Pick<LongDistanceDatePickerWrapperProps, 'initStartDate'>> & {
    isDisabled?: DateSearchInputProps['isDisabled']
    isSearchCriteriaWrapped?: DateSearchInputProps['isWrapped']
    autoFocus?: DateSearchInputProps['autoFocus']
    onClear?: DateSearchInputProps['onClear']
    onDateChange: (outwardDate: Date, inwardDate?: Date) => void
    id?: string
  }

const LongDistanceDatePicker: FC<LongDistanceDatePickerProps> = ({
  autoFocus = false,
  datePickerOverrideTheme,
  isDisabled = false,
  initEndDate,
  initStartDate,
  isSearchCriteriaWrapped = false,
  maxDate,
  mode = 'ROUND_TRIP',
  onClear,
  onDateChange,
  restrictedDatesList,
  isInModal,
  ...rest
}) => {
  const { openModal, modalProps } = useModal()

  const hasTimeHidden = mode === 'SINGLE_START_DATE' || mode === 'SINGLE_END_DATE'
  const isInwardDateClearable = (mode === 'ROUND_TRIP' || mode === 'SINGLE_END_DATE') && !initEndDate?.isDateMandatory

  const startDateHours = getHours(initStartDate.date)
  const outwardDate = setHours(initStartDate.date, Math.max(startDateHours - (startDateHours % 2), MIN_DISPLAYED_TIME))
  const inwardDate = initEndDate?.date
  const [initTarget, setInitTarget] = useState<Target>()

  const LONG_DISTANCE_DATE_PICKER_DESCRIBER_ID = 'longDistanceDatePickerDescriber'

  const onSubmit = (formData: LongDistanceDatePickerFormData) => {
    const { startDate, endDate, startDateTime, endDateTime } = formData
    const todayTime = getHours(new Date())
    const oDate = startDateTime
      ? setHours(parseISO(startDate), +startDateTime)
      : setHours(parseISO(startDate), +todayTime)
    const iDate = endDate ? setHours(parseISO(endDate), +endDateTime) : undefined

    onDateChange(oDate, iDate)
    modalProps.onClose()
  }

  return (
    <>
      <span id={LONG_DISTANCE_DATE_PICKER_DESCRIBER_ID} hidden>
        <FormattedMessage id="datepicker_openButtonDescriber" />
      </span>
      <DateSearch
        {...rest}
        inwardDateInputField={{
          autoFocus: (!isDisabled && mode === 'INWARD' && autoFocus) || undefined,
          isDisabled: mode === 'OUTWARD' || isDisabled,
          isVisible: mode !== 'SINGLE_START_DATE' && mode !== 'OUTWARD_ONLY',
          buttonLabelId: initEndDate?.buttonLabelId,
          value: inwardDate ? inwardDate.toISOString() : '',
          ...(isInwardDateClearable && {
            onClear: () => {
              onDateChange(outwardDate, undefined)
              onClear?.()
            },
          }),
          onClick: () => {
            setInitTarget('START')
            openModal()
          },
          'aria-describedby': LONG_DISTANCE_DATE_PICKER_DESCRIBER_ID,
          isWrapped: isSearchCriteriaWrapped,
        }}
        outwardDateInputField={{
          autoFocus: (!isDisabled && mode !== 'INWARD' && autoFocus) || undefined,
          isDisabled: mode === 'INWARD' || isDisabled,
          isVisible: mode !== 'SINGLE_END_DATE',
          buttonLabelId: initStartDate.buttonLabelId,
          value: outwardDate.toISOString(),
          onClick: () => {
            setInitTarget('INIT')
            openModal()
          },
          'aria-describedby': LONG_DISTANCE_DATE_PICKER_DESCRIBER_ID,
        }}
        isOutwardOnly={mode === 'OUTWARD_ONLY'}
        hasTimeHidden={hasTimeHidden}
        isCompactedOnMobile={mode !== 'OUTWARD_ONLY'}
      />
      <LongDistanceDatePickerWrapper
        initEndDate={initEndDate}
        initStartDate={{ ...initStartDate, date: outwardDate }}
        initTarget={initTarget}
        maxDate={maxDate}
        mode={mode}
        restrictedDatesList={restrictedDatesList}
        modalProps={modalProps}
        onSubmit={onSubmit}
        isOutwardDateVisible={mode !== 'SINGLE_END_DATE'}
        isInwardDateVisible={mode !== 'SINGLE_START_DATE' && mode !== 'OUTWARD_ONLY'}
        hasTimeHidden={hasTimeHidden}
        datePickerOverrideTheme={datePickerOverrideTheme}
        isInModal={isInModal}
      />
    </>
  )
}

export default LongDistanceDatePicker
