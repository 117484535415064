import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

import { SPACING_2, SPACING_4 } from '@Styles/constants/spacing'

export const overridesSnackbar = (theme: Theme): ComponentsOverrides['MuiSnackbar'] => ({
  root: {
    color: theme.palette.text.primary,
    marginBottom: SPACING_4,
    [theme.breakpoints.down('sm')]: {
      left: SPACING_2,
      right: SPACING_2,
    },
  },
})
