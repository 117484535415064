import { createRef, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { InputBaseProps } from '@mui/material'
import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { DEFAULT_DEBOUNCE_DELAY } from '@Constants/debounce'
import {
  AutocompleteBaseProps,
  AutocompleteItemModel,
  AutocompleteSection,
  AutocompleteUserEventType,
} from '@DS/components/navigation/search/autocomplete/Autocomplete.types'
import useUuid from '@Hooks/useUuid'

type TargetMode = 'suggestions' | 'results'
type DisplayMode = TargetMode | undefined

export type AutocompleteInputProps = {
  onBlur: (event?: { relatedTarget: EventTarget | null }) => void
  onChange: (event?: { currentTarget: EventTarget & (HTMLInputElement | HTMLTextAreaElement) }) => void
  onFocus: VoidFunction
  onKeyDown: (event?: { key: string; preventDefault(): void }) => void
} & Omit<InputBaseProps, 'onFocus' | 'onBlur' | 'onChange' | 'onKeyDown'> & { value: string; defaultValue?: string }

// Propriétés qui ne peuvent pas être passées en entrée dans les input props en option de ce hook car elles sont
// calculées ou fixées en dur et ne seront pas re-transmises dans les input props de retour de ce hook
type ForbiddenOptionsInputProps = Pick<AutocompleteInputProps, 'autoComplete' | 'value'> & {
  inputProps: Pick<
    Required<AutocompleteInputProps>['inputProps'],
    'aria-activedescendant' | 'aria-autocomplete' | 'aria-expanded' | 'aria-haspopup' | 'aria-owns' | 'role'
  >
}

// Propriétés qui ne peuvent pas être passées en entrée dans les autocomplete props en option de ce hook car elles sont
// calculées ou fixées en dur et ne seront pas re-transmises dans les autocomplete props de retour de ce hook
type ForbiddenOptionsAutocompleteProps = Pick<AutocompleteBaseProps, 'autocompleteRef' | 'itemRef' | 'shouldOpen'>

type AutocompleteHelpers = {
  clearInputValue: VoidFunction
  setInputValue: Dispatch<SetStateAction<string>>
}

type AutocompleteState = {
  displayMode: DisplayMode
  hasNoResults: boolean
  isFetching: boolean
}

export type Autocomplete<AutocompleteItemModelType extends AutocompleteItemModel> = [
  inputProps: AutocompleteInputProps,
  autocompleteProps: AutocompleteBaseProps<AutocompleteItemModelType>,
  helpers: AutocompleteHelpers,
  state: AutocompleteState
]

export type AutocompleteOptions<AutocompleteItemModelType extends AutocompleteItemModel> = {
  autocompleteProps?: Omit<
    Partial<AutocompleteBaseProps<AutocompleteItemModelType>>,
    keyof ForbiddenOptionsAutocompleteProps
  >
  debounceDelay?: number
  hasToShowEmptySections?: boolean
  hasToAutoHighlight?: boolean
  hasToRestoreSelectedOnBlur?: boolean
  hasToValidateOnTab?: boolean
  inputProps?: Omit<Partial<AutocompleteInputProps>, keyof ForbiddenOptionsInputProps> & {
    inputProps?: Omit<
      Partial<Required<AutocompleteInputProps>['inputProps']>,
      keyof ForbiddenOptionsInputProps['inputProps']
    >
  }
  minInputLengthOpen?: number
  onError?: (error: unknown) => void
}

const filterSections = <AutocompleteItemModelType extends AutocompleteItemModel>(
  sections: AutocompleteSection<AutocompleteItemModelType>[]
): AutocompleteSection<AutocompleteItemModelType>[] => sections.filter(({ items }) => items.length)

const defaultEmptyHandler = () => undefined
const defaultEmptySuggestions: unknown[] = []
const defaultEmptyObject: unknown = {}

export const DEBOUNCE_DELAY = DEFAULT_DEBOUNCE_DELAY
export const DEFAULT_MIN_INPUT_LENGTH_OPEN = 2

type GetAutocompleteItemsHookOptionsModel<
  AutocompleteItemModelType extends AutocompleteItemModel,
  GetAutocompleteItemsHookQueryFnData,
  GetAutocompleteItemsHookQueryKey extends readonly unknown[]
> = UseQueryOptions<
  GetAutocompleteItemsHookQueryFnData,
  unknown,
  AutocompleteSection<AutocompleteItemModelType>[],
  GetAutocompleteItemsHookQueryKey
>

type GetAutocompleteItemsHook<
  AutocompleteItemModelType extends AutocompleteItemModel,
  GetAutocompleteItemsHookQueryFnData,
  GetAutocompleteItemsHookQueryKey extends readonly unknown[]
> = (
  inputValue: string,
  options?: GetAutocompleteItemsHookOptionsModel<
    AutocompleteItemModelType,
    GetAutocompleteItemsHookQueryFnData,
    GetAutocompleteItemsHookQueryKey
  >
) => UseQueryResult<AutocompleteSection<AutocompleteItemModelType>[]>

/**
 * We use [Function Overloads](https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads) here to
 * specify multiple signatures for the function
 *
 * 1st overload signature
 *
 * @param {GetAutocompleteItemsHook} useCustomAutocompleteItems - Custom hook to get remote items
 * @param {AutocompleteOptions} [options] - Options for hook behavior
 * @return {Autocomplete} - Returns Input and Autocomplete props + helpers + hook state
 */
export function useAutocomplete<
  AutocompleteItemModelType extends AutocompleteItemModel,
  GetAutocompleteItemsHookQueryFnData,
  GetAutocompleteItemsHookQueryKey extends readonly unknown[]
>(
  useCustomAutocompleteItems: GetAutocompleteItemsHook<
    AutocompleteItemModelType,
    GetAutocompleteItemsHookQueryFnData,
    GetAutocompleteItemsHookQueryKey
  >,
  options?: AutocompleteOptions<AutocompleteItemModelType>
): Autocomplete<AutocompleteItemModelType>

/**
 * 2nd overload signature
 *
 * @param {GetAutocompleteItemsHook} useCustomAutocompleteItems - Custom hook to get remote items
 * @param {AutocompleteSection[]} suggestions - Items to show when user input length has not reached `minInputLength` option yet
 * @param {AutocompleteOptions} [options] - Options for hook behavior
 * @return {Autocomplete} - Returns Input and Autocomplete props + helpers + hook state
 */
export function useAutocomplete<
  AutocompleteItemModelType extends AutocompleteItemModel,
  GetAutocompleteItemsHookQueryFnData,
  GetAutocompleteItemsHookQueryKey extends readonly unknown[]
>(
  useCustomAutocompleteItems: GetAutocompleteItemsHook<
    AutocompleteItemModelType,
    GetAutocompleteItemsHookQueryFnData,
    GetAutocompleteItemsHookQueryKey
  >,
  suggestions: AutocompleteSection<AutocompleteItemModelType>[],
  options?: AutocompleteOptions<AutocompleteItemModelType>
): Autocomplete<AutocompleteItemModelType>

/**
 * Implementation signature - Flatten signature compatible with overload signatures
 *
 * @param {GetAutocompleteItemsHook} useCustomAutocompleteItems - Custom hook to get remote items
 * @param {AutocompleteSection[] | AutocompleteOptions} [suggestionsOrOptions] - Items to show when user input length has not reached `minInputLength` option yet **or** Options for hook behavior
 * @param {AutocompleteOptions} [optionsOrUndefined] - Options for hook behavior
 * @return {Autocomplete} - Returns Input and Autocomplete props + helpers + hook state
 */
export function useAutocomplete<
  AutocompleteItemModelType extends AutocompleteItemModel,
  GetAutocompleteItemsHookQueryFnData,
  GetAutocompleteItemsHookQueryKey extends readonly unknown[]
>(
  useCustomAutocompleteItems: GetAutocompleteItemsHook<
    AutocompleteItemModelType,
    GetAutocompleteItemsHookQueryFnData,
    GetAutocompleteItemsHookQueryKey
  >,
  suggestionsOrOptions?:
    | AutocompleteSection<AutocompleteItemModelType>[]
    | AutocompleteOptions<AutocompleteItemModelType>
    | undefined,
  optionsOrUndefined?: AutocompleteOptions<AutocompleteItemModelType>
): Autocomplete<AutocompleteItemModelType> {
  const uuid = useUuid()

  /**
   * We guess if we have suggestions passed depending on what overload signature has been used
   */
  const definedSuggestions = useMemo(
    () =>
      Array.isArray(suggestionsOrOptions)
        ? suggestionsOrOptions
        : (defaultEmptySuggestions as AutocompleteSection<AutocompleteItemModelType>[]),
    [suggestionsOrOptions]
  )
  /**
   * We define if we have options passed depending on what overload signature has been used
   */
  const options = useMemo(
    () => (suggestionsOrOptions && !Array.isArray(suggestionsOrOptions) ? suggestionsOrOptions : optionsOrUndefined),
    [optionsOrUndefined, suggestionsOrOptions]
  )

  /**
   * Destructuring options to set on the fly default values
   */
  const {
    autocompleteProps: {
      id,
      onFocus: onFocusAutocompleteProps = defaultEmptyHandler,
      onBlur: onBlurAutocompleteProps = defaultEmptyHandler,
      onSelectItem: onSelectItemAutoCompleteProps = defaultEmptyHandler,
      ...restAutocompleteProps
    } = {},
    debounceDelay = DEBOUNCE_DELAY,
    hasToShowEmptySections = false,
    inputProps: {
      defaultValue = '',
      inputRef: inputRefInputProps,
      onBlur: onBlurInputProps = defaultEmptyHandler,
      onChange: onChangeInputProps = defaultEmptyHandler,
      onFocus: onFocusInputProps = defaultEmptyHandler,
      onKeyDown: onKeyDownInputProps = defaultEmptyHandler,
      ...restInputProps
    } = {},
    minInputLengthOpen = DEFAULT_MIN_INPUT_LENGTH_OPEN,
    onError: onErrorProps = defaultEmptyHandler,
    hasToAutoHighlight = false,
    hasToRestoreSelectedOnBlur = false,
    hasToValidateOnTab = false,
  } = options || (defaultEmptyObject as AutocompleteOptions<AutocompleteItemModelType>)

  const suggestions = useMemo(
    () => (hasToShowEmptySections ? definedSuggestions : filterSections(definedSuggestions)),
    [definedSuggestions, hasToShowEmptySections]
  )

  const [inputValue, setInputValue] = useState(defaultValue)
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue)
  const [shouldOpen, setShouldOpen] = useState(false)
  const [highlighted, setHighlighted] = useState<AutocompleteItemModelType>()
  const autocompleteRef = useRef<HTMLDivElement>(null)
  const highlightedItemRef = useRef<HTMLDivElement>(null)
  const [sections, setSections] = useState<AutocompleteSection<AutocompleteItemModelType>[]>([])
  const selectedText = useRef(defaultValue)
  const inputRef = inputRefInputProps || createRef()
  const wasError = useRef(false)
  const [userEventType, setUserEventType] = useState<AutocompleteUserEventType>()

  const onError = useCallback(
    (error: unknown) => {
      wasError.current = true
      onErrorProps(error)
    },
    [onErrorProps]
  )

  const [, cancelDebouncedInputValue] = useDebounce(
    () => {
      wasError.current = false
      setDebouncedInputValue(inputValue)
    },
    debounceDelay,
    [inputValue, debounceDelay]
  )

  const clearInputValue = useCallback(() => {
    setInputValue('')
    selectedText.current = ''
  }, [])

  const flattenItems = useMemo<AutocompleteItemModelType[]>(() => {
    const newFlattenItems = sections.reduce<AutocompleteItemModelType[]>(
      (items, section) => [...items, ...section.items],
      []
    )

    return newFlattenItems
  }, [sections])

  const flattenItemsHighlightedIndex = useMemo<number>(
    () => flattenItems.findIndex((item) => item === highlighted),
    [flattenItems, highlighted]
  )

  const incrementHighlighted = useCallback(
    (increment: number) => {
      const targetIndex = flattenItemsHighlightedIndex + increment

      setHighlighted(flattenItems[targetIndex === flattenItems.length ? 0 : targetIndex])
    },
    [flattenItems, flattenItemsHighlightedIndex]
  )

  const decrementHighlighted = useCallback(
    (decrement: number) => {
      const targetIndex = flattenItemsHighlightedIndex - decrement

      setHighlighted(flattenItems[targetIndex < 0 ? flattenItems.length - 1 : targetIndex])
    },
    [flattenItems, flattenItemsHighlightedIndex]
  )

  const select = useCallback(
    (item: AutocompleteItemModelType) => {
      selectedText.current = item.label
      setInputValue(item.label)

      if ('current' in inputRef) {
        inputRef.current?.focus()
      }
      onSelectItemAutoCompleteProps(item)
      setShouldOpen(false)
    },
    [inputRef, onSelectItemAutoCompleteProps]
  )

  const onInputChange = useCallback<AutocompleteInputProps['onChange']>(
    (event) => {
      const newValue = event?.currentTarget.value

      if (newValue !== undefined) {
        cancelDebouncedInputValue()
        setInputValue(newValue)
      }
      setShouldOpen(true)
      onChangeInputProps(event)
    },
    [cancelDebouncedInputValue, onChangeInputProps]
  )

  const onInputKeyDown = useCallback<AutocompleteInputProps['onKeyDown']>(
    (event) => {
      const { key } = event || {}

      if (key && event) {
        setUserEventType('KEY_PRESSED')

        const handleKeyboardAction = () => {
          if (['ArrowUp', 'PageUp'].includes(key)) {
            event.preventDefault()
            decrementHighlighted(key === 'ArrowUp' ? 1 : 10)

            return
          }

          if (['ArrowDown', 'PageDown'].includes(key)) {
            event.preventDefault()
            incrementHighlighted(key === 'ArrowDown' ? 1 : 10)

            return
          }

          if ((key === 'Enter' || (hasToValidateOnTab && key === 'Tab')) && highlighted) {
            if (key !== 'Tab') {
              event.preventDefault()
            }
            select(highlighted)

            return
          }

          if (key === 'Escape') {
            event.preventDefault()

            if (highlighted) {
              setHighlighted(undefined)

              return
            }
            setShouldOpen(false)
          }
        }

        if (shouldOpen) {
          handleKeyboardAction()
        } else if (['ArrowUp', 'PageUp', 'ArrowDown', 'PageDown'].includes(key)) {
          setShouldOpen(true)
        } else if (key === 'Escape') {
          setInputValue('')
        }
      }

      onKeyDownInputProps(event)
    },
    [
      shouldOpen,
      onKeyDownInputProps,
      hasToValidateOnTab,
      highlighted,
      decrementHighlighted,
      incrementHighlighted,
      select,
    ]
  )

  const onInputFocus = useCallback<AutocompleteInputProps['onFocus']>(() => {
    setShouldOpen(true)

    onFocusInputProps()
  }, [onFocusInputProps])

  const hasFocusTransferredToAutocomplete = useCallback(
    (target: EventTarget | null | undefined) => target && autocompleteRef.current?.contains(target as Node),
    []
  )

  const onInputBlur = useCallback<AutocompleteInputProps['onBlur']>(
    (event) => {
      const { relatedTarget } = event || {}

      if (!hasFocusTransferredToAutocomplete(relatedTarget)) {
        if (hasToRestoreSelectedOnBlur) {
          setInputValue(selectedText.current)
        }
        setShouldOpen(false)
      }

      onBlurInputProps(event)
    },
    [hasFocusTransferredToAutocomplete, onBlurInputProps, hasToRestoreSelectedOnBlur]
  )

  const onAutocompleteFocus = useCallback<Required<AutocompleteBaseProps<AutocompleteItemModelType>>['onFocus']>(
    (event) => {
      setShouldOpen(true)

      onFocusAutocompleteProps(event)
    },
    [onFocusAutocompleteProps]
  )

  const hasFocusTransferredToInput = useCallback(
    (target: EventTarget | null | undefined) =>
      target && 'current' in inputRef && inputRef.current?.contains(target as Node),
    [inputRef]
  )

  const onAutocompleteBlur = useCallback<Required<AutocompleteBaseProps<AutocompleteItemModelType>>['onBlur']>(
    (event) => {
      const { relatedTarget } = event

      if (!hasFocusTransferredToInput(relatedTarget) && !hasFocusTransferredToAutocomplete(relatedTarget)) {
        if (hasToRestoreSelectedOnBlur) {
          setInputValue(selectedText.current)
        }
        setShouldOpen(false)
      }

      onBlurAutocompleteProps(event)
    },
    [hasFocusTransferredToAutocomplete, hasFocusTransferredToInput, hasToRestoreSelectedOnBlur, onBlurAutocompleteProps]
  )

  const onAutocompleteSelectItem = useCallback<(item: AutocompleteItemModelType) => void>(
    (item) => {
      select(item)
    },
    [select]
  )

  const onAutocompleteMouseMove = () => {
    setUserEventType('MOUSE_MOVED')
  }

  const onAutocompleteItemFocus = useCallback<(item: AutocompleteItemModelType) => void>(
    (item) => {
      setHighlighted(item)
    },
    [setHighlighted]
  )

  const onAutocompleteItemHover = useCallback<(item: AutocompleteItemModelType) => void>(
    (item) => {
      if (userEventType === 'MOUSE_MOVED') {
        setHighlighted(item)
      }
    },
    [userEventType, setHighlighted]
  )

  /**
   * Computed mode we want to have depending on user input and `minInputLengthOpen` option
   */
  const targetMode = useMemo<DisplayMode>(() => {
    if (shouldOpen) {
      if (inputValue.length < minInputLengthOpen) {
        return 'suggestions'
      }

      if (debouncedInputValue.length >= minInputLengthOpen) {
        return 'results'
      }
    }

    return undefined
  }, [shouldOpen, inputValue.length, debouncedInputValue.length, minInputLengthOpen])

  const { data: remoteSections, isFetching } = useCustomAutocompleteItems(debouncedInputValue, {
    // We only want to call remote service if conditions are met (depending on computed value `displayMode`)
    enabled: targetMode === 'results' && !wasError.current,
    onError,
  })

  /**
   * Computed type of data returned in `sections` property
   */
  const displayMode = useMemo<DisplayMode>(
    // Equals targetMode with the difference when we switch from suggestions to autocomplete results, waiting for debounce and async operation
    () => (targetMode !== 'suggestions' && sections === suggestions ? 'suggestions' : targetMode),
    [sections, suggestions, targetMode]
  )

  /**
   * Cancel debounce on unmount
   */
  useEffect(() => cancelDebouncedInputValue, [cancelDebouncedInputValue])

  /**
   * Display remote autocomplete results if conditions are met (depending on computed value `displayMode`) and if we have get data from remote service
   */
  useEffect(() => {
    if (targetMode === 'results' && remoteSections) {
      setSections(hasToShowEmptySections ? remoteSections : filterSections(remoteSections))
    }
  }, [targetMode, remoteSections, hasToShowEmptySections])

  /**
   * Display suggestions if conditions are met (depending on computed value `displayMode`)
   * Cancel pending debounce as it's useless to change `debouncedInputValue` state lately, it will not be used
   */
  useEffect(() => {
    if (targetMode === 'suggestions') {
      setSections(suggestions)
      cancelDebouncedInputValue()
      setDebouncedInputValue('')
    }
  }, [targetMode, cancelDebouncedInputValue, suggestions])

  /**
   * Calc hightlighted by default
   */
  useEffect(() => {
    setHighlighted(flattenItems.length && hasToAutoHighlight ? flattenItems[0] : undefined)
  }, [hasToAutoHighlight, flattenItems])

  /**
   * Auto scroll to highlighted item
   */
  useEffect(() => {
    if (flattenItems.length > 0 && flattenItems[0] === highlighted) {
      if (!autocompleteRef.current?.scrollTo) return

      autocompleteRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      highlightedItemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
    }
  }, [flattenItems, highlighted])

  const autocompleteId = id || `autocomplete-${uuid}`

  return [
    {
      ...restInputProps,
      autoComplete: 'off',
      onBlur: onInputBlur,
      onChange: onInputChange,
      onFocus: onInputFocus,
      onKeyDown: onInputKeyDown,
      value: inputValue,
      inputRef,
      inputProps: {
        ...restInputProps.inputProps,
        ...(shouldOpen && highlighted && highlighted.id && { 'aria-activedescendant': highlighted.id }),
        'aria-autocomplete': 'list',
        'aria-describedby': `autocomplete_input_visuallyHiddenLabel ${
          restInputProps.inputProps?.['aria-describedby'] || ''
        }`,
        'aria-expanded': shouldOpen && flattenItems.length > 0,
        'aria-haspopup': 'listbox',
        'aria-owns': autocompleteId,
        role: 'combobox',
      },
    },
    {
      ...restAutocompleteProps,
      id: autocompleteId,
      onBlur: onAutocompleteBlur,
      onFocus: onAutocompleteFocus,
      onSelectItem: onAutocompleteSelectItem,
      highlighted,
      onMouseMove: onAutocompleteMouseMove,
      onItemHover: onAutocompleteItemHover,
      onItemFocus: onAutocompleteItemFocus,
      autocompleteRef,
      itemRef: highlightedItemRef,
      sections,
      shouldOpen,
    },
    { clearInputValue, setInputValue },
    { displayMode, hasNoResults: sections.length === 0 && displayMode === 'results', isFetching },
  ]
}
