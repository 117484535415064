import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { toolbarClasses } from '@mui/material/Toolbar'
import { rem, size } from 'polished'

import { SPACING_1, SPACING_2, SPACING_3, SPACING_4, SPACING_5 } from '@DS/styles/constants/spacing'
import { typographyOverrides } from '@Material/typography'
import { APPBAR_HEIGHT, APPBAR_MD_HEIGHT } from '@Styles/constants/layout'
import { getFocusVisibleIconButtonStyle } from '@Styles/focus'

export const appBar = (theme: Theme, isInModal = false): SerializedStyles =>
  css({
    height: APPBAR_HEIGHT,
    justifyContent: 'center',
    padding: isInModal ? SPACING_2 : `${SPACING_2} 0`,
    [theme.breakpoints.up('md')]: {
      height: APPBAR_MD_HEIGHT,
    },
  })

export const appBarContainer = (theme: Theme): SerializedStyles =>
  css({
    padding: `0 ${SPACING_2}`,
    [theme.breakpoints.up('md')]: {
      padding: `0 ${SPACING_3}`,
    },
  })

export const appBarProminent = (theme: Theme, isInModal = false): SerializedStyles =>
  css({
    justifyContent: 'center',
    minHeight: APPBAR_HEIGHT,
    padding: isInModal ? SPACING_2 : `${SPACING_2} 0`,
    [theme.breakpoints.up('md')]: {
      minHeight: APPBAR_MD_HEIGHT,
    },
  })

export const toolBar = (theme: Theme): SerializedStyles =>
  css({
    [`&.${toolbarClasses.root}`]: {
      minHeight: 0,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        height: rem(24),
      },
      [theme.breakpoints.up('md')]: {
        height: rem(40),
      },
    },
  })

export const appBarAction = css({
  flexShrink: 0,
  justifyContent: 'end',
  marginRight: `-${SPACING_2}`,
  minWidth: 0,
})

export const children = css({
  marginTop: SPACING_2,
})

export const backButtonWithBookingForm = css({
  marginTop: rem(25.6),
})

export const backButton = (theme: Theme): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleIconButtonStyle(theme.palette.primary.main, rem(-4)),
    '&:hover': {
      backgroundColor: theme.customPalette.invertedColors.invertedColorsSelected,
    },

    alignSelf: 'auto',
    backgroundColor: theme.palette.action.active,
    // Pour modifier la couleur du ripple sur un iconButton il faut définir la couleur
    color: theme.customPalette.onElements.selected,
    marginLeft: 0,
    marginRight: SPACING_2,
    padding: SPACING_1,
    ...size(SPACING_4),
    [theme.breakpoints.up('md')]: {
      ...size(SPACING_5),
    },
  })

export const arrowIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.customPalette.onElements.elevated,
  })

export const content = (theme: Theme, isInModal = false): SerializedStyles =>
  css({
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      ...(!isInModal && (typographyOverrides(theme)?.h2 as CSSProperties)),
    },
  })

export const contentLegacy = css({
  flexGrow: 1,
})
