import { ElementType, FC, ReactNode } from 'react'
import { Typography, TypographyProps } from '@mui/material'
import { hideVisually } from 'polished'

export type VisuallyHiddenLabelProps = TypographyProps & {
  component?: ElementType
  label: ReactNode
}

const VisuallyHiddenLabel: FC<VisuallyHiddenLabelProps> = ({ component = 'span', label, children, ...rest }) => (
  <Typography {...rest} component={component} css={hideVisually()}>
    {label}
    {children}
  </Typography>
)

export default VisuallyHiddenLabel
