import { Context, createContext, useContext } from 'react'

import { ModalControls, ModalNavData } from './Modal.types'

// Props de la modale qui serviront à la ModalPage
export type ModalPrivateContextState = {
  headerActionLabel?: string
  isBackIconDisplayed?: boolean
  onBackIconClick?: VoidFunction
  onClose: VoidFunction
}

const ModalPrivateContext = createContext<ModalPrivateContextState | undefined>(undefined)
const ModalControlsContext = createContext<ModalControls | undefined>(undefined)
const ModalNavDataContext = createContext<ModalNavData<unknown> | undefined>(undefined)

export const ModalProvider = ModalPrivateContext.Provider
export const ModalControlsProvider = ModalControlsContext.Provider
export const ModalNavDataProvider = ModalNavDataContext.Provider

/**
 * To be called only in ModalPage.tsx to get modal private context
 */
export const useModalPageInfo = (): ModalPrivateContextState => {
  const context = useContext(ModalPrivateContext)

  if (context === undefined) {
    throw new Error('useModalPageInfo must only be used in ModalPage.tsx, ModalPage must be used within Modal')
  }

  return context
}

/**
 * To be called in any Modal Page sub component to get methods to control modal (navigation & close)
 */
export const useModalControls = (): ModalControls => {
  const context = useContext(ModalControlsContext)

  if (context === undefined) {
    throw new Error(
      'useModalControls must be used within a multi pages modal, i.e. a Modal with modalControls prop provided'
    )
  }

  return context
}

export const useModalNavDataContext = <ModalNavContextDataType,>(): ModalNavData<ModalNavContextDataType> => {
  const context = useContext<ModalNavData<ModalNavContextDataType> | undefined>(
    ModalNavDataContext as Context<ModalNavData<ModalNavContextDataType> | undefined>
  )

  if (context === undefined) {
    throw new Error('useModalNavDataContext must be used within a ModalNavDataProvider')
  }

  return context
}

export const useModalControlsIfIsInModal = (
  defaultModalControls: ModalControls | Record<string, never> = {}
): ModalControls | Record<string, never> => {
  const context = useContext(ModalControlsContext)

  return context || defaultModalControls
}

export const useIsInModal = (): boolean => {
  const context = useContext(ModalControlsContext)

  return !!context
}
