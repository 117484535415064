import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

import { SPACING_0 } from '@DS/styles/constants/spacing'
import { backgroundUnderColor, colorOnBackground } from '@Utils/colors'

const scaleRatio = 5 / 6

export const generatedTransportIcon = (
  backgroundColor: string | undefined,
  color: string | undefined,
  radius: number,
  isSmallGenerated = false
): SerializedStyles => {
  const finalBackgroundColor = backgroundColor ? `#${backgroundColor}` : backgroundUnderColor(color)
  const finalColor = color ? `#${color}` : colorOnBackground(backgroundColor)

  return css({
    alignItems: 'center',
    backgroundColor: finalBackgroundColor,
    borderRadius: rem(radius),
    color: finalColor,
    display: 'inline-flex',
    flexDirection: 'column',
    height: rem(20),
    justifyContent: 'center',
    margin: rem(2),
    minWidth: rem(20),
    padding: `0 ${SPACING_0}`,
    paddingTop: rem(1),
    whiteSpace: 'nowrap',
    ...(isSmallGenerated && {
      fontSize: rem(14 * scaleRatio),
      height: rem(20 * scaleRatio),
    }),
  })
}

const getIconSize = (isSmall: boolean, isSupplierIcon: boolean) => {
  if (isSupplierIcon) {
    return 34
  }

  if (isSmall) {
    return 16
  }

  return 24
}

export const icon = (theme: Theme, isSmall: boolean, isSupplierIcon: boolean): SerializedStyles =>
  css({
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
    height: rem(getIconSize(isSmall, isSupplierIcon)),
  })
