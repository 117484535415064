import { type AxiosError, type AxiosInstance, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'

import { type AuthenticationSdk } from './authentication'
import { type OutputErrorMessage } from './generated'
import { type AugmentedAxiosRequestConfig } from './interceptors.types'
import { type UserSdk } from './user'

const isAugmentedAxiosRequestConfig = (config: unknown): config is AugmentedAxiosRequestConfig =>
  (config as AugmentedAxiosRequestConfig).ivts !== undefined

const createRefreshTokensIfNeededRequestInterceptor =
  (userSdk: UserSdk, authenticationSdk: AuthenticationSdk) =>
  async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    if (isAugmentedAxiosRequestConfig(config) && config.ivts.shouldRefreshTokens && userSdk.isRefreshTokensNeeded()) {
      await authenticationSdk.refreshTokensFromInterceptor()
    }

    return config
  }

const passThroughError = (error: unknown): Promise<unknown> => Promise.reject(error)

export const resetTokenWhenErrorIsTypeOfInvalidJwt =
  (userSdk: UserSdk) =>
  ({ isAxiosError, response }: AxiosError<OutputErrorMessage>): void => {
    if (isAxiosError && response?.status === 401 && response?.data?.type === '/authentication/invalid-jwt-token') {
      userSdk.setInvalidTokenData()
    }
  }

export const addAxiosRequestInterceptors = (
  axios: AxiosInstance,
  userSdk: UserSdk,
  authenticationSdk: AuthenticationSdk
): void => {
  const refreshTokensIfNeededRequestInterceptor = createRefreshTokensIfNeededRequestInterceptor(
    userSdk,
    authenticationSdk
  )

  axios.interceptors.request.use(refreshTokensIfNeededRequestInterceptor, passThroughError)

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError<OutputErrorMessage>) => {
      resetTokenWhenErrorIsTypeOfInvalidJwt(userSdk)(error)

      throw error
    }
  )
}
