// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const Covid = svgLazyLoader('mea/covid')
const Alert = svgLazyLoader('mea/alert')
const Delay = svgLazyLoader('mea/delay')
const InfoAction = svgLazyLoader('mea/info_action')
const InfoMessage = svgLazyLoader('mea/info_message')
const Insurance = svgLazyLoader('mea/insurance')
const Offline = svgLazyLoader('mea/offline')
const Positive = svgLazyLoader('mea/positive')
const Roadworks = svgLazyLoader('mea/roadworks')
const TrainDelete = svgLazyLoader('mea/train_delete')
const Warning = svgLazyLoader('mea/warning')
const WarningRed = svgLazyLoader('mea/warning_red')
const WorkOnRailway = svgLazyLoader('mea/work_on_railway')
const WorkOnRailwayMea = svgLazyLoader('mea/work_on_railway_mea')

const ALL_MEA_ICONS = [
  'covid',
  'alert',
  'delay',
  'info',
  'info_action',
  'info_message',
  'insurance',
  'offline',
  'positive',
  'roadworks',
  'train_delete',
  'warning',
  'warning_red',
  'work_on_railway',
  'work_on_railway_mea',
] as const
export type MeaIcon = (typeof ALL_MEA_ICONS)[number]

const isMeaIcon = (meaKey: string): meaKey is MeaIcon => ALL_MEA_ICONS.includes(meaKey as MeaIcon)

const ICONS: LazyIconsGroup<MeaIcon> = {
  covid: Covid,
  alert: Alert,
  delay: Delay,
  info: InfoMessage,
  info_action: InfoAction,
  info_message: InfoMessage,
  insurance: Insurance,
  offline: Offline,
  positive: Positive,
  roadworks: Roadworks,
  train_delete: TrainDelete,
  warning: Warning,
  warning_red: WarningRed,
  work_on_railway: WorkOnRailway,
  work_on_railway_mea: WorkOnRailwayMea,
}

export const getMeaIcon = (meaKey: string | undefined): LazyIcon | undefined =>
  meaKey && isMeaIcon(meaKey) ? ICONS[meaKey] : undefined

export const getMeaIcons = (): LazyIconsGroup<MeaIcon> => ICONS
