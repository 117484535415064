import { Theme } from '@mui/material/styles'

import {
  ALLIANZ_BLUE,
  ALLIANZ_ORANGE,
  ALTERNATIVE_COLORS_AGENT,
  ALTERNATIVE_COLORS_AGENT_LIGHT_MODE,
  ARCTIC_BLUE,
  BLACK,
  BLUE_INFO,
  BLUE_INFO_LIGHT_MODE,
  BUSINESS_PREMIERE,
  CART,
  CART_HOVER,
  CARTE_ABONNEMENT,
  CARTE_AVANTAGE,
  CARTE_AVANTAGE_BACKGROUND,
  CARTE_AVANTAGE_LE_CLUB,
  CARTE_AVANTAGE_POINTS_BACKGROUND,
  CARTE_LIBERTE,
  CORNFLOWER_BLUE,
  DARK_GREY,
  ERROR_BACKGROUND,
  ERROR_BACKGROUND_LIGHT_MODE,
  EUROSTAR,
  EXTRA_ICON_1,
  EXTRA_ICON_1_LIGHT_MODE,
  EXTRA_ICON_2,
  EXTRA_ICON_2_LIGHT_MODE,
  EXTRA_ICON_3,
  EXTRA_ICON_3_LIGHT_MODE,
  EXTRA_ICON_4,
  EXTRA_ICON_4_LIGHT_MODE,
  GREEN_ECO,
  GREEN_ECO_LIGHT_MODE,
  GREEN_SUCCESS_LIGHT_MODE,
  GREEN_YELLOW,
  GREY_SELECTED_LIGHT_MODE,
  HOME_PAGE_BACKGROUND,
  HOME_PAGE_BACKGROUND_LIGHT_MODE,
  HOME_PAGE_ILLUSTRATION,
  HOME_PAGE_ILLUSTRATION_LIGHT_MODE,
  IDFM,
  INFO_BACKGROUND,
  INFO_BACKGROUND_LIGHT_MODE,
  INOUI1,
  INOUI2,
  INVERTED_COLORS_ACTION,
  INVERTED_COLORS_ACTION_HOVER,
  INVERTED_COLORS_ACTION_HOVER_LIGHT_MODE,
  INVERTED_COLORS_ACTION_LIGHT_MODE,
  INVERTED_COLORS_BACKGROUND,
  INVERTED_COLORS_BACKGROUND_LIGHT_MODE,
  INVERTED_COLORS_ELEVATED,
  INVERTED_COLORS_ELEVATED_LIGHT_MODE,
  INVERTED_COLORS_ERROR,
  INVERTED_COLORS_ERROR_LIGHT_MODE,
  INVERTED_COLORS_ON_BACKGROUND,
  INVERTED_COLORS_ON_BACKGROUND_LIGHT_MODE,
  INVERTED_COLORS_SELECTED,
  INVERTED_COLORS_SELECTED_LIGHT_MODE,
  INVERTED_COLORS_SUCCESS,
  INVERTED_COLORS_SUCCESS_LIGHT_MODE,
  INVERTED_COLORS_SURFACE,
  INVERTED_COLORS_SURFACE_LIGHT_MODE,
  INVERTED_COLORS_TEXT_SECONDARY,
  INVERTED_COLORS_TEXT_SECONDARY_LIGHT_MODE,
  INVERTED_COLORS_WARNING,
  INVERTED_COLORS_WARNING_LIGHT_MODE,
  INVERTED_CURSOR,
  INVERTED_CURSOR_LIGHT_MODE,
  INVERTED_PLACEHOLDER,
  INVERTED_PLACEHOLDER_LIGHT_MODE,
  IV_ERROR_BACKGROUND,
  IV_ERROR_BACKGROUND_LIGHT_MODE,
  IV_INFO_BACKGROUND,
  IV_INFO_BACKGROUND_LIGHT_MODE,
  IV_NEUTRAL_BACKGROUND,
  IV_NEUTRAL_BACKGROUND_LIGHT_MODE,
  IV_SUCCESS_BACKGROUND,
  IV_SUCCESS_BACKGROUND_LIGHT_MODE,
  IV_WARNING_BACKGROUND,
  IV_WARNING_BACKGROUND_LIGHT_MODE,
  LAVENDER_CHARM,
  LIGHT_BLUE,
  LIGHT_BLUE_LIGHT_MODE,
  LIGHT_GREY_LIGHT_MODE,
  NOBLE_GREY,
  NOTIFICATION,
  NOTIFICATION_LIGHT_MODE,
  ON_ALTERNATIVE_COLOR_1,
  ON_ALTERNATIVE_COLOR_1_LIGHT_MODE,
  ON_ALTERNATIVE_COLOR_2,
  ON_ALTERNATIVE_COLOR_2_LIGHT_MODE,
  ON_ALTERNATIVE_COLOR_3,
  ON_ALTERNATIVE_COLOR_3_LIGHT_MODE,
  ON_ALTERNATIVE_COLOR_4,
  ON_ALTERNATIVE_COLOR_4_LIGHT_MODE,
  ON_COMMMUNICATION,
  ON_COMMUNICATION_LIGHT_MODE,
  ON_EXTRA_ICON_2_LIGHT_MODE,
  ON_INFO,
  ON_INFO_LIGHT_MODE,
  ON_SERVICE,
  ON_SERVICE_LIGHT_MODE,
  ORANGE_WARNING_LIGHT_MODE,
  OUIGO,
  OVERLAY_GREY_LIGHT_MODE,
  PURPLE,
  PURPLE_LIGHT_MODE,
  RIPPLE_GREY,
  SEARCH_BUTTON,
  SEARCH_BUTTON_LIGHT_MODE,
  SLATE_GREY,
  SLATE_GREY_LIGHT_MODE,
  SUCCESS,
  SUCCESS_BACKGROUND,
  SUCCESS_BACKGROUND_LIGHT_MODE,
  SURFACE,
  SURFACE_LIGHT_MODE,
  TER,
  TGVMAX1,
  TGVMAX2,
  URANIAN_BLUE,
  WARNING,
  WARNING_BACKGROUND,
  WARNING_BACKGROUND_LIGHT_MODE,
  WHITE,
  XTRA_DARK_BLUE,
  YELLOW,
  YELLOW_LIGHT_MODE,
} from '@Styles/constants/colors'
import { SKYLINE_GRADIENT } from '@Styles/constants/gradients'

const darkTheme: Partial<Theme> = {
  customPalette: {
    onElements: {
      outline: SLATE_GREY,
      selected: DARK_GREY,
      elevated: XTRA_DARK_BLUE,
      overlay: OVERLAY_GREY_LIGHT_MODE,
      ripple: LIGHT_BLUE_LIGHT_MODE,
    },
    alternative: {
      ecology: GREEN_ECO,
      service: PURPLE,
      info: BLUE_INFO,
      recommandation: YELLOW,
      communication: YELLOW,
      separator: BLACK,
      extraIcon1: EXTRA_ICON_1,
      extraIcon2: EXTRA_ICON_2,
      extraIcon3: EXTRA_ICON_3,
      extraIcon4: EXTRA_ICON_4,
      onExtraIcon2: ON_EXTRA_ICON_2_LIGHT_MODE,
      onAlternativeColor1: ON_ALTERNATIVE_COLOR_1,
      onAlternativeColor2: ON_ALTERNATIVE_COLOR_2,
      onAlternativeColor3: ON_ALTERNATIVE_COLOR_3,
      onAlternativeColor4: ON_ALTERNATIVE_COLOR_4,
      cart: CART,
      notification: NOTIFICATION,
      cartHover: CART_HOVER,
      firstClass: GREEN_YELLOW,
      secondClass: GREEN_YELLOW,
      businessClass: GREEN_YELLOW,
      onService: ON_SERVICE,
      onCommunication: ON_COMMMUNICATION,
      onInfo: ON_INFO,
      warningBackground: WARNING_BACKGROUND,
      successBackground: SUCCESS_BACKGROUND,
      infoBackground: INFO_BACKGROUND,
      errorBackground: ERROR_BACKGROUND,
      ivWarningBackground: IV_WARNING_BACKGROUND,
      ivSuccessBackground: IV_SUCCESS_BACKGROUND,
      ivInfoBackground: IV_INFO_BACKGROUND,
      ivErrorBackground: IV_ERROR_BACKGROUND,
      ivNeutralBackground: IV_NEUTRAL_BACKGROUND,
      surface: SURFACE,
      warning: WARNING,
      success: SUCCESS,
      agent: ALTERNATIVE_COLORS_AGENT,
    },
    brand: {
      businessPremiere: BUSINESS_PREMIERE,
      tgvMax1: TGVMAX1,
      tgvMax2: TGVMAX2,
      ouigo: OUIGO,
      allianz1: ALLIANZ_BLUE,
      allianz2: ALLIANZ_ORANGE,
      inoui1: INOUI1,
      inoui2: INOUI2,
      eurostar: EUROSTAR,
      ter: TER,
      carteAbonnement: CARTE_ABONNEMENT,
      carteLiberte: CARTE_LIBERTE,
      carteAvantageBackground: CARTE_AVANTAGE_BACKGROUND,
      carteAvantagePointsBackground: CARTE_AVANTAGE_POINTS_BACKGROUND,
      carteAvantage: CARTE_AVANTAGE,
      carteAvantageLeClub: CARTE_AVANTAGE_LE_CLUB,
      idfm: IDFM,
    },
    invertedColors: {
      invertedColorsBackground: INVERTED_COLORS_BACKGROUND,
      invertedColorsOnBackground: INVERTED_COLORS_ON_BACKGROUND,
      invertedColorsAction: INVERTED_COLORS_ACTION,
      invertedColorsActionHover: INVERTED_COLORS_ACTION_HOVER,
      invertedColorsSelected: INVERTED_COLORS_SELECTED,
      invertedColorsSurface: INVERTED_COLORS_SURFACE,
      invertedColorsElevated: INVERTED_COLORS_ELEVATED,
      invertedColorsTextSecondary: INVERTED_COLORS_TEXT_SECONDARY,
      error: INVERTED_COLORS_ERROR,
      warning: INVERTED_COLORS_WARNING,
      success: INVERTED_COLORS_SUCCESS,
    },
    appOnly: {
      homePageIllustration: HOME_PAGE_ILLUSTRATION,
      homePageBackground: HOME_PAGE_BACKGROUND,
      searchButton: SEARCH_BUTTON,
      invertedCursor: INVERTED_CURSOR,
      invertedPlaceholder: INVERTED_PLACEHOLDER,
    },
    BG: {
      ActionHigh: {
        Initial: LIGHT_BLUE,
      },
      ActionLow: {
        Initial: 'transparent',
      },
      Attribute: {
        Initial: XTRA_DARK_BLUE,
      },
    },
    FG: {
      ActionHigh: {
        Initial: XTRA_DARK_BLUE,
      },
      ActionLow: {
        Initial: LIGHT_BLUE,
      },
      Attribute: {
        Initial: WHITE,
      },
    },
  },
}

const lightTheme: Partial<Theme> = {
  customPalette: {
    onElements: {
      outline: SLATE_GREY_LIGHT_MODE,
      selected: GREY_SELECTED_LIGHT_MODE,
      elevated: LIGHT_GREY_LIGHT_MODE,
      overlay: OVERLAY_GREY_LIGHT_MODE,
      ripple: RIPPLE_GREY,
    },
    alternative: {
      ecology: GREEN_ECO_LIGHT_MODE,
      service: PURPLE_LIGHT_MODE,
      info: BLUE_INFO_LIGHT_MODE,
      recommandation: YELLOW_LIGHT_MODE,
      communication: YELLOW_LIGHT_MODE,
      separator: BLACK,
      extraIcon1: EXTRA_ICON_1_LIGHT_MODE,
      extraIcon2: EXTRA_ICON_2_LIGHT_MODE,
      extraIcon3: EXTRA_ICON_3_LIGHT_MODE,
      extraIcon4: EXTRA_ICON_4_LIGHT_MODE,
      onExtraIcon2: ON_EXTRA_ICON_2_LIGHT_MODE,
      onAlternativeColor1: ON_ALTERNATIVE_COLOR_1_LIGHT_MODE,
      onAlternativeColor2: ON_ALTERNATIVE_COLOR_2_LIGHT_MODE,
      onAlternativeColor3: ON_ALTERNATIVE_COLOR_3_LIGHT_MODE,
      onAlternativeColor4: ON_ALTERNATIVE_COLOR_4_LIGHT_MODE,
      cart: CART,
      notification: NOTIFICATION_LIGHT_MODE,
      cartHover: CART_HOVER,
      firstClass: URANIAN_BLUE,
      secondClass: ARCTIC_BLUE,
      businessClass: CORNFLOWER_BLUE,
      onService: ON_SERVICE_LIGHT_MODE,
      onCommunication: ON_COMMUNICATION_LIGHT_MODE,
      onInfo: ON_INFO_LIGHT_MODE,
      warningBackground: WARNING_BACKGROUND_LIGHT_MODE,
      successBackground: SUCCESS_BACKGROUND_LIGHT_MODE,
      infoBackground: INFO_BACKGROUND_LIGHT_MODE,
      errorBackground: ERROR_BACKGROUND_LIGHT_MODE,
      ivWarningBackground: IV_WARNING_BACKGROUND_LIGHT_MODE,
      ivSuccessBackground: IV_SUCCESS_BACKGROUND_LIGHT_MODE,
      ivInfoBackground: IV_INFO_BACKGROUND_LIGHT_MODE,
      ivErrorBackground: IV_ERROR_BACKGROUND_LIGHT_MODE,
      ivNeutralBackground: IV_NEUTRAL_BACKGROUND_LIGHT_MODE,
      surface: SURFACE_LIGHT_MODE,
      warning: ORANGE_WARNING_LIGHT_MODE,
      success: GREEN_SUCCESS_LIGHT_MODE,
      agent: ALTERNATIVE_COLORS_AGENT_LIGHT_MODE,
    },
    brand: {
      businessPremiere: BUSINESS_PREMIERE,
      tgvMax1: TGVMAX1,
      tgvMax2: TGVMAX2,
      ouigo: OUIGO,
      allianz1: ALLIANZ_BLUE,
      allianz2: ALLIANZ_ORANGE,
      inoui1: INOUI1,
      inoui2: INOUI2,
      eurostar: EUROSTAR,
      ter: TER,
      carteAbonnement: CARTE_ABONNEMENT,
      carteLiberte: CARTE_LIBERTE,
      carteAvantageBackground: CARTE_AVANTAGE_BACKGROUND,
      carteAvantagePointsBackground: CARTE_AVANTAGE_POINTS_BACKGROUND,
      carteAvantage: CARTE_AVANTAGE,
      carteAvantageLeClub: CARTE_AVANTAGE_LE_CLUB,
      idfm: IDFM,
    },
    invertedColors: {
      invertedColorsBackground: INVERTED_COLORS_BACKGROUND_LIGHT_MODE,
      invertedColorsOnBackground: INVERTED_COLORS_ON_BACKGROUND_LIGHT_MODE,
      invertedColorsAction: INVERTED_COLORS_ACTION_LIGHT_MODE,
      invertedColorsActionHover: INVERTED_COLORS_ACTION_HOVER_LIGHT_MODE,
      invertedColorsSelected: INVERTED_COLORS_SELECTED_LIGHT_MODE,
      invertedColorsSurface: INVERTED_COLORS_SURFACE_LIGHT_MODE,
      invertedColorsElevated: INVERTED_COLORS_ELEVATED_LIGHT_MODE,
      invertedColorsTextSecondary: INVERTED_COLORS_TEXT_SECONDARY_LIGHT_MODE,
      error: INVERTED_COLORS_ERROR_LIGHT_MODE,
      warning: INVERTED_COLORS_WARNING_LIGHT_MODE,
      success: INVERTED_COLORS_SUCCESS_LIGHT_MODE,
    },
    appOnly: {
      homePageIllustration: HOME_PAGE_ILLUSTRATION_LIGHT_MODE,
      homePageBackground: HOME_PAGE_BACKGROUND_LIGHT_MODE,
      searchButton: SEARCH_BUTTON_LIGHT_MODE,
      invertedCursor: INVERTED_CURSOR_LIGHT_MODE,
      invertedPlaceholder: INVERTED_PLACEHOLDER_LIGHT_MODE,
    },
    BG: {
      ActionHigh: {
        Initial: LIGHT_BLUE_LIGHT_MODE,
      },
      ActionLow: {
        Initial: 'transparent',
      },
      Attribute: {
        Initial: LIGHT_BLUE_LIGHT_MODE,
      },
    },
    FG: {
      ActionHigh: {
        Initial: WHITE,
      },
      ActionLow: {
        Initial: LIGHT_BLUE_LIGHT_MODE,
      },
      Attribute: {
        Initial: WHITE,
      },
    },
  },
}

const proTheme: Partial<Theme> = {
  customPalette: {
    ...(lightTheme.customPalette as Theme['customPalette']),
    BG: {
      ActionHigh: {
        Initial: SKYLINE_GRADIENT,
      },
      ActionLow: {
        Initial: 'transparent',
      },
      Attribute: {
        Initial: LAVENDER_CHARM,
      },
    },
    FG: {
      ActionHigh: {
        Initial: NOBLE_GREY,
      },
      ActionLow: {
        Initial: LAVENDER_CHARM,
      },
      Attribute: {
        Initial: WHITE,
      },
    },
  },
}

export const customPalette = ({
  hasDarkMode,
  hasProMode,
}: {
  hasDarkMode: boolean
  hasProMode: boolean
}): Partial<Theme> => {
  if (hasDarkMode) {
    return darkTheme
  }

  if (hasProMode) {
    return proTheme
  }

  return lightTheme
}
