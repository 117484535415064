import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import { differenceInCalendarDays, getDaysInMonth, getMonth, getYear, isSameDay, setDate } from 'date-fns'

import { isDateOutsideRange, isDateOutsideRestrictedList } from '@Utils/datepicker'

import { Mode, SelectionType, Target } from '../../DatePicker.types'
import Day from '../day/Day'

import * as styles from './styles'

const getMonthDays = (month: Date): Date[] =>
  [...Array(getDaysInMonth(month)).keys()].map((day) => setDate(month, day + 1))

const getSelectionType = (
  startDate: Date | undefined,
  isStartDate: boolean,
  isEndDate: boolean,
  endOfRange: Date | undefined
): SelectionType => {
  if (isStartDate && isEndDate) {
    return 'PLAIN_WITH_CIRCLE'
  }

  const isShowingARange = !!endOfRange && !!startDate && differenceInCalendarDays(endOfRange, startDate) > 0

  if (isEndDate || (!isShowingARange && isStartDate)) {
    return 'PLAIN'
  }

  if (isShowingARange && isStartDate) {
    return 'CIRCLE'
  }

  return 'NONE'
}

export type MonthProps = {
  currentTarget: Target
  endDate?: Date
  hoveredDate?: Date
  maxDate?: Date
  mode: Mode
  month: Date
  onDaySelect: (clickedDay: Date) => void
  restrictedDatesList?: Date[]
  isDayFocusable?: boolean
  setHoveredDate: (day?: Date | undefined) => void
  startDate?: Date
}

const Month: FC<MonthProps> = ({
  currentTarget,
  endDate,
  hoveredDate,
  maxDate: maxDateProps,
  mode,
  month,
  onDaySelect,
  restrictedDatesList,
  isDayFocusable = false,
  setHoveredDate,
  startDate,
}) => {
  const { formatMessage } = useIntl()

  const today = new Date()
  const minDate =
    !!startDate && (mode === 'INWARD' || mode === 'SINGLE_END_DATE') && differenceInCalendarDays(today, startDate) < 0
      ? startDate
      : today
  const maxDate = maxDateProps ?? (mode === 'OUTWARD' ? endDate : undefined)

  const orderedWeekDays = [1, 2, 3, 4, 5, 6, 0]

  const monthTitle = (_month: Date) =>
    `${formatMessage({ id: 'datepicker_monthNames' }, { index: `m${getMonth(_month)}` })} ${getYear(_month)}`

  const dayTitle = (dayIndex: number) => formatMessage({ id: 'datepicker_shortDayNames' }, { index: `d${dayIndex}` })

  return (
    <div css={styles.root}>
      <Typography css={styles.title} variant="h3">
        {monthTitle(month)}
      </Typography>
      <div css={styles.grid}>
        {orderedWeekDays.map((dayIndex) => (
          <div key={dayIndex} css={styles.dayTitle}>
            <Typography component="span" variant="body2">
              {dayTitle(dayIndex)}
            </Typography>
          </div>
        ))}

        {getMonthDays(month).map((day: Date) => {
          const isStartDate = !!startDate && isSameDay(day, startDate)
          const isEndDate = !!endDate && isSameDay(day, endDate)

          const isSelectable =
            !isDateOutsideRestrictedList(day, restrictedDatesList) && !isDateOutsideRange(day, minDate, maxDate)

          const isHoveredDate = !!hoveredDate && isSelectable && isSameDay(day, hoveredDate)
          const endOfRange = endDate || (currentTarget !== 'INIT' ? hoveredDate : undefined)
          const selectionType = getSelectionType(startDate, isStartDate, isEndDate, endOfRange)

          const isHovered = isHoveredDate && selectionType === 'NONE'

          const isInRange =
            mode !== 'SINGLE_START_DATE' &&
            !!startDate &&
            !!endOfRange &&
            differenceInCalendarDays(day, startDate) >= 0 &&
            differenceInCalendarDays(day, endOfRange) <= 0 &&
            !(isStartDate && isSameDay(day, endOfRange))

          const isRangeStart = mode !== 'SINGLE_START_DATE' && isInRange && isStartDate
          const isRangeEnd = mode !== 'SINGLE_START_DATE' && isInRange && isSameDay(day, endOfRange)

          return (
            <Day
              key={day.toUTCString()}
              onDaySelect={onDaySelect}
              selectionType={selectionType}
              isSelectable={isSelectable}
              isInRange={isInRange}
              isRangeStart={isRangeStart}
              isRangeEnd={isRangeEnd}
              isHovered={isHovered}
              isFocusable={isDayFocusable}
              setHoveredDate={setHoveredDate}
              day={day}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Month
