import { createContext, FC, ReactNode, useContext } from 'react'

import { Features } from '@Types/features'

export type FeaturesContextProps = {
  features?: Features
  children: ReactNode
}

const FeaturesContext = createContext<Features | undefined>(undefined)

export const FeaturesProvider: FC<FeaturesContextProps> = ({ children, features }) => (
  <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>
)

export const useFeatures = (): Features => {
  const context = useContext(FeaturesContext)

  if (context === undefined) {
    throw new Error('useFeatures must be used within a FeaturesProvider')
  }

  return context
}
