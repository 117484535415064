import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const overridesAppBar = (theme: Theme): ComponentsOverrides['MuiAppBar'] => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  colorPrimary: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
})
