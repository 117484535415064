import { FC, HTMLAttributes, ReactElement, useCallback, useState } from 'react'
import { BackdropProps, Drawer } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useFeatures } from '@Contexts/features/FeaturesContext'

import { DRAWER_TRANSITION_DURATION, ModalControls, ModalNavControls, ModalNavData } from './Modal.types'
import { ModalControlsProvider, ModalNavDataProvider, ModalPrivateContextState, ModalProvider } from './ModalContext'
import * as styles from './styles'

export type ModalProps = {
  isBackIconDisplayed?: boolean
  isOpen: boolean
  size?: 'small' | 'large'
  modalNavControls?: ModalNavControls
  modalNavData?: ModalNavData
  dynamicPage?: ReactElement
  onBackIconClick?: VoidFunction
  onClose: VoidFunction
  ariaLabelledBy?: string
}

const Modal: FC<ModalProps & HTMLAttributes<HTMLDivElement>> = ({
  children,
  dynamicPage,
  isBackIconDisplayed,
  isOpen,
  size = 'small',
  modalNavControls = {
    goToModalPage: () => {},
    goToParentPage: () => {},
    goToDynamicModalPage: () => {},
    goToNextModalPage: () => {},
  },
  modalNavData = {
    getNavContextData: () => {},
    setNavContextData: () => undefined,
    clearNavContextData: () => undefined,
  },
  onBackIconClick,
  onClose,
  ariaLabelledBy = 'modal-header-title',
  ...rest
}) => {
  const theme = useTheme()

  const [isBackdropFilterDisabled, setIsBackdropFilterDisabled] = useState(false)

  const { isIvts61782DoubleVocalisationBotFooterHeaderTitleEnabled } = useFeatures()

  const closeModal = useCallback(() => {
    setIsBackdropFilterDisabled(true)
    onClose()
    setTimeout(() => setIsBackdropFilterDisabled(false), DRAWER_TRANSITION_DURATION)
  }, [onClose])

  const modalContextState: ModalPrivateContextState = {
    isBackIconDisplayed,
    onBackIconClick,
    onClose: closeModal,
  }

  const modalControls: ModalControls = { closeModal, ...modalNavControls }

  return (
    <ModalProvider value={modalContextState}>
      <Drawer
        {...rest}
        transitionDuration={DRAWER_TRANSITION_DURATION}
        anchor="right"
        open={isOpen}
        onClose={closeModal}
        role={undefined} // NOTE: To override role='presentation'
        PaperProps={{
          role: 'dialog',
          style: styles.paper,
          'aria-labelledby': isIvts61782DoubleVocalisationBotFooterHeaderTitleEnabled
            ? 'aria-labelledby'
            : ariaLabelledBy,
        }}
        BackdropProps={
          {
            'data-test': 'modal-backdrop',
            // NOTE: backdropFilter has to be deactivated before the closing animation due to a Chrome visual bug https://jira.vsct.fr/browse/IVTS-46880
            ...(isBackdropFilterDisabled && { style: { backdropFilter: 'none' } }),
          } as Partial<BackdropProps> // NOTE: This type assertion is legitimate https://github.com/mui-org/material-ui/issues/20160
        }
      >
        <ModalControlsProvider value={modalControls}>
          <ModalNavDataProvider value={modalNavData}>
            <div css={styles.page(theme, size)}>{dynamicPage || children}</div>
          </ModalNavDataProvider>
        </ModalControlsProvider>
      </Drawer>
    </ModalProvider>
  )
}

export default Modal
