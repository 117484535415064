import { ButtonProps } from '@mui/material'
import { buttonBaseClasses } from '@mui/material/ButtonBase'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

const isSecondaryButton = (ownerState: ButtonProps) => ownerState.variant === 'outlined'

export const overridesButton = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: ({ ownerState }) => ({
    ...(isSecondaryButton(ownerState) && {
      background: theme.customPalette.BG.ActionLow.Initial,
      color: theme.customPalette.FG.ActionLow.Initial,
    }),
  }),
})

export const overridesButtonBase = (theme: Theme): ComponentsOverrides['MuiButtonBase'] => ({
  root: {
    [`&.${buttonBaseClasses.disabled}`]: {
      '& svg': {
        fill: theme.palette.text.disabled,
      },
      color: theme.palette.text.disabled,
    },
  },
})
