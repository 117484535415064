import { type OutputErrorMessage } from './generated'

export type SdkErrorType =
  | 'authentificationFailed'
  | 'functionalError'
  | 'serverError'
  | 'technicalError'
  | 'networkError'

export interface SdkError {
  isSdkError: boolean
  type: SdkErrorType
  data?: OutputErrorMessage
  displayedMessage?: string
}

export const isSdkError = (error: unknown): error is SdkError => (error as SdkError).isSdkError
