import { AutocompleteSdk } from 'invictus-sdk-typescript'

import { useSdkState } from '@Contexts/sdk/SdkContext'

export const useAutocompleteSdk = (): AutocompleteSdk => {
  const { autocompleteSdk } = useSdkState()

  if (autocompleteSdk === undefined) {
    throw new Error('useAutocompleteSdk must be used within a SdkProvider with a autocompleteSdk')
  }

  return autocompleteSdk
}
