import {
  createRef,
  FC,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { usePrevious } from 'react-use'
import { useTheme } from '@mui/material/styles'
import { AutocompleteRequestUsageEnum } from 'invictus-sdk-typescript'

import AutocompleteNoResultsGenericMessage from '@DS/components/components/searchBar/AutocompleteNoResultsGenericMessage/AutocompleteNoResultsGenericMessage'
import { AutocompleteResultModel } from '@DS/components/navigation/search/atoms/AutocompleteResult'
import Autocomplete from '@DS/components/navigation/search/autocomplete/Autocomplete'
import { AutocompleteBaseProps } from '@DS/components/navigation/search/autocomplete/Autocomplete.types'
import { ODSearchElevation, ODSearchOnSwap } from '@DS/components/navigation/search/common/ODSearch.types'
import ODSearch from '@DS/components/navigation/search/odSearch/ODSearch'
import { AutocompleteInputProps } from '@Hooks/autocomplete/useAutocomplete'
import { useSearchAutocomplete } from '@Hooks/autocomplete/useSearchAutocomplete'
import { useSearchAutocompleteSuggestions } from '@Hooks/autocomplete/useSearchAutocompleteSuggestions'

import * as styles from './styles'

const DefaultAutocompleteWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div css={styles.autocompleteContainer}>{children}</div>
)

const defaultEmptyHandler = () => {}

type FocusedField = 'NONE' | 'ORIGIN' | 'DESTINATION'

type BookingODSearchControllerProps = {
  elevation?: ODSearchElevation
  originInputValue?: string
  destinationInputValue?: string
  AutocompleteWrapper?: FC
  onClearOrigin?: VoidFunction
  onClearDestination?: VoidFunction
  onAutocompleteError?: (error: unknown) => void
  originAutocompleteProps?: Partial<AutocompleteBaseProps<AutocompleteResultModel>>
  destinationAutocompleteProps?: Partial<AutocompleteBaseProps<AutocompleteResultModel>>
  originInputPlaceProps?: Partial<Omit<AutocompleteInputProps, 'defaultValue'>>
  destinationInputPlaceProps?: Partial<Omit<AutocompleteInputProps, 'defaultValue'>>
  onSwap?: ODSearchOnSwap
  isSwapHidden?: boolean
  hasToKeepStationsOnly?: boolean
  usage?: AutocompleteRequestUsageEnum
  shouldIgnoreSuggestions?: boolean
  hasToRestoreSelectedOnBlur?: boolean
}

const BookingODSearchController: FC<BookingODSearchControllerProps & HTMLAttributes<HTMLDivElement>> = ({
  elevation,
  originInputValue,
  destinationInputValue,
  AutocompleteWrapper = DefaultAutocompleteWrapper,
  onClearOrigin = defaultEmptyHandler,
  onClearDestination = defaultEmptyHandler,
  originAutocompleteProps,
  destinationAutocompleteProps,
  originInputPlaceProps: {
    inputRef: originInputPlacePropsInputRef,
    error: originHasError,
    onFocus: originOnFocus = defaultEmptyHandler,
    onBlur: originOnBlur = defaultEmptyHandler,
    'aria-describedby': originAriaDescribedby,
    ...restOriginInputPlaceProps
  } = {},
  destinationInputPlaceProps: {
    inputRef: destinationInputPlacePropsInputRef,
    error: destinationHasError,
    onFocus: destinationOnFocus = defaultEmptyHandler,
    onBlur: destinationOnBlur = defaultEmptyHandler,
    'aria-describedby': destinationAriaDescribedby,
    ...restDestinationInputPlaceProps
  } = {},
  onSwap,
  isSwapHidden = false,
  hasToKeepStationsOnly = false,
  usage,
  shouldIgnoreSuggestions = false,
  hasToRestoreSelectedOnBlur: hasToRestoreSelectedOnBlurProp = false,
  onAutocompleteError,
  ...rest
}) => {
  const theme = useTheme()

  const previousOriginInputValue = usePrevious(originInputValue)
  const previousDestinationInputValue = usePrevious(destinationInputValue)
  const [hasToRestoreSelectedOnBlur, setHasToRestoreSelectedOnBlur] = useState(hasToRestoreSelectedOnBlurProp)
  const [shouldForceOpenAutocomplete, setShouldForceOpenAutocomplete] = useState(false)
  const [isSwapped, setIsSwapped] = useState(false)
  const [isSwapping, setIsSwapping] = useState(false)
  const [focusedField, setFocusedField] = useState<FocusedField>('NONE')
  const originInputRef = originInputPlacePropsInputRef || createRef()
  const destinationInputRef = destinationInputPlacePropsInputRef || createRef()
  const suggestionsOptions = useMemo(() => ({ shouldIgnoreSuggestions }), [shouldIgnoreSuggestions])
  const [originSuggestions] = useSearchAutocompleteSuggestions('origin', suggestionsOptions)
  const [destinationSuggestions] = useSearchAutocompleteSuggestions('destination', suggestionsOptions)

  // On swap button click, autocomplete should be kept open and input value should not be restored
  const preserveFocusOnSwapClick = (shouldOpen: boolean) => {
    setShouldForceOpenAutocomplete(shouldOpen)
    setHasToRestoreSelectedOnBlur(false)
  }

  // Clean up modified state when swap is complete (see preserveFocusOnSwapClick)
  const cleanUpAfterSwap = useCallback(() => {
    setShouldForceOpenAutocomplete(false)
    setHasToRestoreSelectedOnBlur(hasToRestoreSelectedOnBlurProp)
    setIsSwapping(false)
  }, [hasToRestoreSelectedOnBlurProp])

  const commonAutocompleteOptions = {
    onError: onAutocompleteError,
    hasToAutoHighlight: true,
    hasToRestoreSelectedOnBlur,
    hasToKeepStationsOnly,
    usage,
  }

  const [
    ac1InputProps,
    ac1Props,
    { setInputValue: setAc1InputValue, clearInputValue: clearAc1InputValue },
    { hasNoResults: hasNoResultsAc1 },
  ] = useSearchAutocomplete(isSwapped ? destinationSuggestions : originSuggestions, {
    autocompleteProps: isSwapped ? destinationAutocompleteProps : originAutocompleteProps,
    inputProps: {
      inputProps: { 'aria-describedby': isSwapped ? destinationAriaDescribedby : originAriaDescribedby },
      defaultValue: isSwapped ? destinationInputValue : originInputValue,
      onFocus: () => {
        setFocusedField(isSwapped ? 'DESTINATION' : 'ORIGIN')
        setShouldForceOpenAutocomplete(false)

        if (isSwapped) {
          destinationOnFocus()
        } else {
          originOnFocus()
        }
      },
      onBlur: (event) => {
        const { relatedTarget } = event || {}

        const otherInputRef = isSwapped ? originInputRef : destinationInputRef

        if (relatedTarget && 'current' in otherInputRef && otherInputRef.current?.contains(relatedTarget as Node)) {
          setShouldForceOpenAutocomplete(true)
        }

        if (isSwapped) {
          destinationOnBlur()
        } else {
          originOnBlur()
        }
      },
      inputRef: isSwapped ? destinationInputRef : originInputRef,
      ...(isSwapped ? restDestinationInputPlaceProps : restOriginInputPlaceProps),
    },
    ...commonAutocompleteOptions,
  })

  const [
    ac2InputProps,
    ac2Props,
    { setInputValue: setAc2InputValue, clearInputValue: clearAc2InputValue },
    { hasNoResults: hasNoResultsAc2 },
  ] = useSearchAutocomplete(isSwapped ? originSuggestions : destinationSuggestions, {
    autocompleteProps: isSwapped ? originAutocompleteProps : destinationAutocompleteProps,
    inputProps: {
      inputProps: { 'aria-describedby': isSwapped ? originAriaDescribedby : destinationAriaDescribedby },
      defaultValue: isSwapped ? originInputValue : destinationInputValue,
      onFocus: () => {
        setFocusedField(isSwapped ? 'ORIGIN' : 'DESTINATION')
        setShouldForceOpenAutocomplete(false)

        if (isSwapped) {
          originOnFocus()
        } else {
          destinationOnFocus()
        }
      },
      onBlur: (event) => {
        const { relatedTarget } = event || {}

        const otherInputRef = isSwapped ? destinationInputRef : originInputRef

        if (relatedTarget && 'current' in otherInputRef && otherInputRef.current?.contains(relatedTarget as Node)) {
          setShouldForceOpenAutocomplete(true)
        }

        if (isSwapped) {
          originOnBlur()
        } else {
          destinationOnBlur()
        }
      },
      inputRef: isSwapped ? originInputRef : destinationInputRef,
      ...(isSwapped ? restOriginInputPlaceProps : restDestinationInputPlaceProps),
    },
    ...commonAutocompleteOptions,
  })

  // Update values when change is coming from store
  useEffect(() => {
    if (!originInputValue || originInputValue === previousOriginInputValue) {
      return
    }

    if (isSwapped) {
      setAc2InputValue(originInputValue)
    } else {
      setAc1InputValue(originInputValue)
    }
  }, [originInputValue, previousOriginInputValue, isSwapped, setAc1InputValue, setAc2InputValue])

  useEffect(() => {
    if (!destinationInputValue || destinationInputValue === previousDestinationInputValue) {
      return
    }

    if (isSwapped) {
      setAc1InputValue(destinationInputValue)
    } else {
      setAc2InputValue(destinationInputValue)
    }
  }, [destinationInputValue, previousDestinationInputValue, isSwapped, setAc1InputValue, setAc2InputValue])

  // Focus field after swap, after handlers for both fields have been swapped in DOM
  useLayoutEffect(() => {
    if (!isSwapping) {
      return
    }

    if (shouldForceOpenAutocomplete) {
      if (focusedField === 'ORIGIN' && 'current' in destinationInputRef) {
        destinationInputRef.current?.focus()
      } else if (focusedField === 'DESTINATION' && 'current' in originInputRef) {
        originInputRef.current?.focus()
      }
    }

    cleanUpAfterSwap()
  }, [shouldForceOpenAutocomplete, isSwapping, focusedField, destinationInputRef, originInputRef, cleanUpAfterSwap])

  const handleSwap: ODSearchOnSwap = (oldValues, newValues) => {
    setIsSwapping(true)
    setIsSwapped((actualIsSwapped) => !actualIsSwapped)
    onSwap?.(oldValues, newValues)
  }

  const originOnClear = () => {
    onClearOrigin()

    if ('current' in originInputRef) {
      originInputRef.current?.focus()
    }

    if (isSwapped) {
      clearAc2InputValue()
    } else {
      clearAc1InputValue()
    }
  }

  const destinationOnClear = () => {
    onClearDestination()

    if ('current' in destinationInputRef) {
      destinationInputRef.current?.focus()
    }

    if (isSwapped) {
      clearAc1InputValue()
    } else {
      clearAc2InputValue()
    }
  }

  const originInputProps = isSwapped ? ac2InputProps : ac1InputProps
  const destinationInputProps = isSwapped ? ac1InputProps : ac2InputProps

  const isAC1CurrentAutocomplete =
    (focusedField === 'ORIGIN' && ((!isSwapped && !isSwapping) || (isSwapped && isSwapping))) ||
    (focusedField === 'DESTINATION' && ((isSwapped && !isSwapping) || (!isSwapped && isSwapping)))

  const { shouldOpen: currentShouldOpen, ...currentAcProps } = isAC1CurrentAutocomplete ? ac1Props : ac2Props
  const shouldDisplayNoResults = isAC1CurrentAutocomplete ? hasNoResultsAc1 : hasNoResultsAc2
  const inputValue = isAC1CurrentAutocomplete ? ac1InputProps.value : ac2InputProps.value

  return (
    <div {...rest}>
      <ODSearch
        elevation={elevation}
        originInput={{
          autoFocus: originInputValue === undefined,
          error: originHasError,
          onClear: originOnClear,
          ...originInputProps,
        }}
        destinationInput={{
          autoFocus: destinationInputValue === undefined && originInputValue !== undefined,
          error: destinationHasError,
          onClear: destinationOnClear,
          ...destinationInputProps,
        }}
        onSwap={isSwapHidden ? undefined : handleSwap}
        swapProps={{ onMouseDown: () => preserveFocusOnSwapClick(currentShouldOpen) }}
      />
      {focusedField !== 'NONE' && (
        <AutocompleteWrapper>
          <Autocomplete
            css={styles.autocomplete(theme)}
            {...currentAcProps}
            shouldOpen={shouldForceOpenAutocomplete || currentShouldOpen}
          >
            {shouldDisplayNoResults && <AutocompleteNoResultsGenericMessage inputValue={inputValue} />}
          </Autocomplete>
        </AutocompleteWrapper>
      )}
    </div>
  )
}

export default BookingODSearchController
