import { Configuration } from 'invictus-sdk-typescript'
import { BuildManifest } from 'next/dist/server/get-page-files'

import { PublicRuntimeConfiguration } from '@Utils/config'

declare const PUBLIC_RUNTIME_CONFIG: PublicRuntimeConfiguration

declare const NEXT_BUILD_MANIFEST: BuildManifest

const isDev = process.env.NODE_ENV === 'development'

const getWidgetFrontendApiConfiguration = (): Configuration =>
  new Configuration({
    basePath: isDev ? PUBLIC_RUNTIME_CONFIG.frontendApiURL : 'https://www.sncf-connect.com/bff',
  })

const externalWidgetPublicRuntimeConfig: PublicRuntimeConfiguration = { ...PUBLIC_RUNTIME_CONFIG }

const externalWidgetNextBuildManifest: BuildManifest = { ...NEXT_BUILD_MANIFEST }

export { getWidgetFrontendApiConfiguration, externalWidgetPublicRuntimeConfig, externalWidgetNextBuildManifest }
