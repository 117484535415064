import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { SPACING_4, SPACING_6 } from '@DS/styles/constants/spacing'

export const root = (theme: Theme, isInModal: boolean): SerializedStyles =>
  css(
    {
      height: '100%',
      userSelect: 'none',
    },
    !isInModal && {
      [theme.breakpoints.up('lg')]: {
        margin: '0 auto',
        padding: `0 ${SPACING_6}`,
        position: 'relative',
        width: rem(800),
      },
    }
  )

export const months = (theme: Theme, isInModal: boolean): SerializedStyles =>
  css(
    {
      height: '100%',
      overflow: 'auto',
    },
    !isInModal && {
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridGap: SPACING_4,
        gridTemplateColumns: '1fr 1fr',
        height: rem(314),
        overflow: 'initial',
      },
    }
  )

export const arrow = (theme: Theme): SerializedStyles =>
  css({
    '&[disabled]': {
      opacity: 0.2,
    },
    '.arrow_circle-arrow': {
      fill: theme.palette.text.primary,
    },
    fill: theme.palette.background.default,
    padding: 0,
    position: 'absolute',
    top: rem(120),
  })

export const arrowIcon = (direction: 'left' | 'right' = 'left'): SerializedStyles =>
  css(direction === 'right' && { transform: 'rotate(180deg)' }, size(rem(40)))

export const arrowLeft = css({
  left: 0,
})

export const arrowRight = css({
  right: 0,
})

export const rowVirtualizerContainer = (totalSize: number): SerializedStyles =>
  css({
    height: rem(totalSize),
    position: 'relative',
    width: '100%',
  })

export const rowVirtualizerItem = (startOffset: number): SerializedStyles =>
  css({
    left: 0,
    position: 'absolute',
    top: 0,
    transform: `translateY(${rem(startOffset)})`,
    width: '100%',
  })
