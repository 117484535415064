export enum LegType {
  TRAIN = 'TRAIN',
  CAR = 'CAR',
  RER = 'RER',
  BUS = 'BUS',
  FOOT = 'FOOT',
  METRO = 'METRO',
  TRAMWAY = 'TRAMWAY',
  WAITING = 'WAITING',
  BOARDING = 'BOARDING',
  LANDING = 'LANDING',
  CROW_FLY = 'CROW_FLY',
  BUS_RAPID_TRANSIT = 'BUS_RAPID_TRANSIT',
  COACH = 'COACH',
  FUNICULAR = 'FUNICULAR',
  RAPID_TRANSIT = 'RAPID_TRANSIT',
  SHUTTLE = 'SHUTTLE',
  BIKE = 'BIKE',
  INTERCITY = 'INTERCITY',
  HST = 'HST',
  OTHER_TRAIN = 'OTHER_TRAIN',
  CAR_AGREEMENT = 'CAR_AGREEMENT',
  TER = 'TER',
  CARPOOL = 'CARPOOL',
  SHARED_CAR = 'SHARED_CAR',
  OTHER = 'OTHER',
  LOWCOST_BUS = 'LOWCOST_BUS',
  BOAT = 'BOAT',
  NOCTILIEN = 'NOCTILIEN',
  NAVETTE = 'NAVETTE',
  CABLE_CAR = 'CABLE_CAR',
}

export enum ModeType {
  DRIVING = 'DRIVING',
  WALKING = 'WALKING',
  BICYCLING = 'BICYCLING',
  TRANSIT = 'TRANSIT',
  OTHER = 'OTHER',
}

export enum Behaviour {
  UNRECOVERABLE = 'UNRECOVERABLE',
  RECOVERABLE = 'RECOVERABLE',
  IGNORE = 'IGNORE',
  DISPLAY_FIELD_ERROR = 'DISPLAY_FIELD_ERROR',
}

export enum TravelerIcon {
  TRAVELER = 'TRAVELER',
  PET = 'PET',
  BIKE = 'BIKE',
}

export enum CartError {
  CART_UNSUPPORTED_PRODUCT_MIX = '/booking/unsupported-product-mix',
  CART_SEAT_NOT_AVAILABLE = '/booking/seat-not-available',
}

export enum VisitorType {
  NEW_USER = '0',
  KNOWN_USER = '1',
}

export enum CartItemType {
  TRIP = 'TRIP',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TICKET = 'TICKET',
}

export enum CartNextStep {
  CONTACT = 'CONTACT',
  PAYMENT = 'PAYMENT',
}

export enum CartPreReservationStatus {
  PRERESERVABLE = 'PRERESERVABLE',
  NOTPRERESERVABLE = 'NOT_PRERESERVABLE',
  PRERESERVED = 'PRERESERVED',
}

export enum TripState {
  ORIGIN_IS_DELETED = 'ORIGIN_IS_DELETED',
  DESTINATION_IS_DELETED = 'DESTINATION_IS_DELETED',
  DEPARTURE_TIME_IS_UPDATED = 'DEPARTURE_TIME_IS_UPDATED',
  ARRIVAL_TIME_IS_UPDATED = 'ARRIVAL_TIME_IS_UPDATED',
  DEPARTURE_IS_REPORTED = 'DEPARTURE_IS_REPORTED',
  ARRIVAL_IS_REPORTED = 'ARRIVAL_IS_REPORTED',
}

export enum SegmentState {
  IS_DELETED = 'IS_DELETED',
  DEPARTURE_IS_DELETED = 'DEPARTURE_IS_DELETED',
  ARRIVAL_IS_DELETED = 'ARRIVAL_IS_DELETED',
}

export enum IconSource {
  MODE = 'MODE',
  LINE = 'LINE',
  DS_ICON = 'DS_ICON',
  GENERATE = 'GENERATE',
}

export enum DecisionActionType {
  ARRIVAL_DATE = 'ARRIVAL DATE',
  BUS_CARPOOLING = 'BUS CARPOOLING',
  DEPARTURE_DATE = 'DEPARTURE DATE',
  DESTINATION = 'DESTINATION',
  INWARD_DATE = 'INWARD DATE',
  ORIGIN = 'ORIGIN',
  OUTWARD_DATE = 'OUTWARD DATE',
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT',
  REFRESH = 'REFRESH',
  SWAP = 'SWAP',
  TRAIN = 'TRAIN',
  WEEKLY = 'WEEKLY',
  REDIRECT = 'REDIRECT',
  FILTER = 'FILTER',
  MODIFY_PASSENGERS = 'MODIFY_PASSENGERS',
}

export enum DecisionSourceType {
  FORCE = 'FORCE',
  AI = 'AI',
  CACHE = 'CACHE',
  PRIORITIZE = 'PRIORITIZE',
  EXCEPTION = 'EXCEPTION',
  FAVORITE = 'FAVORITE',
  PLAN_FAILURE = 'PLAN_FAILURE',
}

export enum SubscriptionCardState {
  EXPIRED = 'EXPIRED',
  VALID = 'VALID',
  TO_COME = 'TO_COME',
}
