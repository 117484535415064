import { ButtonHTMLAttributes, FC } from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { differenceInMinutes, format, isToday, isTomorrow, parseISO } from 'date-fns'

import { useIsMediumUp } from '@Hooks/useResponsive'
import { ReactComponent as Add } from '@Svg/navigationAndAction/add.svg'
import Locale from '@Types/locale'
import { parseLocale } from '@Utils/i18n'
import { dateFnsLocale } from '@Utils/locale'
import { capitalizeFirstLetterOfSentence } from '@Utils/lodash'

import DateTimeButton, { DateTimeButtonProps } from '../atoms/DateTimeButton'

import * as styles from './styles'

export type DateSearchInputProps = ButtonHTMLAttributes<HTMLButtonElement> &
  Omit<DateTimeButtonProps, 'prefix' | 'placeholder' | 'disabled'>

type DateSearchProps = {
  inwardDateInputField?: DateSearchInputProps
  outwardDateInputField: DateSearchInputProps
  hasTimeHidden?: boolean
  isCompactedOnMobile?: boolean
  hasMinutesDisplayed?: boolean
  isAlertingNow?: boolean
  isOutwardOnly?: boolean
}

const DateSearch: FC<DateSearchProps> = ({
  inwardDateInputField,
  outwardDateInputField: {
    value: outwardDateValue,
    onClick: outwardDateClick,
    isVisible: isOutwardDateVisible = true,
    isDisabled: isOutwardDateDisabled = false,
    buttonLabelId: outwardButtonLabelId,
    ...restOutwardDate
  },
  hasTimeHidden = false,
  isCompactedOnMobile = true,
  hasMinutesDisplayed = false,
  isAlertingNow = false,
  isOutwardOnly = false,
  ...rest
}) => {
  const theme = useTheme()
  const { formatMessage, locale } = useIntl()
  const { lang } = parseLocale(locale as Locale)
  const isMediumUp = useIsMediumUp(theme)

  const getDisplayValue = (dateToFormat: Date, isEndDate: boolean, isForA11y = false) => {
    const hourFormat = hasMinutesDisplayed ? "H':'mm" : "H'h'"
    let dateFormat: string

    if (hasTimeHidden) {
      dateFormat = 'dd/MM/yyyy'
    } else {
      dateFormat = isMediumUp || !isCompactedOnMobile ? `iii dd MMM, ${hourFormat}` : `dd MMM, ${hourFormat}`
    }

    const a11yFormat = hasTimeHidden ? `dd/MM/yyyy` : `iiii dd MMMM, ${hourFormat}`

    const timeLabel = hasTimeHidden
      ? ''
      : `, ${format(dateToFormat, hourFormat, {
          locale: dateFnsLocale[lang],
        })}`

    let date =
      isToday(dateToFormat) || isTomorrow(dateToFormat)
        ? `${formatMessage({ id: isToday(dateToFormat) ? 'today' : 'tomorrow' })}${timeLabel}`
        : capitalizeFirstLetterOfSentence(
            format(dateToFormat, isForA11y ? a11yFormat : dateFormat, {
              locale: dateFnsLocale[lang],
            })
          )

    if (isAlertingNow) {
      const diff = differenceInMinutes(dateToFormat, new Date(), { roundingMethod: 'round' })

      if (diff < 5) {
        date = formatMessage({ id: 'now' })
      }
    }

    return isForA11y
      ? formatMessage(
          {
            id:
              isEndDate && inwardDateInputField
                ? inwardDateInputField.buttonLabelId ?? 'trip_inwardDateLabel'
                : outwardButtonLabelId ?? 'trip_outwardDateLabel',
          },
          {
            date,
          }
        )
      : date
  }

  const getInwardDateInputFieldProps = ((): DateTimeButtonProps | undefined => {
    if (!inwardDateInputField) {
      return undefined
    }

    const {
      value: inwardDateValue,
      onClick: inwardDateClick,
      isDisabled: isInwardDateDisabled,
      isVisible: isInwardDateVisible,
      isWrapped = false,
      buttonLabelId: inwardButtonLabelId,
    } = inwardDateInputField

    return {
      ...inwardDateInputField,
      'aria-label': inwardDateValue && getDisplayValue(parseISO(inwardDateValue), true, true),
      buttonLabelId: inwardButtonLabelId || 'trip_inwardDateLabel',
      css: [
        styles.inputDate(
          theme,
          inwardDateInputField.isVisible && !hasTimeHidden ? 'inward' : 'singleDate',
          isInwardDateDisabled
        ),
        styles.inwardInput,
      ],
      isDisabled: isInwardDateDisabled,
      isVisible: isInwardDateVisible,
      isWrapped,
      placeholder: inwardButtonLabelId
        ? formatMessage({ id: 'dateSearchCatalog_add_inward' })
        : formatMessage({ id: isMediumUp ? 'trip_addInwardLabel' : 'trip_addInwardResponsiveLabel' }),
      startIcon: !inwardDateValue && <Add css={styles.startIcon} />,
      value: inwardDateValue && getDisplayValue(parseISO(inwardDateValue), true),
      onClick: !isInwardDateDisabled ? inwardDateClick : () => undefined,
      isCompactedOnMobile,
    }
  })()

  return (
    <div {...rest} css={styles.datesWrapper(isOutwardOnly)}>
      <DateTimeButton
        {...restOutwardDate}
        aria-label={outwardDateValue && getDisplayValue(parseISO(outwardDateValue), false, true)}
        isDisabled={isOutwardDateDisabled}
        onClick={!isOutwardDateDisabled ? outwardDateClick : () => undefined}
        css={[
          styles.inputDate(
            theme,
            inwardDateInputField && inwardDateInputField.isVisible && !hasTimeHidden ? 'outward' : 'singleDate',
            isOutwardDateDisabled
          ),
          isOutwardDateDisabled && styles.disabledLabel(theme),
        ]}
        buttonLabelId={outwardButtonLabelId || 'trip_outwardDateLabel'}
        value={outwardDateValue && getDisplayValue(parseISO(outwardDateValue), false)}
        placeholder={formatMessage({ id: 'leaveOn' })}
        isVisible={isOutwardDateVisible}
        isCompactedOnMobile={isCompactedOnMobile}
      />
      {getInwardDateInputFieldProps && <DateTimeButton {...getInwardDateInputFieldProps} />}
    </div>
  )
}

export default DateSearch
