import { backdropClasses } from '@mui/material/Backdrop'
import { alpha, Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const overridesBackdrop = (theme: Theme): ComponentsOverrides['MuiBackdrop'] => ({
  root: {
    backgroundColor: alpha(theme.customPalette.onElements.overlay ?? theme.palette.background.default, 0.7),
    backdropFilter: 'blur(2px)',
    [`&.${backdropClasses.invisible}`]: {
      backgroundColor: 'transparent',
      backdropFilter: 'none',
    },
  },
})
