/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LogoutBackRequest } from '../model';
// @ts-ignore
import { LogoutFrontResponse } from '../model';
// @ts-ignore
import { OutputErrorMessage } from '../model';
// @ts-ignore
import { RefreshTokenRequest } from '../model';
// @ts-ignore
import { TokenRequest } from '../model';
// @ts-ignore
import { UserAccountFound } from '../model';
// @ts-ignore
import { UserAccountFoundV2 } from '../model';
/**
 * AuthenticationControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (xBffKey: string, tokenRequest: TokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('authenticate', 'xBffKey', xBffKey)
            // verify required parameter 'tokenRequest' is not null or undefined
            assertParamExists('authenticate', 'tokenRequest', tokenRequest)
            const localVarPath = `/api/v1/authenticate/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {LogoutBackRequest} logoutBackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBack: async (xBffKey: string, logoutBackRequest: LogoutBackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('logoutBack', 'xBffKey', xBffKey)
            // verify required parameter 'logoutBackRequest' is not null or undefined
            assertParamExists('logoutBack', 'logoutBackRequest', logoutBackRequest)
            const localVarPath = `/api/v1/logout/back`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutBackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {string} xBffKey bff key header
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFront: async (redirectUri: string, xBffKey: string, state?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('logoutFront', 'redirectUri', redirectUri)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('logoutFront', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/logout/front`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = redirectUri;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticate: async (xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('reauthenticate', 'xBffKey', xBffKey)
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('reauthenticate', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/api/v1/reauthenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticateV2: async (xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('reauthenticateV2', 'xBffKey', xBffKey)
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            assertParamExists('reauthenticateV2', 'refreshTokenRequest', refreshTokenRequest)
            const localVarPath = `/api/v2/reauthenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webLogoutBack: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('webLogoutBack', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/web-refresh/logout/back`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webReauthenticate: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('webReauthenticate', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/web-refresh/reauthenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationControllerApi - functional programming interface
 * @export
 */
export const AuthenticationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(xBffKey: string, tokenRequest: TokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(xBffKey, tokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {LogoutBackRequest} logoutBackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutBack(xBffKey: string, logoutBackRequest: LogoutBackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutBack(xBffKey, logoutBackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {string} xBffKey bff key header
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutFront(redirectUri: string, xBffKey: string, state?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutFrontResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutFront(redirectUri, xBffKey, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reauthenticate(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reauthenticate(xBffKey, refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reauthenticateV2(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFoundV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reauthenticateV2(xBffKey, refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webLogoutBack(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webLogoutBack(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webReauthenticate(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webReauthenticate(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationControllerApi - factory interface
 * @export
 */
export const AuthenticationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(xBffKey: string, tokenRequest: TokenRequest, options?: any): AxiosPromise<UserAccountFound> {
            return localVarFp.authenticate(xBffKey, tokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {LogoutBackRequest} logoutBackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBack(xBffKey: string, logoutBackRequest: LogoutBackRequest, options?: any): AxiosPromise<object> {
            return localVarFp.logoutBack(xBffKey, logoutBackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {string} xBffKey bff key header
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFront(redirectUri: string, xBffKey: string, state?: string, options?: any): AxiosPromise<LogoutFrontResponse> {
            return localVarFp.logoutFront(redirectUri, xBffKey, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticate(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<UserAccountFound> {
            return localVarFp.reauthenticate(xBffKey, refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticateV2(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<UserAccountFoundV2> {
            return localVarFp.reauthenticateV2(xBffKey, refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webLogoutBack(xBffKey: string, options?: any): AxiosPromise<object> {
            return localVarFp.webLogoutBack(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webReauthenticate(xBffKey: string, options?: any): AxiosPromise<UserAccountFound> {
            return localVarFp.webReauthenticate(xBffKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationControllerApi - object-oriented interface
 * @export
 * @class AuthenticationControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {TokenRequest} tokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public authenticate(xBffKey: string, tokenRequest: TokenRequest, options?: AxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).authenticate(xBffKey, tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {LogoutBackRequest} logoutBackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public logoutBack(xBffKey: string, logoutBackRequest: LogoutBackRequest, options?: AxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).logoutBack(xBffKey, logoutBackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} redirectUri 
     * @param {string} xBffKey bff key header
     * @param {string} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public logoutFront(redirectUri: string, xBffKey: string, state?: string, options?: AxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).logoutFront(redirectUri, xBffKey, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public reauthenticate(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).reauthenticate(xBffKey, refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public reauthenticateV2(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: AxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).reauthenticateV2(xBffKey, refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public webLogoutBack(xBffKey: string, options?: AxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).webLogoutBack(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public webReauthenticate(xBffKey: string, options?: AxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).webReauthenticate(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }
}
