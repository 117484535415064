import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { STANDARD_RADIUS } from '@DS/styles/constants/radius'
import { spacing, SPACING_0, SPACING_1, SPACING_2 } from '@DS/styles/constants/spacing'

export const datesWrapper = (isOutwardOnly = false): SerializedStyles =>
  css({
    cursor: 'pointer',
    display: 'flex',
    height: rem(48),
    maxWidth: isOutwardOnly ? '50%' : '100%',
    minWidth: isOutwardOnly ? rem(217) : undefined,
  })

type DateSearchCriteriaMode = 'inward' | 'outward' | 'singleDate'

const outwardBorderRadius = `${STANDARD_RADIUS} 0 0 ${STANDARD_RADIUS}`
const inwardBorderRadius = `0 ${STANDARD_RADIUS} ${STANDARD_RADIUS} 0`
const singleDateRadius = STANDARD_RADIUS

const DATE_SEARCH_CRITERIA_RADIUS: Record<DateSearchCriteriaMode, string> = {
  inward: inwardBorderRadius,
  outward: outwardBorderRadius,
  singleDate: singleDateRadius,
}

const separatorWidth = spacing(0.25)

export const inwardInput = css({
  marginLeft: separatorWidth,
  maxWidth: `calc(100% - ${separatorWidth})`,
})

export const startIcon = css({
  ...size(16),
  fill: 'currentcolor',
  marginRight: SPACING_0,
})

export const inputDate = (theme: Theme, mode: DateSearchCriteriaMode, disabled?: boolean): SerializedStyles =>
  css(
    !disabled && {
      ':hover': {
        backgroundColor: theme.customPalette.onElements.selected,
      },
    },
    {
      '&:focus-visible': {
        outline: 'none',
      },
      '&:focus-visible::before': {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: DATE_SEARCH_CRITERIA_RADIUS[mode],
        bottom: 0,
        content: '""',
        display: 'block',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      },
      backgroundColor: theme.palette.background.paper,
      border: `2px solid transparent`,
      borderRadius: DATE_SEARCH_CRITERIA_RADIUS[mode],
      color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
      cursor: disabled ? 'default' : 'pointer',
      padding: `${spacing(1.5)} ${spacing(2.25)} ${spacing(1.5)} ${SPACING_2}`,
    }
  )

export const buttonWrapper = (isWrapped = false): SerializedStyles =>
  css({
    flexBasis: '50%',
    flexGrow: 1,
    marginTop: isWrapped ? SPACING_1 : 0,
    position: 'relative',
  })

export const disabledLabel = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.disabled,
  })

export const labelId = (theme: Theme, isDisabled = false): SerializedStyles =>
  css({
    color: isDisabled ? theme.palette.text.disabled : theme.palette.text.secondary,
  })

export const button = (theme: Theme, isFilled: boolean, disabled: boolean | undefined): SerializedStyles =>
  css({
    ':focus': {
      outlineColor: theme.palette.primary.main,
    },
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 0,
    color: isFilled ? theme.palette.text[disabled ? 'disabled' : 'secondary'] : theme.palette.primary.main,
    display: 'flex',
    height: rem(48),
    padding: 0,
    textOverflow: 'ellipsis',
    width: '100%',
  })

export const value = (theme: Theme, disabled: boolean | undefined): SerializedStyles =>
  css({
    color: theme.palette.text[disabled ? 'disabled' : 'primary'],
    marginRight: SPACING_0,
  })

export const removeButton = css({
  position: 'absolute',
  right: SPACING_2,
  top: '50%',
  transform: 'translateY(-50%)',
})
