import { useState } from 'react'
import { v4 as uuid } from 'uuid'

/**
 * TODO https://jira.vsct.fr/browse/IVTS-48675 A déprécier
 *
 * @see {@link ../components/presentational/search/traveler/FavoritePets.tsx:24} for example
 *
 * Ticket de suppression : https://jira.vsct.fr/browse/IVTS-46600
 */
const useUuid = (): string => {
  const [id] = useState(() => uuid())

  return id
}

export default useUuid
