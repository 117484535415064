import { ComponentPropsWithoutRef, CSSProperties, ElementType, ForwardedRef, forwardRef, ReactElement } from 'react'
import { Card, CardProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { CommonCardBackground } from '@DS/components/foundations/shapes/card/types'

import * as commonStyles from '../styles'

import * as styles from './styles'

export type BasicCardProps<Component extends ElementType = 'div'> = Omit<CardProps<Component>, 'children'> & {
  hasError?: boolean
  borderColor?: CSSProperties['borderColor']
  background?: CommonCardBackground
  backgroundColor?: CSSProperties['backgroundColor']
} & ComponentPropsWithoutRef<Component>

const BasicCard = forwardRef(
  <Component extends ElementType>(
    {
      children,
      background = 'paper',
      backgroundColor,
      hasError = false,
      borderColor,
      ...rest
    }: BasicCardProps<Component>,
    ref: ForwardedRef<HTMLDivElement>
  ): ReactElement | null => {
    const theme = useTheme()

    return (
      <Card
        ref={ref}
        {...rest}
        css={[
          backgroundColor ? styles.backgroundColor(backgroundColor) : commonStyles.cardBackground(theme, background),
          borderColor && styles.borderColor(borderColor),
          hasError && styles.borderColor(theme.palette.error.main),
        ]}
      >
        {children}
      </Card>
    )
  }
)

export default BasicCard
