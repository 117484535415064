import { rem } from 'polished'

export const SPACING_BASE = 8

// 4px
export const SPACING_0 = rem(SPACING_BASE / 2)
// 8px
export const SPACING_1 = rem(SPACING_BASE)
// 16px
export const SPACING_2 = rem(SPACING_BASE * 2)
// 24px
export const SPACING_3 = rem(SPACING_BASE * 3)
// 32px
export const SPACING_4 = rem(SPACING_BASE * 4)
// 40px
export const SPACING_5 = rem(SPACING_BASE * 5)
// 48px
export const SPACING_6 = rem(SPACING_BASE * 6)
// 56px
export const SPACING_7 = rem(SPACING_BASE * 7)
// 64px
export const SPACING_8 = rem(SPACING_BASE * 8)
// 72px
export const SPACING_9 = rem(SPACING_BASE * 9)
// 80px
export const SPACING_10 = rem(SPACING_BASE * 10)

/**
 * Multiply a given number by SPACING_BASE ( == 8 ) ({@link https://www.figma.com/file/sYM1vW0ZdHPRIzhrkTSXEn/Composants-DS?node-id=5050%3A162|according to the design system}) and convert the result to rem
 *
 * @param {number} multiplier
 * @returns {string} spacing (in rem)
 */
export const spacing = (multiplier: number): string => rem(multiplier * SPACING_BASE)
