import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { UseQueryResult } from '@tanstack/react-query'
import { AutocompleteRequestUsageEnum, AutocompleteResult } from 'invictus-sdk-typescript'

import { AutocompleteResultModel } from '@DS/components/navigation/search/atoms/AutocompleteResult'
import { AutocompleteSection } from '@DS/components/navigation/search/autocomplete/Autocomplete.types'

import { Autocomplete, AutocompleteOptions, useAutocomplete } from './useAutocomplete'
import { SearchAutocompleteQueryOptions, useSearchAutocompleteQuery } from './useSearchAutocompleteQuery'

export const FULL_TEXT_SUGGESTIONS_AREA = 'fullTextSuggestions_area'
export const PLACES_AREA = 'places_area'

export type SearchAutocompleteOptions = Omit<
  AutocompleteOptions<AutocompleteResultModel>,
  'cancelGetAutocompleteItems'
> & {
  hasToKeepStationsOnly?: boolean
  usage?: AutocompleteRequestUsageEnum
  shouldReturnSuggestions?: boolean
}

export const useSearchAutocomplete: (
  suggestions: AutocompleteSection<AutocompleteResultModel>[],
  options?: SearchAutocompleteOptions
) => Autocomplete<AutocompleteResultModel> = (
  suggestions,
  { hasToKeepStationsOnly = false, usage, shouldReturnSuggestions = false, ...restOptions } = {}
) => {
  const { formatMessage } = useIntl()
  const OLYMPIC_GAMES_POI_PREFIX = 'POI_OG'
  const mapAutocompleteGroupToAutocompleteSections = useCallback(
    ({
      places: { transportPlaces, addresses, pois, commonSearches },
      suggestions: autocompleteSuggestionGroup,
    }: AutocompleteResult): AutocompleteSection<AutocompleteResultModel>[] => [
      ...(shouldReturnSuggestions && autocompleteSuggestionGroup?.fullTextSuggestions
        ? [
            {
              section: 'suggestions',
              area: FULL_TEXT_SUGGESTIONS_AREA,
              title: formatMessage({ id: 'suggestions' }),
              items: autocompleteSuggestionGroup.fullTextSuggestions,
            } satisfies AutocompleteSection<AutocompleteResultModel>,
          ]
        : []),
      {
        section: 'commonSearches',
        area: PLACES_AREA,
        title: formatMessage({ id: 'commonSearches' }),
        items: commonSearches,
      },
      {
        section: 'citiesAndStations',
        area: PLACES_AREA,
        title: formatMessage({ id: 'citiesAndStations' }),
        items: transportPlaces,
      },
      {
        section: 'trends',
        area: PLACES_AREA,
        title: formatMessage({ id: 'trends' }),
        items: pois.filter(({ id }) => id.startsWith(OLYMPIC_GAMES_POI_PREFIX)),
      },
      {
        section: 'addresses',
        area: PLACES_AREA,
        title: formatMessage({ id: 'addresses' }),
        items: addresses,
      },
      {
        section: 'pois',
        area: PLACES_AREA,
        title: formatMessage({ id: 'pois' }),
        items: pois.filter(({ id }) => !id.startsWith(OLYMPIC_GAMES_POI_PREFIX)),
      },
    ],
    [shouldReturnSuggestions, formatMessage]
  )

  const useWrapSearchAutocompleteQuery = (
    inputValue: string,
    options?: SearchAutocompleteQueryOptions<AutocompleteSection<AutocompleteResultModel>[]>
  ): UseQueryResult<AutocompleteSection<AutocompleteResultModel>[]> => {
    const overriddenOptions = useMemo(
      () => ({
        ...options,
        select: (data: AutocompleteResult) => mapAutocompleteGroupToAutocompleteSections(data),
      }),
      [options]
    )

    return useSearchAutocompleteQuery(inputValue, overriddenOptions, {
      keepStationsOnly: hasToKeepStationsOnly,
      usage,
      returnsSuggestions: shouldReturnSuggestions,
    })
  }

  return useAutocomplete(useWrapSearchAutocompleteQuery, suggestions, restOptions)
}
