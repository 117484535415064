import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem } from 'polished'

import { STANDARD_RADIUS } from '@DS/styles/constants/radius'
import { SPACING_1, SPACING_2 } from '@DS/styles/constants/spacing'

export const overridesTooltip = (theme: Theme): ComponentsOverrides['MuiTooltip'] => ({
  arrow: {
    color: theme.palette.text.secondary,
    fontSize: rem(18),
    width: rem(18),
    '&::before': {
      backgroundColor: theme.customPalette.alternative.onAlternativeColor1,
      border: 0,
    },
  },
  tooltip: {
    backgroundColor: theme.customPalette.invertedColors.invertedColorsBackground,
    border: 0,
    borderRadius: STANDARD_RADIUS,
    color: theme.customPalette.invertedColors.invertedColorsOnBackground,
    padding: 0,
    pointerEvents: 'all',
  },
  tooltipPlacementTop: {
    margin: `${SPACING_2} 0`,
    [theme.breakpoints.up('sm')]: {
      margin: `${SPACING_2} 0`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `${SPACING_2} 0`,
    },
    [theme.breakpoints.up('lg')]: {
      margin: `${SPACING_2} 0`,
    },
    [theme.breakpoints.up('xl')]: {
      margin: `${SPACING_2} 0`,
    },
  },
  tooltipPlacementBottom: {
    margin: `${SPACING_1} 0 0 ${rem(-3)}`,
    [theme.breakpoints.up('sm')]: {
      margin: `${SPACING_1} 0 0 ${rem(-3)}`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `${SPACING_1} 0 0 ${rem(-3)}`,
    },
    [theme.breakpoints.up('lg')]: {
      margin: `${SPACING_1} 0 0 ${rem(-3)}`,
    },
    [theme.breakpoints.up('xl')]: {
      margin: `${SPACING_1} 0 0 ${rem(-3)}`,
    },
  },
})
