import { CSSObject } from '@mui/material'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

import { HALF_RADIUS } from '@DS/styles/constants/radius'
import { typographyOverrides } from '@Material/typography'

export const overridesOutlinedInput = (theme: Theme): ComponentsOverrides['MuiOutlinedInput'] => ({
  root: {
    borderRadius: HALF_RADIUS,
  },
  input: {
    ...(typographyOverrides(theme)?.body2 as CSSObject),
    [`&.${outlinedInputClasses.disabled}`]: {
      backgroundColor: theme.palette.background.default,
      border: `solid 1px ${theme.palette.background.default}`,
    },
  },
  multiline: {
    // Pour être ISO Mui v4
    padding: '18.5px 14px',
  },
})
