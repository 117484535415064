// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const LuggageBikeCover = svgLazyLoader('luggage/luggage_bike_cover')
const LuggageLarge = svgLazyLoader('luggage/luggage_large')
const LuggageMedium = svgLazyLoader('luggage/luggage_medium')
const LuggageSmall = svgLazyLoader('luggage/luggage_small')

const ALL_LUGGAGE_ICONS = ['luggage_bike_cover', 'luggage_large', 'luggage_medium', 'luggage_small'] as const
export type LuggageIcon = (typeof ALL_LUGGAGE_ICONS)[number]

const isLuggageIcon = (luggageKey: string): luggageKey is LuggageIcon =>
  ALL_LUGGAGE_ICONS.includes(luggageKey as LuggageIcon)

const ICONS: LazyIconsGroup<LuggageIcon> = {
  luggage_bike_cover: LuggageBikeCover,
  luggage_large: LuggageLarge,
  luggage_medium: LuggageMedium,
  luggage_small: LuggageSmall,
}

export const getLuggageIcon = (luggageKey: string | undefined): LazyIcon | undefined =>
  luggageKey && isLuggageIcon(luggageKey) ? ICONS[luggageKey] : undefined

export const getLuggageIcons = (): LazyIconsGroup<LuggageIcon> => ICONS
