import { RefObject, useLayoutEffect, useRef, useState } from 'react'

type ButtonsGroupReturn = {
  overriddenWidth?: number
  firstButtonRef: RefObject<HTMLButtonElement | HTMLAnchorElement>
  secondButtonRef: RefObject<HTMLButtonElement | HTMLAnchorElement>
}

const useButtonsGroup = (): ButtonsGroupReturn => {
  const firstButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)
  const secondButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null)

  // Width is overridden on second button when one button has longer width
  const [overriddenWidth, setOverriddenWidth] = useState<number | undefined>()

  useLayoutEffect(() => {
    if (!firstButtonRef.current || !secondButtonRef.current) {
      return
    }

    const firstButtonWidth = Math.ceil(parseFloat(getComputedStyle(firstButtonRef.current).width))
    const secondButtonWidth = Math.ceil(parseFloat(getComputedStyle(secondButtonRef.current).width))

    if (firstButtonWidth !== secondButtonWidth) {
      const biggestWidth = Math.max(firstButtonWidth, secondButtonWidth)
      setOverriddenWidth(biggestWidth)
    }
  }, [firstButtonRef, secondButtonRef])

  return {
    overriddenWidth,
    firstButtonRef,
    secondButtonRef,
  }
}

export default useButtonsGroup
