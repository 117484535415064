import { CSSProperties } from 'react'
import { Theme } from '@mui/material/styles'

import { SeverityType } from '@DS/types/severity'

export const getColorBySeverity = (
  theme: Theme,
  severity: SeverityType,
  hasWhiteBackground = false
): CSSProperties['color'] => {
  switch (severity) {
    case 'alert':
    case 'train_delete':
    case 'warning_red':
      return hasWhiteBackground ? theme.customPalette.onElements.elevated : theme.palette.error.main
    case 'delay':
    case 'roadworks':
    case 'warning':
      return theme.palette.warning.main
    case 'positive':
      return theme.palette.success.main
    case 'service':
      return theme.customPalette.alternative.service
    case 'insurance':
      return theme.customPalette.invertedColors.invertedColorsBackground
    case 'info_message':
    default:
      return theme.customPalette.alternative.info
  }
}

export const getBackgroundColorBySeverity = (theme: Theme, severity: SeverityType): CSSProperties['color'] => {
  switch (severity) {
    case 'alert':
    case 'train_delete':
    case 'warning_red':
      return theme.customPalette.alternative.ivErrorBackground
    case 'delay':
    case 'roadworks':
    case 'warning':
      return theme.customPalette.alternative.ivWarningBackground
    case 'positive':
      return theme.customPalette.alternative.ivSuccessBackground
    case 'insurance':
      return theme.customPalette.alternative.ivNeutralBackground
    case 'info_message':
    default:
      return theme.customPalette.alternative.ivInfoBackground
  }
}
