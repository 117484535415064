import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const overridesDialog = (): ComponentsOverrides['MuiDialog'] => ({
  paperScrollPaper: {
    overflowY: 'hidden',
  },
})

export const overridesDialogContent = (): ComponentsOverrides['MuiDialogContent'] => ({
  root: {
    padding: 0,
  },
})

export const overridesDialogActions = (theme: Theme): ComponentsOverrides['MuiDialogActions'] => ({
  root: {
    backgroundColor: theme.palette.background.default,
    justifyContent: 'center',
  },
})
