import { FC, ReactElement, ReactNode } from 'react'

type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children?: ReactNode) => ReactElement
  defaultWrapper?: (children?: ReactNode) => ReactElement
  children?: ReactNode
}

const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, wrapper, defaultWrapper, children }) => {
  if (condition) {
    return wrapper(children)
  }

  if (defaultWrapper) {
    return defaultWrapper(children)
  }

  return <>{children}</>
}

export default ConditionalWrapper
