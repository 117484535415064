import {
  FC,
  ForwardedRef,
  forwardRef,
  Fragment,
  MutableRefObject,
  ReactElement,
  RefAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import { Paper, PaperProps, Typography } from '@mui/material'
import { ThemeProvider, useTheme } from '@mui/material/styles'
import { rem } from 'polished'

import { remToNumberOfPixels } from '@DS/utils/units'
import { useIsMediumUp } from '@Hooks/useResponsive'
import { lightTheme } from '@Material/theme'
import { TABBAR_HEIGHT } from '@Styles/constants/layout'

import { AutocompleteBaseProps, AutocompleteItemModel, AutocompleteItemProps } from './Autocomplete.types'
import * as styles from './styles'

type GenericAutocompleteProps<AutocompleteItemModelType extends AutocompleteItemModel> = Omit<
  AutocompleteBaseProps<AutocompleteItemModelType>,
  'autocompleteRef'
> & {
  AutocompleteItemComponent: FC<AutocompleteItemProps<AutocompleteItemModelType> & RefAttributes<HTMLDivElement>>
}

const HiddenAutocompleteDescription: FC = () => (
  <div hidden id="autocomplete_input_visuallyHiddenLabel">
    <FormattedMessage id="autocomplete_input_visuallyHiddenLabel" />
  </div>
)

const BOTTOM_SCREEN_GAP = rem(40)

const GenericAutocomplete = <AutocompleteItemModelType extends AutocompleteItemModel>(
  {
    AutocompleteItemComponent,
    children,
    shouldOpen = false,
    onOpen,
    onClose,
    onSelectItem,
    onMouseMove,
    onItemFocus,
    onItemHover,
    highlighted,
    sections,
    itemRef,
    ...rest
  }: GenericAutocompleteProps<AutocompleteItemModelType> & Omit<PaperProps, 'itemRef'>,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false)
  const [maxHeight, setMaxHeight] = useState<number | undefined>()
  const theme = useTheme()
  const isMediumUp = useIsMediumUp(theme)

  const computeAutocompleteAvailableHeight = useCallback(() => {
    if (!ref) {
      return
    }
    const { current } = ref as MutableRefObject<HTMLDivElement | null>

    const tabBarHeight = isMediumUp ? 0 : remToNumberOfPixels(TABBAR_HEIGHT)

    if (current?.parentElement) {
      setMaxHeight(
        window.innerHeight -
          current.parentElement.getBoundingClientRect().top -
          remToNumberOfPixels(BOTTOM_SCREEN_GAP) -
          tabBarHeight
      )
    }
  }, [isMediumUp, ref])

  useEffect(() => {
    if (isOpen) {
      computeAutocompleteAvailableHeight()
    }
  }, [computeAutocompleteAvailableHeight, isMediumUp, isOpen, ref])

  useEffect(() => {
    setIsOpen((currentIsOpen) => {
      const nextIsOpen = shouldOpen && (sections.length > 0 || !!children)

      if (!currentIsOpen && nextIsOpen) {
        onOpen?.()
      } else if (currentIsOpen && !nextIsOpen) {
        onClose?.()
      }

      return nextIsOpen
    })
  }, [shouldOpen, sections.length, children, onOpen, onClose])

  if (!isOpen) {
    return <HiddenAutocompleteDescription />
  }

  return (
    <>
      <HiddenAutocompleteDescription />
      <ThemeProvider theme={lightTheme}>
        <Paper
          {...rest}
          css={styles.autocomplete(theme, maxHeight)}
          elevation={0}
          ref={ref}
          tabIndex={-1}
          role="listbox"
          onMouseMove={() => onMouseMove?.()}
        >
          {children}
          {sections.map(({ section: sectionId, title, items }, index) => (
            <Fragment key={title}>
              {title && (
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  css={[styles.sectionTitle(theme), index === 0 && !children && styles.sectionTitleFirstChild]}
                  data-test={sectionId}
                >
                  {title}
                </Typography>
              )}
              {items.map((item, itemIndex) => {
                const { id } = item
                const isHighlighted = item === highlighted || id === highlighted?.id

                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <div
                    data-cs-override-id={`search_suggestions-${sectionId}-${itemIndex + 1}`}
                    data-cs-mask={sectionId === 'addresses' || sectionId === 'pois'}
                    key={id}
                    id={id}
                    data-test={id}
                    role="option"
                    aria-selected={isHighlighted}
                    onClick={() => onSelectItem?.(item)}
                    onMouseOver={() => onItemHover?.(item)}
                    onFocus={() => onItemFocus?.(item)}
                    tabIndex={-1}
                  >
                    <AutocompleteItemComponent
                      isHighlighted={isHighlighted}
                      ref={isHighlighted ? itemRef : undefined}
                      model={item}
                    />
                  </div>
                )
              })}
            </Fragment>
          ))}
        </Paper>
      </ThemeProvider>
    </>
  )
}

export default forwardRef(GenericAutocomplete)
