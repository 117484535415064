import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem } from 'polished'

export const brandIcon = (theme: Theme): SerializedStyles =>
  css({
    height: rem(48),
    [theme.breakpoints.up('md')]: {
      height: rem(64),
    },
  })
