import { createTheme as createMuiTheme, responsiveFontSizes, Theme } from '@mui/material/styles'

import { STANDARD_RADIUS } from '@DS/styles/constants/radius'
import { remToNumberOfPixels } from '@DS/utils/units'
import { overridesSnackbar } from '@Material/snackbar'
import canUseDom from '@Utils/can-use-dom'

import { overridesAccordion, overridesAccordionDetails, overridesAccordionSummary } from './accordion'
import { overridesAppBar } from './appBar'
import { overridesBackdrop } from './backdrop'
import { overridesBottomNavigation, overridesBottomNavigationAction } from './bottomNavigation'
import { overridesButton, overridesButtonBase } from './button'
import { overridesCard, overridesCardActionArea, overridesCardContent } from './card'
import { overridesCheckbox } from './checkbox'
import { overridesCssBaseLine } from './cssbaseline'
import { customPalette } from './customPalette'
import { overridesDialog, overridesDialogActions, overridesDialogContent } from './dialog'
import { overridesDivider } from './divider'
import { overridesDrawer } from './drawer'
import { overridesFormLabel } from './formLabel'
import { overridesIconButton } from './iconButton'
import { overridesInputLabel } from './inputLabel'
import { overridesListItem } from './listItem'
import { overridesMenuItem } from './menuItem'
import { overridesOutlinedInput } from './outlinedInput'
import { palette } from './palette'
import { overridesRadio } from './radio'
import { overridesSelect } from './select'
import { overridesSkeleton } from './skeleton'
import { overridesSlider } from './slider'
import { overridesSwitch } from './switch'
import { overridesTab, overridesTabs } from './tabs'
import { overridesTextField } from './textField'
import { overridesTooltip } from './tooltip'
import { globalTypography, typographyOverrides as overridesTypography } from './typography'

export const createTheme = (themeOptions: { hasDarkMode: boolean; hasProMode: boolean }): Theme => {
  const baseTheme = createMuiTheme({
    palette: palette(themeOptions),
    ...customPalette(themeOptions),
    typography: globalTypography,
    shadows: [
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    shape: {
      borderRadius: remToNumberOfPixels(STANDARD_RADIUS),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1024,
        xl: 1440,
      },
    },
  })

  const theme = createMuiTheme(baseTheme, {
    components: {
      MuiAccordion: {
        styleOverrides: overridesAccordion(),
      },
      MuiAccordionSummary: {
        styleOverrides: overridesAccordionSummary(),
      },
      MuiAccordionDetails: {
        styleOverrides: overridesAccordionDetails(),
      },
      MuiAppBar: {
        styleOverrides: overridesAppBar(baseTheme),
      },
      MuiBackdrop: {
        styleOverrides: overridesBackdrop(baseTheme),
      },
      MuiBottomNavigation: {
        styleOverrides: overridesBottomNavigation(baseTheme),
      },
      MuiBottomNavigationAction: {
        styleOverrides: overridesBottomNavigationAction(baseTheme),
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: overridesButton(baseTheme),
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: overridesButtonBase(baseTheme),
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: overridesIconButton(),
      },
      MuiCard: {
        styleOverrides: overridesCard(),
      },
      MuiCardActionArea: {
        styleOverrides: overridesCardActionArea(baseTheme),
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiCardContent: {
        styleOverrides: overridesCardContent(),
      },
      MuiCheckbox: {
        styleOverrides: overridesCheckbox(baseTheme),
      },
      MuiCssBaseline: {
        styleOverrides: overridesCssBaseLine(),
      },
      MuiDialog: {
        styleOverrides: overridesDialog(),
      },
      MuiDialogActions: {
        styleOverrides: overridesDialogActions(baseTheme),
      },
      MuiDialogContent: {
        styleOverrides: overridesDialogContent(),
      },
      MuiDivider: {
        styleOverrides: overridesDivider(),
      },
      MuiDrawer: {
        styleOverrides: overridesDrawer(),
      },
      MuiInputLabel: {
        styleOverrides: overridesInputLabel(baseTheme),
      },
      MuiFormLabel: {
        styleOverrides: overridesFormLabel(baseTheme),
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiListItem: {
        styleOverrides: overridesListItem(baseTheme),
      },
      MuiMenuItem: {
        styleOverrides: overridesMenuItem(baseTheme),
      },
      MuiOutlinedInput: {
        styleOverrides: overridesOutlinedInput(baseTheme),
      },
      MuiRadio: {
        styleOverrides: overridesRadio(baseTheme),
        defaultProps: {
          color: 'primary',
          disableRipple: true,
        },
      },
      MuiSelect: {
        styleOverrides: overridesSelect(),
      },
      MuiSkeleton: {
        styleOverrides: overridesSkeleton(),
      },
      MuiSlider: {
        styleOverrides: overridesSlider(baseTheme),
      },
      MuiSwitch: {
        styleOverrides: overridesSwitch(baseTheme),
      },
      MuiTab: {
        styleOverrides: overridesTab(baseTheme),
      },
      MuiTabs: {
        styleOverrides: overridesTabs(baseTheme),
      },
      MuiTextField: {
        styleOverrides: overridesTextField(),
      },
      MuiTooltip: {
        styleOverrides: overridesTooltip(baseTheme),
      },
      MuiSnackbar: {
        styleOverrides: overridesSnackbar(baseTheme),
      },
      MuiTypography: {
        defaultProps: {
          variant: 'body2',
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            subtitle1: 'p',
            subtitle2: 'p',
            body1: 'p',
            body2: 'p',
          },
        },
        styleOverrides: overridesTypography(baseTheme),
      },
    },
  })

  if (canUseDom) {
    window.theme = theme
  }

  return responsiveFontSizes(theme)
}
