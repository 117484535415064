import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import { useTheme } from '@mui/material/styles'

import * as styles from './styles'

type ScrollableContentProps = {
  hasNoContentSpacing?: boolean
  children: ReactNode
} & HTMLAttributes<HTMLDivElement>

// ScrollableContent is used to have a scrollable content in a ModalPage. No story needed
const ScrollableContent = forwardRef<HTMLDivElement, ScrollableContentProps>(
  ({ hasNoContentSpacing = false, children, ...rest }, ref) => {
    const theme = useTheme()

    return (
      <div {...rest} ref={ref} css={styles.scrollableContent}>
        <div css={styles.content(theme, hasNoContentSpacing)} tabIndex={-1}>
          {children}
        </div>
      </div>
    )
  }
)

export default ScrollableContent
