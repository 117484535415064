/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LocationTrackerRequest } from '../model';
// @ts-ignore
import { TrackedEventRequest } from '../model';
// @ts-ignore
import { TrackedFrontError } from '../model';
// @ts-ignore
import { TrackingIdfmOrderRequest } from '../model';
// @ts-ignore
import { TrackingRequest } from '../model';
// @ts-ignore
import { TrackingResponseSuccess } from '../model';
// @ts-ignore
import { TrackingUpdateConsentRequest } from '../model';
// @ts-ignore
import { TrackingVendorsResponseSuccess } from '../model';
/**
 * TrackingControllerApi - axios parameter creator
 * @export
 */
export const TrackingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get vendors consents to track server-side
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS2sVendors: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('getS2sVendors', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/t/vendors/s2s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track error
         * @param {string} type 
         * @param {string} xBffKey bff key header
         * @param {TrackedFrontError} trackedFrontError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackError: async (type: string, xBffKey: string, trackedFrontError: TrackedFrontError, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('trackError', 'type', type)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('trackError', 'xBffKey', xBffKey)
            // verify required parameter 'trackedFrontError' is not null or undefined
            assertParamExists('trackError', 'trackedFrontError', trackedFrontError)
            const localVarPath = `/api/v1/t/errors/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackedFrontError, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track generic events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEvents: async (xBffKey: string, trackedEventRequest: TrackedEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('trackEvents', 'xBffKey', xBffKey)
            // verify required parameter 'trackedEventRequest' is not null or undefined
            assertParamExists('trackEvents', 'trackedEventRequest', trackedEventRequest)
            const localVarPath = `/api/v1/t/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackedEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track a generic idfm confirmation order
         * @param {string} xBffKey bff key header
         * @param {TrackingIdfmOrderRequest} trackingIdfmOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackIdfmOrder: async (xBffKey: string, trackingIdfmOrderRequest: TrackingIdfmOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('trackIdfmOrder', 'xBffKey', xBffKey)
            // verify required parameter 'trackingIdfmOrderRequest' is not null or undefined
            assertParamExists('trackIdfmOrder', 'trackingIdfmOrderRequest', trackingIdfmOrderRequest)
            const localVarPath = `/api/v1/t/idfm/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackingIdfmOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track a generic page view
         * @param {string} pageName 
         * @param {string} xBffKey bff key header
         * @param {TrackingRequest} trackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPage: async (pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageName' is not null or undefined
            assertParamExists('trackPage', 'pageName', pageName)
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('trackPage', 'xBffKey', xBffKey)
            // verify required parameter 'trackingRequest' is not null or undefined
            assertParamExists('trackPage', 'trackingRequest', trackingRequest)
            const localVarPath = `/api/v1/t/pages/{pageName}`
                .replace(`{${"pageName"}}`, encodeURIComponent(String(pageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track positions
         * @param {string} xBffKey bff key header
         * @param {LocationTrackerRequest} locationTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPositions: async (xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('trackPositions', 'xBffKey', xBffKey)
            // verify required parameter 'locationTrackerRequest' is not null or undefined
            assertParamExists('trackPositions', 'locationTrackerRequest', locationTrackerRequest)
            const localVarPath = `/api/v1/t/events/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationTrackerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track gesture and lifecycle events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackUserActionEvent: async (xBffKey: string, trackedEventRequest: TrackedEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('trackUserActionEvent', 'xBffKey', xBffKey)
            // verify required parameter 'trackedEventRequest' is not null or undefined
            assertParamExists('trackUserActionEvent', 'trackedEventRequest', trackedEventRequest)
            const localVarPath = `/api/v1/t/events/user-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackedEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary persists user consents
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsents: async (xBffKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('updateConsents', 'xBffKey', xBffKey)
            const localVarPath = `/api/v1/t/consentedVendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update previously-tracked events with consents
         * @param {string} xBffKey bff key header
         * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithConsent: async (xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            assertParamExists('updateWithConsent', 'xBffKey', xBffKey)
            // verify required parameter 'trackingUpdateConsentRequest' is not null or undefined
            assertParamExists('updateWithConsent', 'trackingUpdateConsentRequest', trackingUpdateConsentRequest)
            const localVarPath = `/api/v1/t/update-consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey != null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackingUpdateConsentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingControllerApi - functional programming interface
 * @export
 */
export const TrackingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get vendors consents to track server-side
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getS2sVendors(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingVendorsResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getS2sVendors(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Track error
         * @param {string} type 
         * @param {string} xBffKey bff key header
         * @param {TrackedFrontError} trackedFrontError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackError(type: string, xBffKey: string, trackedFrontError: TrackedFrontError, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackError(type, xBffKey, trackedFrontError, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Track generic events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackEvents(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackEvents(xBffKey, trackedEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Track a generic idfm confirmation order
         * @param {string} xBffKey bff key header
         * @param {TrackingIdfmOrderRequest} trackingIdfmOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackIdfmOrder(xBffKey: string, trackingIdfmOrderRequest: TrackingIdfmOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackIdfmOrder(xBffKey, trackingIdfmOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Track a generic page view
         * @param {string} pageName 
         * @param {string} xBffKey bff key header
         * @param {TrackingRequest} trackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackPage(pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackPage(pageName, xBffKey, trackingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Track positions
         * @param {string} xBffKey bff key header
         * @param {LocationTrackerRequest} locationTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackPositions(xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackPositions(xBffKey, locationTrackerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Track gesture and lifecycle events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackUserActionEvent(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackUserActionEvent(xBffKey, trackedEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary persists user consents
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConsents(xBffKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConsents(xBffKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update previously-tracked events with consents
         * @param {string} xBffKey bff key header
         * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithConsent(xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWithConsent(xBffKey, trackingUpdateConsentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackingControllerApi - factory interface
 * @export
 */
export const TrackingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get vendors consents to track server-side
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS2sVendors(xBffKey: string, options?: any): AxiosPromise<TrackingVendorsResponseSuccess> {
            return localVarFp.getS2sVendors(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track error
         * @param {string} type 
         * @param {string} xBffKey bff key header
         * @param {TrackedFrontError} trackedFrontError 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackError(type: string, xBffKey: string, trackedFrontError: TrackedFrontError, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return localVarFp.trackError(type, xBffKey, trackedFrontError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track generic events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEvents(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return localVarFp.trackEvents(xBffKey, trackedEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track a generic idfm confirmation order
         * @param {string} xBffKey bff key header
         * @param {TrackingIdfmOrderRequest} trackingIdfmOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackIdfmOrder(xBffKey: string, trackingIdfmOrderRequest: TrackingIdfmOrderRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return localVarFp.trackIdfmOrder(xBffKey, trackingIdfmOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track a generic page view
         * @param {string} pageName 
         * @param {string} xBffKey bff key header
         * @param {TrackingRequest} trackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPage(pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return localVarFp.trackPage(pageName, xBffKey, trackingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track positions
         * @param {string} xBffKey bff key header
         * @param {LocationTrackerRequest} locationTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPositions(xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return localVarFp.trackPositions(xBffKey, locationTrackerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track gesture and lifecycle events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackUserActionEvent(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return localVarFp.trackUserActionEvent(xBffKey, trackedEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary persists user consents
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConsents(xBffKey: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateConsents(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update previously-tracked events with consents
         * @param {string} xBffKey bff key header
         * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithConsent(xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return localVarFp.updateWithConsent(xBffKey, trackingUpdateConsentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackingControllerApi - object-oriented interface
 * @export
 * @class TrackingControllerApi
 * @extends {BaseAPI}
 */
export class TrackingControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get vendors consents to track server-side
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public getS2sVendors(xBffKey: string, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).getS2sVendors(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track error
     * @param {string} type 
     * @param {string} xBffKey bff key header
     * @param {TrackedFrontError} trackedFrontError 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackError(type: string, xBffKey: string, trackedFrontError: TrackedFrontError, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).trackError(type, xBffKey, trackedFrontError, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track generic events
     * @param {string} xBffKey bff key header
     * @param {TrackedEventRequest} trackedEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackEvents(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).trackEvents(xBffKey, trackedEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track a generic idfm confirmation order
     * @param {string} xBffKey bff key header
     * @param {TrackingIdfmOrderRequest} trackingIdfmOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackIdfmOrder(xBffKey: string, trackingIdfmOrderRequest: TrackingIdfmOrderRequest, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).trackIdfmOrder(xBffKey, trackingIdfmOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track a generic page view
     * @param {string} pageName 
     * @param {string} xBffKey bff key header
     * @param {TrackingRequest} trackingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackPage(pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).trackPage(pageName, xBffKey, trackingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track positions
     * @param {string} xBffKey bff key header
     * @param {LocationTrackerRequest} locationTrackerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackPositions(xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).trackPositions(xBffKey, locationTrackerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track gesture and lifecycle events
     * @param {string} xBffKey bff key header
     * @param {TrackedEventRequest} trackedEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackUserActionEvent(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).trackUserActionEvent(xBffKey, trackedEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary persists user consents
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public updateConsents(xBffKey: string, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).updateConsents(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update previously-tracked events with consents
     * @param {string} xBffKey bff key header
     * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public updateWithConsent(xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options?: AxiosRequestConfig) {
        return TrackingControllerApiFp(this.configuration).updateWithConsent(xBffKey, trackingUpdateConsentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
