import { FC, useState } from 'react'
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form'

import { humanDateFormat } from '@Constants/date'
import InputDate, { InputDateProps } from '@DS/components/forms/input/date/InputDate'
import { humanDateToIsoDate, isoDateToHumanDate } from '@Utils/date'

type Control = UseControllerProps<Record<string, InputDateProps['value']>, string>
export type ControlInputDateProps = Omit<InputDateProps, 'name' | 'value' | 'onChange'> &
  Partial<Pick<InputDateProps, 'onChange'>> &
  Control

const ControlInputDate: FC<ControlInputDateProps> = ({
  defaultValue,
  name,
  rules,
  onClear,
  onChange,
  dateFormat = humanDateFormat,
  label,
  ...rest
}) => {
  const { control, setValue } = useFormContext()
  const [updateClear, setUpdateClear] = useState(0)

  const onClearInput = () => {
    setValue(name, '')
    setUpdateClear((prevUpdateClear) => prevUpdateClear + 1)
    onClear?.()
  }

  const isHumanFormattedDate = dateFormat === humanDateFormat

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { value: fieldValue, onChange: onFieldChange, ref }, fieldState: { error } }) => (
        <InputDate
          key={updateClear}
          {...rest}
          ref={ref}
          onChange={(value) => {
            const formattedDate = isHumanFormattedDate ? humanDateToIsoDate(value) : value
            onFieldChange(formattedDate)
            onChange?.(formattedDate)
          }}
          aria-required={!!rules?.required}
          label={`${label}${rules?.required ? '*' : ''}`}
          onClear={onClearInput}
          name={name}
          error={!!error}
          aria-invalid={!!error}
          value={isHumanFormattedDate ? isoDateToHumanDate(fieldValue) : fieldValue}
          helperText={!!error && error.message}
          dateFormat={dateFormat}
        />
      )}
    />
  )
}

export default ControlInputDate
