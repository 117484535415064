import { menuItemClasses } from '@mui/material/MenuItem'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

import { highContrast } from '@Styles/a11y'

export const overridesMenuItem = (theme: Theme): ComponentsOverrides['MuiMenuItem'] => ({
  root: {
    [`&.${menuItemClasses.selected}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    [`&.${menuItemClasses.focusVisible}`]: highContrast({
      border: 'solid 2px transparent',
    }),
    [`&.${menuItemClasses.selected}:hover`]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
})
