import { ComponentPropsWithoutRef, ElementType, ReactElement } from 'react'

import { ReactComponent as ExternalLinkIcon } from '@Svg/navigationAndAction/external_link.svg'

import LinkBase from '../_internal/LinkBase'
import { LinkBaseProps } from '../_internal/LinkBase.types'

type ExternalLinkProps<Component extends ElementType = 'a'> = Omit<
  LinkBaseProps<Component>,
  'underline' | 'locale' | 'isNextRoute' | 'isInline' | 'hasDefaultFocusVisible' | 'isExternal' | 'target' | 'icon'
> & {
  component?: Component
  isInText?: boolean
  hasNoSpacing?: boolean
} & ComponentPropsWithoutRef<Component>

const ExternalLink = <Component extends ElementType = 'a'>({
  isInText = false,
  children,
  hasNoSpacing = false, // TODO IVTS-37424 corriger l'erreur de surcharge
  ...rest
}: ExternalLinkProps<Component>): ReactElement | null => (
  <LinkBase
    {...rest}
    hasNoSpacing={isInText || hasNoSpacing}
    hasDefaultFocusVisible={isInText}
    underline={isInText ? 'always' : 'none'}
    icon={ExternalLinkIcon}
    isInline={isInText}
    isExternal
  >
    {children}
  </LinkBase>
)

export default ExternalLink
