import { useCallback } from 'react'

import canUseDom from '@Utils/can-use-dom'
// TODO IVTS-63537: [WEB] Créer un utilitaire générique pour les (Session/Local)Storage

type UseLocalStorageReturn<ValueType> = {
  set: (value: ValueType) => void
  get: () => ValueType | undefined
  remove: VoidFunction
}

export const getFromLocalStorage = <ValueType>(key: string): ValueType | undefined => {
  if (canUseDom) {
    const value = localStorage.getItem(key)

    if (
      value &&
      (/^"/.test(value) ||
        /^{"/.test(value) ||
        /^\[{"/.test(value) ||
        /^true|false/.test(value) ||
        /^[-]?[0-9]*[.]?[0-9]+/.test(value))
    ) {
      return JSON.parse(value)
    }

    return (value as ValueType) || undefined
  }

  return undefined
}

export const setToLocalStorage = <ValueType>(key: string, value: ValueType): void => {
  if (canUseDom) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

const useLocalStorage = <ValueType>(key: string, initialValue?: ValueType): UseLocalStorageReturn<ValueType> => {
  const set = useCallback((value: ValueType) => setToLocalStorage(key, value), [key])

  const remove = useCallback(() => {
    if (canUseDom) {
      localStorage.removeItem(key)
    }
  }, [key])

  const get = useCallback(() => getFromLocalStorage<ValueType>(key) || initialValue, [key, initialValue])

  return { remove, set, get } as UseLocalStorageReturn<ValueType>
}

export { useLocalStorage }
