export const uniqWith = <Item>(arr: Item[], fn: (item: Item, step: Item) => boolean): Item[] =>
  arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index)

export const uniqBy = <Item, Value>(arr: Item[], fn: (item: Item) => Value): Item[] =>
  uniqWith(arr, (item, step) => fn(item) === fn(step))

export const uniqByKey = <Item>(arr: Item[], key: keyof Item): Item[] => uniqBy(arr, (item) => item[key])

export const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()

export const kebabCase = (value: string): string => value.replace(/\s+/g, '-').replace(/-+/g, '-').toLowerCase()

export const pascalCase = (value: string): string =>
  value
    .replace(/[-_]+$/g, '')
    .replace(/[-_]+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(/\s+(.)(\w*)/g, ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
    .replace(/\w/, (s) => s.toUpperCase())

// different of capitalize, this function only capitalize the first letter of a sentence
export const capitalizeFirstLetterOfSentence = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1)

export const cloneObject = <ObjectType>(obj: ObjectType): ObjectType => JSON.parse(JSON.stringify(obj))

export const removeDiacritics = (value: string): string => value.normalize('NFD').replace(/\p{Diacritic}/gu, '')

export const isNotEmpty = <ValueType>(value: ValueType | null | undefined): value is ValueType => value != null
