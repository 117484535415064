import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'
import { rem } from 'polished'

import { RADIO_PADDING_VALUE, RADIO_SPACING_VALUE } from './radio'

export const overridesCheckbox = (theme: Theme): ComponentsOverrides['MuiCheckbox'] => ({
  root: {
    // Zone de tap = taille radio (24) + padding (12 * 2) = 48 -> conforme recommandation accessibilité
    // Espacement visuel = padding (12) + margin (-4) = 8 -> conforme DS
    margin: rem(RADIO_SPACING_VALUE - RADIO_PADDING_VALUE),
    padding: rem(RADIO_PADDING_VALUE),
    color: theme.customPalette.onElements.outline,
  },
})
