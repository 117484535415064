import type { RawAxiosRequestHeaders } from 'axios'

import { AbstractSdk, type AbstractSdkConstructorParameters, type ControllerApiConstructor } from '../abstract'
import { VisitorType } from '../enums'
import {
  type MetadataContent,
  type ShortItinerary,
  type TrackedEvent,
  type TrackedEventEventTypeEnum,
  type TrackedEventRequest,
  type TrackedFrontError,
  TrackingControllerApi,
  type TrackingRequest,
  type TrackingResponseSuccess,
  type TrackingUpdateConsentRequest,
} from '../generated'
import { type AugmentedAxiosRequestConfig } from '../interceptors.types'
import {
  CONSENT_ID_STORAGE,
  CONSENT_VENDORS_ALL_STORAGE,
  CONSENT_VENDORS_FILTER_STORAGE,
  EVENT_TO_CONSENT_STORAGE,
  EVENTS_CLICK_STORAGE,
  EVENTS_PUSH_STORAGE,
  FIRST_VISIT_STORAGE,
  NAVIGATION_PREVIOUS_STORAGE,
  type PreviousData,
  SEARCH_USAGE_STORAGE,
  type StorageSdk,
  VISITOR_TYPE_STORAGE,
} from '../storage'

import { FirstVisitData, FirstVisitDataMapper, type VisitorTypeData, VisitorTypeDataMapper } from './expirableData'

export enum PushEvent {
  PushTableauDesGares = 'PushTableauDesGares',
  PushItinerairesEnregistres = 'PushItinerairesEnregistres',
  PushRetourExpress = 'PushRetourExpress',
  PushFinalisationOptionSansEco = 'PushFinalisationOptionSansEco',
  PushFinalisationOptionAvecEco = 'PushFinalisationOptionAvecEco',
  PushEchangeProactif = 'PushEchangeProactif',
  CompositionTrainDoorToDoor = 'CompositionTrainDoorToDoor',
  CompositionTrainTableauDeGare = 'CompositionTrainTableauDeGare',
  CompositionConsultationCommande = 'CompositionConsultationCommande',
  PushAlerteResaNoTrain = 'PushAlerteResaNoTrain',
  PushAlerteResaMoreTrain = 'PushAlerteResaMoreTrain',
  PushAlertePetitPrix = 'PushAlertePetitPrix',
  PushAlerteMax = 'PushAlerteMax',
  PushAlertePetitPrixValide = 'PushAlertePetitPrixValide',
  PushAlerteMaxValide = 'PushAlerteMaxValide',
  PushAlerteMaxAutoValide = 'PushAlerteMaxAutoValide',
  PushAlerteResaAuTrain = 'PushAlerteResaAuTrain',
  PushXsellAvis = 'XsellAvis',
  PushXsellAccor = 'XsellAccor',
  PushXsellBagages = 'XsellBagages',
  PushXsellVTC = 'XsellVTC',
  PushXsellBarTGV = 'XsellBarTGV',
  PushXsellBarIntercite = 'XsellBarIntercite',
  PushAssurance = 'PushAssurance',
  PushOptInGeneric = 'PushOptInGeneric',
  PushRenouvellementProactifCCL = 'RenouvellementProactifCCL',
  PushEligibiliteOption = 'EligibiliteOption',
  PushEligibiliteSplitPayment = 'EligibiliteSplitPayment',
  PushAssistanceEnGare = 'AssistanceEnGare',
  PushRetrainspectiveCCL = 'PushRetrainspectiveCCL',
  RechercheExistante = 'RechercheExistante',
  DetailTrainSupprime = 'DetailTrainSupprime',
  ConfirmationEnregistrementCompagnon = 'ConfirmationEnregistrementCompagnon',
}

export enum PushType {
  INFORMATION = 'IV_Information',
  POSITIVE = 'IV_Positif',
  WARNING = 'IV_Warning',
  ERROR = 'IV_Erreur',
}
export enum ExcludeTrackingFrontPageName {
  AjoutProduit = 'AjoutProduit',
}

export enum PageName {
  AssistanceEnGarePFU = 'AssistanceEnGarePFU',
  AssistanceEnGarePRR = 'AssistanceEnGarePRR',
  Interstitiel = 'Interstitiel',
  RechercheRedirection = 'RechercheRedirection',
  RechercheOD = 'RechercheOD',
  Devis = 'Devis',
  DevisAller = 'DevisAller',
  DevisRetour = 'DevisRetour',
  ResultatsItineraires = 'ResultatsItineraires',
  TERPlacesNonReservees = 'TERPlacesNonReservees',
  FicheTrajet = 'FicheTrajet',
  TrainDetails = 'TrainDetails',
  Panier = 'Panier',
  Services = 'Services',
  PaiementAnimaux = 'PaiementAnimaux',
  Coordonnees = 'Coordonnees',
  Paiement = 'Paiement',
  PaymentValidation = 'paymentValidation',
  ConfirmationCommande = 'ConfirmationCommande',
  OptionsEnCours = 'OptionsEnCours',
  DetailOption = 'DetailOption',
  ConditionsTarifaires = 'ConditionsTarifaires',
  ModeDeRetrait = 'ModeDeRetrait',
  AccueilJustificatifs = 'AccueilJustificatifs',
  ExportJustificatifMultiple = 'ExportJustificatifMultiple',
  Ticketing = 'Ticketing',
  RetrouverReferenceCommande = 'RetrouverReferenceCommande',
  Homepage = 'Homepage',
  CatalogueHomepage = 'Catalogue',
  CancellationQuotationCard = 'CancellationQuotationCard',
  CancellationQuotationPass = 'CancellationQuotationPass',
  ConfirmationCardCancellationFinalization = 'confirmationCardCancellationFinalization',
  ConfirmationCancellationFinalization = 'confirmationCancellationFinalization',
  ConfirmationCancellationPreReservation = 'confirmationCancellationPreReservation',
  ConsultationTripDetail = 'consultationTripDetail',
  ConsultationTripSearchResults = 'consultationTripSearchResults',

  // Taxi VTC
  RideHailingSearch = 'RechercheVTC',
  RideHailingContact = 'ContactDetailsVTC',
  RideHailingEstimates = 'ResultatsVTC',
  RideHailingOrder = 'RideVTC',
  RideHailingOrders = 'HistoriqueCoursesVTC_TAXI',
  RideHailingPayment = 'PaiementVTC_TAXI',
  RideHailingPaymentValidation = 'PaiementValidationVTC_TAXI',
  RideHailingHelp = 'RideHailingHelp',
  RideHailingHelpSubPage = 'RideHailingHelpSubPage',

  StationTimetableSearch = 'HorairesEnGare',
  StationTimetableResults = 'TableauDeGare',
  Station = 'InfosGare',

  // Exchange
  EchangeSelectionTrajetPassager = 'EchangeSelectionTrajetPassager',
  EchangeSelectionTrajet = 'EchangeSelectionTrajet',
  EchangeSelectionPassager = 'EchangeSelectionPassager',
  EchangeMoteur = 'EchangeMoteur',
  EchangeDevisAller = 'EchangeDevisAller',
  EchangeDevisRetour = 'EchangeDevisRetour',
  EchangePanier = 'EchangePanier',
  EchangePaiement = 'EchangePaiement',
  EchangeModifierOuigo = 'EchangeModifierOuigo',
  ConfirmationEchange = 'ConfirmationEchange',
  DemandeCompensation = 'DemandeCompensation',
  EchangePlacement = 'EchangePlacement',
  EchangeBagages = 'EchangeBagages',

  // annulation
  AnnulationPanier = 'AnnulationPanier',
  AnnulationSelectionTrajetPassager = 'AnnulationSelectionTrajetPassager',
  InfosAnnulationChequesVacances = 'InfosAnnulationChequesVacances',
  InfosRemboursementChequesVacances = 'InfosRemboursementChequesVacances',
  ConfirmationAnnulation = 'ConfirmationAnnulation',

  // CCL*
  CCLAccueil = 'CCLAccueil',
  CCLCreationCompteEmail = 'CCLCreationCompteEmail',
  CCLCreationCompteMotdePasse = 'CCLCreationCompteMotdePasse',
  CCLCreationCompteCoordonnees = 'CCLCreationCompteCoordonnees',
  CCLCreationCompteConfirmation = 'CCLCreationCompteConfirmation',
  CCLCreationCompteLienExpire = 'CCLCreationCompteLienExpire',
  CCLCreationCompteLienActif = 'CCLCreationCompteLienActif',
  CCLCreationCompteLienInvalide = 'CCLCreationCompteLienInvalide',
  CCLConnexion = 'ConnexionCCL',
  CCLCompagnons = 'CCLCompagnons',
  CCLMesInformations = 'CCLMesInformations',
  CCLEspacePro = 'CCLEspacePro',
  CCLEspaceProInfo = 'CCLEspaceProInfo',
  MesVoyages = 'MesVoyages',
  MesVoyagesPasses = 'MesVoyagesPasses',
  VoyageDeplieRetour = 'VoyageDeplieRetour',
  VoyageDeplieAller = 'VoyageDeplieAller',
  VoyageDeplieSejour = 'VoyageDeplieSejour',
  RechercheTitre = 'RechercheTitre',
  CCLMoyensPaiement = 'CCLMoyensPaiement',
  CCLModificationMoyensPaiement = 'CCLModificationMoyensPaiement',
  KiOuiConnexion = 'KiOuiConnexion',
  CCLCompagnonAjouter = 'CCLCompagnonAjouter',
  CCLCompagnonModifier = 'CCLCompagnonModifier',
  CCLCompagnonsCarte = 'CCLCompagnonsCarte',
  CCLCartesProgrammes = 'CCLCartesProgrammes',
  CCLAnimalAjouter = 'CCLAnimalAjouter',
  CCLGestionDeConsentement = 'CCLGestionDeConsentement',
  CCLNewslettersFrance = 'CCLNewslettersFrance',
  CCLNewslettersAutresPays = 'CCLNewslettersAutresPays',
  CCLCodeEntreprise = 'CCLCodeEntreprise',
  InfoTrafic = 'InfoTrafic',
  InfoTraficIdfDetails = 'InfoTraficIdfDetails',
  RenouvellementCarteCo = 'RenouvellementCarteCo',
  CCLCreationAlerteResa = 'CCLCreationAlerteResa',
  CCLSelectionAlerteResa = 'CCLSelectionAlerteResa',
  CCLConsultationAlerteResa = 'CCLConsultationAlerteResa',
  JustificatifExportMultiple = 'JustificatifExportMultiple',

  // alerte
  AlertePetitPrixConsultation = 'AlertePetitPrixConsultation',
  AlerteMaxConsultation = 'AlerteMaxConsultation',
  AlerteMaxAutoConsultation = 'AlerteMaxAutoConsultation',
  AlertePetitPrixTrajet = 'AlertePetitPrixTrajet',
  AlertePetitPrixCreneau = 'AlertePetitPrixCreneau',
  AlertePetitPrixPreferences = 'AlertePetitPrixPreferences',
  AlertePetitPrixValider = 'AlertePetitPrixValider',
  AlerteMaxParametrageAuto = 'AlerteMaxParametrageAuto',
  AlerteMaxParametrage = 'AlerteMaxParametrage',
  AlerteMaxTrajet = 'AlerteMaxTrajet',
  AlerteMaxChoixCreation = 'AlerteMaxChoixCreation',
  AlerteCreationAlerteAuTrain = 'CreationAlerteAuTrain',

  // MASC
  RetrainspectiveIntro = 'Retrainspective1',
  RetrainspectiveDistanceTitle = 'Retrainspective2',
  RetrainspectiveDistanceData = 'Retrainspective3',
  RetrainspectiveDistanceBadge = 'Retrainspective4',
  RetrainspectiveGreenTitle = 'Retrainspective5',
  RetrainspectiveGreenData = 'Retrainspective6',
  RetrainspectiveGreenBadge = 'Retrainspective7',
  RetrainspectiveDestinationTitle = 'Retrainspective8',
  RetrainspectiveDestinationData1 = 'Retrainspective9',
  RetrainspectiveDestinationData2 = 'Retrainspective10',
  RetrainspectiveDestinationBadge = 'Retrainspective11',
  RetrainspectiveActivityTitle = 'Retrainspective12',
  RetrainspectiveActivityData = 'Retrainspective13',
  RetrainspectiveOutro = 'Retrainspective14',
}

export enum ClickEventId {
  DemanderUneAssistance = 'DemanderUneAssistance',
  PropositionBBC = 'PropositionBBC',
  DemandeG30 = 'DemandeG30',
  ClicDetailTrajet = 'ClicDetailTrajet',
  AjoutCalendrier = 'AjoutCalendrier',
  PlanReserverVotreTransporteur = 'PlanReserverVotreTransporteur',
  ClicAlerteResaNoTrain = 'ClicAlerteResaNoTrain',
  ClicAlerteResaMoreTrain = 'ClicAlerteResaMoreTrain',
  RechercheLibre = 'RechercheLibre',
  RechercheAutoCompletion = 'RechercheAutoCompletion',
  BotAide = 'BotAide',
  ClicUpsellPremiere = 'ClicUpsellPremiere',
  EchangeOuigoContinuer = 'EchangeOuigoContinuer',
  EchangeOuigoRedirection = 'EchangeOuigoRedirection',
  TelechargementJustificatif = 'TelechargementJustificatif',
  AlertePetitPrixSuppression = 'AlertePetitPrixSuppression',
  AlerteMaxSuppression = 'AlerteMaxSuppression',
  XsellAvis = 'XsellAvis',
  XsellAccor = 'XsellAccor',
  MasquerRenouvellementProactifCCL = 'MasquerRenouvellementProactifCCL',
  TrajetRecoML = 'TrajetRecoML',
  MessageInfoTraficIdf = 'MessageInfoTraficIdf',
  InfosCompletesGare = 'InfosCompletesGare',
  ClicAlerteResaAuTrain = 'ClicAlerteResaAuTrain',
  CreationAlerteAuTrain = 'CreationAlerteAuTrain',
  AlerteResaCreation = 'AlerteResaCreation',
  AlerteResaConfirmation = 'AlerteResaConfirmation',
  RecommencerRetrainspective = 'RecommencerRetrainspective',
  MasquerRetrainspectiveCCL = 'MasquerRetrainspectiveCCL',
  EnregistrementAdresse = 'EnregistrementAdresse',
  ConsultationAdresse = 'ConsultationAdresse',
  SuppressionAdresse = 'SuppressionAdresse',
  AjoutAdresse = 'AjoutAdresse',
  RechercheExistante = 'RechercheExistante',
  WalletAncrageConf = 'WalletAncrageConf',
  WalletAncrageVoyageDeplieAller = 'WalletAncrageVoyageDeplieAller',
  WalletAncrageVoyageDeplieRetour = 'WalletAncrageVoyageDeplieRetour',
  ModifierDateCoordonnees = 'ModifierDateCoordonnees',
  RenouvelerRecherche = 'RenouvelerRecherche',
  InfosCompletesServices = 'InfosCompletesServices',
  InfosCompletesEquipements = 'InfosCompletesEquipements',
  AnnulerChequesVacances = 'AnnulerChequesVacances',
  InfosBonAchat = 'InfosBonAchat',
  RetourVoyage = 'RetourVoyage',
  ExitToutOui = 'ExitToutOui',
  AdhererContratPro = 'AdhererContratPro',
  DetailTrainSupprime = 'DetailTrainSupprime',
  VoyagePro = 'VoyagePro',
  InfosPro = 'InfosPro',
  FCE = 'FCE',
  TitresTransportPro = 'TitresTransportPro',
  ExportJustificatifsPro = 'ExportJustificatifsPro',
  BonsPlans = 'BonsPlans',
  Assistance = 'Assistance',
  AccueilPro = 'AccueilPro',
  FiltrePro = 'FiltrePro',
  FiltrePerso = 'FiltrePerso',
  ExportsPro = 'ExportsPro',
  ConsentUpdate = 'ConsentUpdate',
}

export type UniversalSearchType =
  | 'suggestions'
  | 'commonSearches'
  | 'citiesAndStations'
  | 'addresses'
  | 'pois'
  | 'trends'
  | 'recentSearches'

// Theme used by the user.
// Native = Theme by default unchanged,
// Custom = Theme changed by the user from the app
// System = Theme changed by the user from the OS System
export enum ColorTheme {
  DarkMode_Native = 'DarkMode_Native',
  DarkMode_Custom = 'DarkMode_Custom',
  LightMode_Custom = 'LightMode_Custom',
  DarkMode_System = 'DarkMode_System',
  LightMode_System = 'LightMode_System',
}

export enum SearchUsage {
  LIBRE = 'LIBRE',
  AUTOCOMPLETION = 'AUTOCOMPLETION',
  SUGGESTION = 'SUGGESTION',
  HISTORIQUE = 'HISTORIQUE',
}

export class TrackingSdk extends AbstractSdk<TrackingControllerApi> {
  readonly storageSdk: StorageSdk

  constructor(...[configuration, ...rest]: AbstractSdkConstructorParameters) {
    super(configuration, ...rest)
    this.storageSdk = this.userSdk.storageSdk
  }

  protected getControllerApiConstructor(): ControllerApiConstructor<TrackingControllerApi> {
    return TrackingControllerApi
  }

  init(): void {
    this.initVisitorType()
    this.initPreviousPageDatas()
    this.initDeferredEvents()
  }

  initFirstVisit(): void {
    const rawFirstVisitDatas = this.storageSdk.localStorage()?.getItem(FIRST_VISIT_STORAGE)

    if (!rawFirstVisitDatas) {
      const newValue = new FirstVisitData(new Date()).resetExpirationDate()
      this.storageSdk.localStorage()?.setItem(FIRST_VISIT_STORAGE, FirstVisitDataMapper.toJSON(newValue))
    }
  }

  initVisitorType(): void {
    this.initFirstVisit()
    const firstVisitData: FirstVisitData = FirstVisitDataMapper.fromJSON(
      this.storageSdk.localStorage()?.getItem(FIRST_VISIT_STORAGE)
    )
    const visitorTypeData: VisitorTypeData = VisitorTypeDataMapper.fromJSON(
      this.storageSdk.localStorage()?.getItem(VISITOR_TYPE_STORAGE)
    )

    if (
      (visitorTypeData.visitorType === VisitorType.NEW_USER && firstVisitData.isExpired()) ||
      visitorTypeData.isExpired()
    ) {
      this.updateVisitorType(visitorTypeData, firstVisitData)
    } else {
      // rewrite object in case of corruption
      this.storageSdk.localStorage()?.setItem(FIRST_VISIT_STORAGE, FirstVisitDataMapper.toJSON(firstVisitData))
      this.storageSdk.localStorage()?.setItem(VISITOR_TYPE_STORAGE, VisitorTypeDataMapper.toJSON(visitorTypeData))
    }
  }

  private updateVisitorType(visitorTypeData: VisitorTypeData, firstVisitData: FirstVisitData) {
    if (visitorTypeData.isExpired()) {
      visitorTypeData.visitorType = VisitorType.NEW_USER
      visitorTypeData.resetExpirationDate()
      firstVisitData = firstVisitData.resetExpirationDate()
    } else if (firstVisitData.isExpired()) visitorTypeData.visitorType = VisitorType.KNOWN_USER
    else visitorTypeData.visitorType = VisitorType.NEW_USER
    this.storageSdk.localStorage()?.setItem(FIRST_VISIT_STORAGE, FirstVisitDataMapper.toJSON(firstVisitData))
    this.storageSdk.localStorage()?.setItem(VISITOR_TYPE_STORAGE, VisitorTypeDataMapper.toJSON(visitorTypeData))
  }

  initPreviousPageDatas(): void {
    if (this.storageSdk.sessionStorage()?.getItem(NAVIGATION_PREVIOUS_STORAGE) === null) {
      const initData: PreviousData = {
        previousPageName: '',
        clickPosition: '',
      }
      this.storageSdk.sessionStorage()?.setItem(NAVIGATION_PREVIOUS_STORAGE, JSON.stringify(initData))
    }
  }

  private initDeferredEvents(): void {
    if (this.storageSdk.sessionStorage()?.getItem(EVENTS_PUSH_STORAGE) == null) {
      this.updateDeferredEventsPush(null)
    }

    if (this.storageSdk.sessionStorage()?.getItem(EVENTS_CLICK_STORAGE) == null) {
      this.updateDeferredEventsClick(null)
    }
  }

  private async getS2sVendors(): Promise<string[]> {
    try {
      const response = await this.api.getS2sVendors(this.userSdk.getBffHeader(), this.userSdk.createAxiosOptions(false))
      const { vendors } = response.data
      this.storageSdk.localStorage()?.setItem(CONSENT_VENDORS_FILTER_STORAGE, vendors.join())

      return vendors
    } catch (error) {
      return this.storageSdk.localStorage()?.getItem(CONSENT_VENDORS_FILTER_STORAGE)?.split(',') ?? new Array('')
    }
  }

  private isConsentInitialized(): boolean {
    return this.storageSdk.localStorage()?.getItem(CONSENT_ID_STORAGE) != null
  }

  private isExemptionActive(userVendors: unknown[] | string): boolean {
    return !userVendors.includes('c:omniture-adobe-analytics') && !userVendors.includes('omniture-adobe-analytics')
  }

  private initializeConsentIds(isExemptionActive: boolean): [string, string] {
    const visitorId = this.userSdk.getOrInitVisitorId() ?? this.userSdk.createVisitorId()
    const newId = this.userSdk.createVisitorId()

    return isExemptionActive ? [newId, visitorId] : [visitorId, newId]
  }

  private updateExemptId(isExemptionActive: boolean): string | undefined {
    const wasExemptionActive = this.isExemptionActive(this.userSdk.getConsentVendors())

    return !wasExemptionActive && isExemptionActive ? this.userSdk.createVisitorId() : undefined
  }

  private async filterVendors(userVendors: unknown[]): Promise<string> {
    if (userVendors.length < 1) {
      return ''
    }
    const s2sVendors = await this.getS2sVendors()
    const filteredVendors = Array.from(s2sVendors).filter((vendor: string) => {
      const vendorNumber = parseInt(vendor, 10)

      return userVendors.includes(Number.isNaN(vendorNumber) ? vendor : vendorNumber)
    })

    return filteredVendors.join()
  }

  async setSearchUsage(usage: SearchUsage): Promise<void> {
    this.storageSdk.sessionStorage()?.setItem(SEARCH_USAGE_STORAGE, usage)
  }

  async updateConsentData(userVendors: unknown[], consentString: string): Promise<void> {
    const consentVendors = userVendors.join()
    const previousVendors = this.storageSdk.localStorage()?.getItem(CONSENT_VENDORS_ALL_STORAGE)

    if (consentVendors !== previousVendors) {
      // Process consent IDs
      let consentId
      let exemptId
      const hasExemption = this.isExemptionActive(userVendors)

      if (this.isConsentInitialized()) {
        exemptId = this.updateExemptId(hasExemption)
      } else {
        ;[consentId, exemptId] = this.initializeConsentIds(hasExemption)
      }

      // Process vendors
      const filteredVendors = await this.filterVendors(userVendors)

      // Update storage (all vendors for web tags + filtered vendors for S2S)
      this.storageSdk.localStorage()?.setItem(CONSENT_VENDORS_ALL_STORAGE, consentVendors)
      this.userSdk.updateUserConsentData(filteredVendors, consentString, consentId, exemptId)

      // trackCLick pour notifier la CDP du changement de cst
      await this.trackClick(ClickEventId.ConsentUpdate, this.userSdk.getLastTrackedPage(), false)

      // Update pending-consent events if any
      const eventToConsent = this.getDeferredEventToConsent()

      if (eventToConsent) {
        const response = await this.updateWithConsent(eventToConsent)

        if (response.ok) {
          this.replaceDeferredEventToConsent(null)
        }
      }
    }
  }

  async updateMissingConsentData(userVendors: unknown[]): Promise<void> {
    if (this.isConsentInitialized() && this.storageSdk.localStorage()?.getItem(CONSENT_VENDORS_ALL_STORAGE) == null) {
      // Add all vendors to storage (for web tags)
      this.storageSdk.localStorage()?.setItem(CONSENT_VENDORS_ALL_STORAGE, userVendors.join())
    }
  }

  /**
   * Iterate recursively through DOM to find an element to identify click position via data-attributes
   */
  findClickPosition(el: HTMLElement): string {
    let link: string | null = null
    let block: string | null = null
    let element: HTMLElement | null = el

    do {
      if (link === null && element.dataset.rfrrlink !== undefined) {
        link = element.dataset.rfrrlink
      }

      if (block === null && element.dataset.rfrrblock !== undefined) {
        block = element.dataset.rfrrblock
      }

      element = element.parentElement
    } while (element && (link == null || block == null))

    const rfrrs = [link, block].filter((item): item is string => !!item)

    if (rfrrs.length === 0) {
      return ''
    }

    return rfrrs.join(':')
  }

  updateClickPosition(evt: MouseEvent): void {
    const newClickPosition = this.findClickPosition(evt.target as HTMLElement)
    const previousData = this.storageSdk.getNavigationPrevious()

    if (previousData) {
      const { previousPageName } = previousData
      const objData: PreviousData = {
        previousPageName,
        clickPosition: newClickPosition,
      }
      this.storageSdk.sessionStorage()?.setItem(NAVIGATION_PREVIOUS_STORAGE, JSON.stringify(objData))
    }
  }

  updatePreviousPageName(pageName = ''): void {
    const previousData: PreviousData = {
      previousPageName: pageName,
      clickPosition: '',
    }
    this.storageSdk.sessionStorage()?.setItem(NAVIGATION_PREVIOUS_STORAGE, JSON.stringify(previousData))
  }

  updateEmails(emailHidden?: string, emailStrong?: string, emailStronger?: string): void {
    if (emailHidden?.trim() && this.userSdk.getEmailHidden() !== emailHidden)
      this.userSdk.setEmailHidden(emailHidden?.trim())
    if (emailStrong?.trim() && this.userSdk.getEmailStrong() !== emailStrong)
      this.userSdk.setEmailStrong(emailStrong?.trim())
    if (emailStronger?.trim() && this.userSdk.getEmailStronger() !== emailStronger)
      this.userSdk.setEmailStronger(emailStronger?.trim())
  }

  updateDatalayer(datalayer?: MetadataContent, forcePreviousPage = false): void {
    try {
      if (forcePreviousPage) {
        this.updatePreviousPageName(datalayer?.stringValues?.page_name)
      }
      const deferredEventToConsent = datalayer?.json

      if (deferredEventToConsent) {
        this.replaceDeferredEventToConsent(deferredEventToConsent)
      }
      this.updateEmails(
        datalayer?.stringValues?.user_emails_hidden,
        datalayer?.stringValues?.user_emails_strong,
        datalayer?.stringValues?.user_emails_stronger
      )
    } catch (error) {}
  }

  private updateDeferredEventsPush = (events: Set<TrackedEvent> | null): void => {
    this.storageSdk.sessionStorage()?.setItem(EVENTS_PUSH_STORAGE, JSON.stringify(Array.from(events || [])))
  }

  private updateDeferredEventsClick = (events: Set<TrackedEvent> | null): void => {
    this.storageSdk.sessionStorage()?.setItem(EVENTS_CLICK_STORAGE, JSON.stringify(Array.from(events || [])))
  }

  private async trackEventRequest(trackedEventRequest: TrackedEventRequest): Promise<TrackingResponseSuccess> {
    try {
      const response = await this.api.trackEvents(
        this.userSdk.getBffHeader(),
        trackedEventRequest,
        this.userSdk.createAxiosOptions(false)
      )

      return response.data
    } catch (error) {
      return { ok: false }
    }
  }

  async trackEvent(
    eventName: string,
    eventType: TrackedEventEventTypeEnum,
    pageName = '',
    timestamp = Date.now(),
    allowDeferred = true,
    eventParams?: Record<string, string>,
    isInternal = false
  ): Promise<TrackingResponseSuccess> {
    try {
      if (eventType === 'PUSH' && allowDeferred) {
        this.deferEventPush(eventName, pageName, timestamp, eventParams)

        return { ok: true }
      }

      if (eventType === 'CLICK' && allowDeferred) {
        this.deferEventClick(eventName, pageName, timestamp, eventParams)

        return { ok: true }
      }
      const trackedEvent: TrackedEvent = {
        event: eventName,
        eventType,
        pageName,
        timestamp,
        eventParams,
        isInternal,
      }

      return await this.trackEvents(trackedEvent)
    } catch (err) {
      return { ok: false }
    }
  }

  private deferEventPush(
    pushName: string,
    pageName: string,
    timestamp: number,
    eventParams?: Record<string, string>
  ): void {
    const events = this.userSdk.getDeferredEventsPush()

    const trackedEvent: TrackedEvent = {
      event: pushName,
      eventType: 'PUSH',
      pageName,
      timestamp,
      eventParams,
      isInternal: true,
    }

    events.add(trackedEvent)

    this.updateDeferredEventsPush(events)
  }

  private deferEventClick(
    clickName: string,
    pageName: string,
    timestamp: number,
    eventParams?: Record<string, string>
  ): void {
    const events = this.userSdk.getDeferredEventsClick()

    const trackedEvent: TrackedEvent = {
      event: clickName,
      eventType: 'CLICK',
      pageName,
      timestamp,
      eventParams,
      isInternal: true,
    }

    events.add(trackedEvent)
    this.updateDeferredEventsClick(events)
  }

  private getDeferredEventToConsent(): string | null {
    return this.storageSdk.sessionStorage()?.getItem(EVENT_TO_CONSENT_STORAGE) ?? null
  }

  private replaceDeferredEventToConsent = (event: string | null): void => {
    if (event) {
      this.storageSdk.sessionStorage()?.setItem(EVENT_TO_CONSENT_STORAGE, event)
    } else {
      this.storageSdk.sessionStorage()?.removeItem(EVENT_TO_CONSENT_STORAGE)
    }
  }

  private async trackEvents(...trackedEvents: TrackedEvent[]): Promise<TrackingResponseSuccess> {
    const trackedEventRequest: TrackedEventRequest = {
      events: trackedEvents,
    }

    return this.trackEventRequest(trackedEventRequest)
  }

  addHeadersToAxiosIfNotExist(
    axiosOptions: AugmentedAxiosRequestConfig,
    headers?: RawAxiosRequestHeaders
  ): AugmentedAxiosRequestConfig {
    if (!headers) {
      return axiosOptions
    }

    return {
      ...axiosOptions,
      headers: { ...headers, ...axiosOptions.headers },
    }
  }

  private async genericTrackPage(
    trackingRequest: TrackingRequest,
    headers?: RawAxiosRequestHeaders
  ): Promise<TrackingResponseSuccess> {
    try {
      const axiosOptions = this.addHeadersToAxiosIfNotExist(this.userSdk.createAxiosOptions(false), headers)
      const request = this.appendDeferredEvents(trackingRequest)
      const responseData = (
        await this.api.trackPage(trackingRequest.pageName, this.userSdk.getBffHeader(), request, axiosOptions)
      ).data
      this.updateDatalayer(responseData.datalayer)

      return responseData
    } catch (error) {
      return { ok: false }
    }
  }

  async trackPage(pageName: string, headers?: RawAxiosRequestHeaders): Promise<TrackingResponseSuccess> {
    return this.genericTrackPage({ pageName }, headers)
  }

  async trackPlanPage(pageName: string, region: string | null = null): Promise<TrackingResponseSuccess> {
    return this.genericTrackPage({ pageName, pageRegion: region || undefined })
  }

  async trackIvPage(pageName: string, transporters: string[] | null = null): Promise<TrackingResponseSuccess> {
    return this.genericTrackPage({ pageName, pageTransporters: transporters || undefined })
  }

  async trackExternalPage({
    pageName,
    pageSeoType,
    pageSectionType,
  }: TrackingRequest): Promise<TrackingResponseSuccess> {
    return this.genericTrackPage({
      pageName,
      pageSeoType,
      pageSectionType,
    })
  }

  async trackClick(clickId: string | undefined, pageName: string, isDeferred = true): Promise<TrackingResponseSuccess> {
    if (clickId === undefined) {
      return { ok: false }
    }
    let eventName

    try {
      const url = new URL(clickId)
      eventName = url.origin + url.pathname
    } catch (e) {
      eventName = clickId
    }

    if (isDeferred) {
      this.deferEventClick(eventName, pageName, Date.now())

      return { ok: true }
    }

    try {
      const trackedEventRequest: TrackedEventRequest = {
        events: [
          {
            pageName,
            event: eventName,
            eventType: 'CLICK',
            timestamp: Date.now(),
            isInternal: false,
          },
        ],
      }
      const axiosOptions = this.userSdk.createAxiosOptions(false)
      const response = await this.api.trackEvents(this.userSdk.getBffHeader(), trackedEventRequest, axiosOptions)

      return response.data
    } catch (error) {
      return { ok: false }
    }
  }

  async trackError(
    type: string,
    source: string,
    message: string,
    params?: Record<string, string>,
    subtype?: string
  ): Promise<TrackingResponseSuccess> {
    try {
      if (params) {
        Object.keys(params).forEach((key) => {
          if (params[key] === '') {
            delete params[key]
          }
        })
      }
      const error: TrackedFrontError = {
        type,
        source,
        message,
        params,
        subtype,
        timestamp: Date.now(),
        isInternal: false,
      }
      const axiosOptions = this.userSdk.createAxiosOptions(false)
      const response = await this.api.trackError(type ?? 'UNTYPED', this.userSdk.getBffHeader(), error, axiosOptions)

      return response.data
    } catch (error) {
      return { ok: false }
    }
  }

  async updateWithConsent(event: string): Promise<TrackingResponseSuccess> {
    try {
      const updateRequest: TrackingUpdateConsentRequest = { event }
      const axiosOptions = this.userSdk.createAxiosOptions(false)
      const responseData = (await this.api.updateWithConsent(this.userSdk.getBffHeader(), updateRequest, axiosOptions))
        .data
      this.updateDatalayer(responseData.datalayer)

      return responseData
    } catch (error) {
      return { ok: false }
    }
  }

  private appendDeferredEvents(request: TrackingRequest): TrackingRequest {
    const deferredEventsPush = this.userSdk.getDeferredEventsPush()
    const deferredEventsClick = this.userSdk.getDeferredEventsClick()
    this.updateDeferredEventsPush(null)
    this.updateDeferredEventsClick(null)

    return {
      ...request,
      deferredEvents: [...this.flatEvents(deferredEventsPush), ...this.flatEvents(deferredEventsClick)],
    }
  }

  private flatEvents(events: Set<TrackedEvent>): TrackedEvent[] {
    return Array.from(events).flatMap((event) =>
      event.event.split(',').map((name) => ({
        ...event,
        event: name,
      }))
    )
  }
}

export const getMarketLanguage = (): string => {
  const htmlLangAttribute = document.documentElement.lang as string

  if (htmlLangAttribute) {
    const htmlLang = htmlLangAttribute.split(/[_-]+/)

    if (!htmlLang[1]) {
      return `${htmlLang[0]}_${htmlLang[0].toUpperCase()}`
    }

    return `${htmlLang[0]}_${htmlLang[1].toUpperCase()}`
  }

  return 'fr_FR'
}

export const getTrackingOs = (osName?: string, osVersion?: string): string | 'unknown' => {
  if (!osName) {
    return 'unknown'
  }

  return osVersion ? `${osName} (${osVersion})` : osName
}

export const getTrackingDeviceType = (deviceType?: string): string => {
  switch (deviceType) {
    case undefined:
    case 'browser':
      return 'desktop'
    case 'mobile':
      return 'responsive'
    default:
      return deviceType
  }
}

export const getTrackingShortItineraryEventName = (itinerary?: ShortItinerary): string | undefined => {
  if (itinerary?.catalogCtaInfo?.code?.toLocaleLowerCase() === 'nomad') {
    return 'NomadCar'
  }

  return undefined
}
