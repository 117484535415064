import { FC, memo, useState } from 'react'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { getMonth, isBefore } from 'date-fns'

import { LargeUp } from '@Components/utils/media/Responsive'
import { useIsInModal } from '@DS/components/navigation/modal/ModalContext'
import { ReactComponent as LeftArrowIcon } from '@Svg/navigationAndAction/back-1.svg'

import Months, { MonthsProps } from './Months'
import * as styles from './styles'

export type CalendarProps = Pick<
  MonthsProps,
  | 'currentTarget'
  | 'endDate'
  | 'maxDate'
  | 'mode'
  | 'onDaySelect'
  | 'restrictedDatesList'
  | 'startDate'
  | 'isDayFocusable'
> &
  Partial<Pick<MonthsProps, 'numberOfMonths' | 'scrollElementRef'>>

const Calendar: FC<CalendarProps> = ({
  numberOfMonths = 12,
  startDate,
  isDayFocusable = false,
  scrollElementRef,
  ...monthsProps
}) => {
  const theme = useTheme()
  const today = new Date()

  const [hoveredDate, setHoveredDate] = useState<Date>()
  const [pageIndex, setPageIndex] = useState<number>(
    !startDate || isBefore(startDate, today) ? 0 : (12 + getMonth(startDate) - getMonth(today)) % 12
  )

  const onPageChange = (direction: number) => {
    setPageIndex(pageIndex + direction)
  }

  const isInModal = useIsInModal()

  return (
    <div css={styles.root(theme, isInModal)}>
      {!isInModal && (
        <LargeUp>
          <IconButton
            css={[styles.arrow(theme), styles.arrowLeft]}
            color="default"
            disabled={pageIndex <= 0}
            onClick={() => {
              onPageChange(-1)
            }}
            tabIndex={-1}
            data-test="datepicker-left-arrow"
          >
            <LeftArrowIcon css={styles.arrowIcon('left')} />
          </IconButton>
        </LargeUp>
      )}
      <Months
        {...monthsProps}
        scrollElementRef={scrollElementRef}
        hoveredDate={hoveredDate}
        numberOfMonths={numberOfMonths}
        pageIndex={pageIndex}
        setHoveredDate={setHoveredDate}
        startDate={startDate}
        isDayFocusable={isDayFocusable}
        today={today}
      />
      {!isInModal && (
        <LargeUp>
          <IconButton
            css={[styles.arrow(theme), styles.arrowRight]}
            color="default"
            disabled={pageIndex >= numberOfMonths - 2}
            onClick={() => {
              onPageChange(1)
            }}
            tabIndex={-1}
            data-test="datepicker-right-arrow"
          >
            <LeftArrowIcon css={styles.arrowIcon('right')} />
          </IconButton>
        </LargeUp>
      )}
    </div>
  )
}

export default memo<CalendarProps>(Calendar)
