import { ElementType, forwardRef, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Link as MaterialLink } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import NextLink from 'next/link'
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router'

import { useFullRefreshReload } from '@Hooks/useFullRefreshReload'
import Locale from '@Types/locale'
import { getInnerText } from '@Utils/html'
import { makeNextPath } from '@Utils/router'

import { LinkBaseProps } from './LinkBase.types'
import * as styles from './styles'

const LinkBase = forwardRef(
  <Component extends ElementType = 'a'>(
    {
      children,
      href,
      locale: forcedLocale,
      icon: Icon,
      title,
      variant = 'subtitle1',
      isExternal: isExternalProp = false,
      isNextRoute = false,
      isObfuscated = false,
      hasFullReloadBehavior = false,
      hasNoSpacing = false,
      hasDefaultFocusVisible = false,
      isInline = false,
      isFullWidth = false,
      iconProps,
      ...rest
    }: LinkBaseProps<Component>,
    ref: LinkBaseProps<Component>['ref']
  ): ReactElement | null => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const { push, locale: currentLocale } = useRouter()
    const { goToWithFullReload } = useFullRefreshReload()
    const component = rest.component || 'a'

    const isExternal = isExternalProp || href?.includes('://')
    const isAnchor = !!href?.startsWith('#')
    const locale = (forcedLocale || currentLocale) as Locale

    const hasToUseLink = component === 'a' && !isObfuscated

    const hasToUseNextLink =
      hasToUseLink && isNextRoute && !isExternal && !isAnchor && !hasFullReloadBehavior && typeof href !== 'undefined'

    const hrefWithPrefixAndLocale = isNextRoute ? makeNextPath(href, locale) : href
    const linkTitle = title || getInnerText(children)

    const linkProps = {
      ...(hrefWithPrefixAndLocale && ({ href: hrefWithPrefixAndLocale } satisfies LinkBaseProps<'a'>)),
      ...(isExternal &&
        ({
          target: '_blank',
          title: linkTitle ? `${linkTitle}${formatMessage({ id: 'link_newTab_titleSuffix' })}` : undefined,
          rel: rest.rel || 'noopener noreferrer',
        } satisfies LinkBaseProps<'a'>)),
    }

    const openLink = async () => {
      if (!hrefWithPrefixAndLocale) {
        return
      }

      if (!isNextRoute) {
        window.open(hrefWithPrefixAndLocale, isExternal ? '_blank' : '_self')
      } else if (hasFullReloadBehavior) {
        goToWithFullReload(hrefWithPrefixAndLocale)
      } else {
        await push(hrefWithPrefixAndLocale, undefined, { locale: false })
      }
    }

    const buttonProps = {
      component: rest.component || 'button',
      tabIndex: rest.tabIndex || 0,
      title,
      onClick: (event) => {
        rest.onClick?.(event)
        openLink()
      },
      onKeyDown: (event) => {
        if (event.key === 'Enter') {
          rest.onKeyDown?.(event)
          openLink()
        }
      },
    } satisfies LinkBaseProps<'button'>

    return (
      <MaterialLink
        {...rest}
        {...(hasToUseNextLink && { component: NextLink, locale: false })}
        {...(hasToUseLink ? linkProps : buttonProps)}
        css={styles.link(theme, hasNoSpacing, isInline, hasDefaultFocusVisible, isFullWidth)}
        ref={ref}
        role={component === 'a' && isObfuscated ? 'link' : undefined}
        variant={variant}
      >
        {children}
        {Icon && <Icon {...iconProps} css={styles.icon(isInline)} />}
      </MaterialLink>
    )
  }
)

export default LinkBase
