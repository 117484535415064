import { listItemClasses } from '@mui/material/ListItem'
import { Theme } from '@mui/material/styles'
import { ComponentsOverrides } from '@mui/material/styles/overrides'

export const overridesListItem = (theme: Theme): ComponentsOverrides['MuiListItem'] => ({
  root: {
    [`&.${listItemClasses.selected}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    [`&.${listItemClasses.selected}:hover`]: {
      backgroundColor: theme.palette.primary.dark,
    },
    [`&.${listItemClasses.focusVisible}`]: {
      backgroundColor: theme.customPalette.onElements.selected,
    },
  },
  button: {
    '&:hover': {
      backgroundColor: theme.customPalette.onElements.selected,
    },
  },
})
