import { FC } from 'react'
import { Divider as MaterialDivider, DividerProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { TypeBackground } from '@mui/material/styles/createPalette'

import * as styles from './styles'
import { SeparatorSize } from './styles'

type BorderType =
  | {
      isDashed?: boolean
      isDotted?: never
    }
  | {
      isDashed?: never
      isDotted?: boolean
    }

export type SeparatorProps = DividerProps & {
  background?: keyof TypeBackground
  size?: SeparatorSize
  isNotOutline?: boolean
} & BorderType

const Separator: FC<SeparatorProps> = ({
  background = 'default',
  className,
  isNotOutline = false,
  size = 'small',
  isDashed = false,
  isDotted = false,
  ...rest
}) => {
  const theme = useTheme()
  const isVertical = rest.orientation === 'vertical'
  const borderType = isDashed ? 'dashed' : 'dotted'

  return (
    <MaterialDivider
      {...rest}
      aria-hidden
      className={className}
      css={
        isDashed || isDotted
          ? styles.separatorDashedOrDotted(theme, isVertical, borderType)
          : styles.separator(background, !isNotOutline, isVertical, size, theme)
      }
    />
  )
}

export default Separator
