import { useCallback } from 'react'
import { Url } from 'next/dist/shared/lib/router/router'
// eslint-disable-next-line no-restricted-imports
import { NextRouter, useRouter } from 'next/router'

import { makeNextPath } from '@Utils/router'

const useRouterWrapper = (): NextRouter => {
  const router = useRouter()
  const { push, replace, locale } = useRouter()

  return {
    ...router,
    push: useCallback(
      (url: Url, as?: Url, options?: Parameters<NextRouter['push']>[2]) => {
        if (typeof url === 'string') {
          const newPathName = makeNextPath(url, locale)

          // We pass "locale: false" option because locale is already in newPathName
          return push(newPathName, as, { locale: false, ...options })
        }
        const newPathName = makeNextPath(url.pathname as string, locale)
        const newUrlObj = { ...url, pathname: newPathName }

        // We pass "locale: false" option because locale is already in newPathName
        return push(newUrlObj, as, { locale: false, ...options })
      },
      [locale, push]
    ),
    replace: useCallback(
      (url: Url, as?: Url, options?: Parameters<NextRouter['replace']>[2]) => {
        if (typeof url === 'string') {
          const newPathName = makeNextPath(url, locale)

          // We pass "locale: false" option because locale is already in newPathName
          return replace(newPathName, as, { locale: false, ...options })
        }
        const newPathName = makeNextPath(url.pathname as string, locale)
        const newUrlObj = { ...url, pathname: newPathName }

        // We pass "locale: false" option because locale is already in newPathName
        return replace(newUrlObj, as, { locale: false, ...options })
      },
      [locale, replace]
    ),
  }
}

// eslint-disable-next-line no-restricted-imports
export * from 'next/router'

export { useRouterWrapper as useRouter }
