import { FC, ReactNode } from 'react'
import { useTheme } from '@mui/material/styles'

import { useIsLargeUp, useIsMediumUp } from '@Hooks/useResponsive'

export const LargeUp: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme()
  const isLargeUp = useIsLargeUp(theme)
  if (!isLargeUp) return null

  return <>{children}</>
}

export const LargeDown: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme()
  const isLargeUp = useIsLargeUp(theme)
  if (isLargeUp) return null

  return <>{children}</>
}

export const MediumUp: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme()
  const isMediumUp = useIsMediumUp(theme)
  if (!isMediumUp) return null

  return <>{children}</>
}

export const MediumDown: FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme()
  const isMediumUp = useIsMediumUp(theme)
  if (isMediumUp) return null

  return <>{children}</>
}
