import { FC, HTMLAttributes } from 'react'
import { useTheme } from '@mui/material/styles'
import { IconId, IconSource, ShortItineraryIconGenerate } from 'invictus-sdk-typescript'

import { getLegIcon } from '@DS/components/icons/lineIcons'
import { getModeIcon } from '@DS/components/icons/modeIcons'
import VisuallyHiddenLabel from '@DS/components/utils/aria/VisuallyHiddenLabel'
import * as commonStyles from '@Utils/icons/styles'

import { getDsIcon } from '../dsIcons'

import GeneratedTransportIcon from './_internal/GeneratedTransportIcon'
import * as styles from './styles'

export type TransportIconProps = {
  iconSource: string
  iconId?: IconId
  name?: string
  label?: string
  generate?: ShortItineraryIconGenerate
  isSmallGenerated?: boolean
  isSmall?: boolean
}

const TransportIcon: FC<
  TransportIconProps & Omit<HTMLAttributes<HTMLSpanElement | SVGSVGElement>, keyof TransportIconProps>
> = ({ iconSource, iconId, name, label, generate, isSmallGenerated = false, isSmall = false, ...rest }) => {
  const theme = useTheme()

  const generateFromModeIcon = () => {
    const ModeIcon = getModeIcon(name?.toUpperCase())

    return (
      ModeIcon && <ModeIcon {...rest} css={[styles.icon(theme, isSmall, false), commonStyles.isWhcm('Highlight')]} />
    )
  }

  const generateFromDsIcon = () => {
    const DsIcon = getDsIcon(iconId)

    const isSupplierIcon = iconId?.iconGroup === 'Supplier'

    return (
      DsIcon && (
        <DsIcon {...rest} css={[styles.icon(theme, isSmall, isSupplierIcon), commonStyles.isWhcm('Highlight')]} />
      )
    )
  }

  const generateFromLineIcon = () => {
    const LineIcon = getLegIcon(name)

    return LineIcon && <LineIcon {...rest} css={[styles.icon(theme, isSmall, false), commonStyles.isWhcm()]} />
  }

  const generateFromFullyGeneratedIconIcon = () =>
    generate && (
      <GeneratedTransportIcon
        {...rest}
        {...generate}
        isSmallGenerated={isSmallGenerated || isSmall}
        css={commonStyles.isWhcm()}
      />
    )

  switch (iconSource) {
    case IconSource.MODE:
      generateFromModeIcon()
      break
    case IconSource.LINE:
      generateFromLineIcon()
      break
    case IconSource.GENERATE:
      generateFromFullyGeneratedIconIcon()
      break
    default:
      break
  }

  return (
    <>
      {iconId
        ? generateFromDsIcon()
        : {
            [IconSource.MODE]: generateFromModeIcon(),
            [IconSource.LINE]: generateFromLineIcon(),
            [IconSource.GENERATE]: generateFromFullyGeneratedIconIcon(),
          }[iconSource]}
      {iconSource !== IconSource.GENERATE && label && <VisuallyHiddenLabel label={label} />}
    </>
  )
}

export default TransportIcon
