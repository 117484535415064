// GENERATED CODE - DO NOT MODIFY BY HAND
// For all changes please modify icons-generator/src/main/resources/web/groupIcons.template file first and regenerate.
import { LazyIcon, LazyIconsGroup } from '@DS/components/icons/icons.types'
import { svgLazyLoader } from '@Utils/icons'

const AppleMessageFull = svgLazyLoader('socialNetworks/apple_message_full')
const AppleMessageOutline = svgLazyLoader('socialNetworks/apple_message_outline')
const FacebookColor = svgLazyLoader('socialNetworks/facebook_color')
const FacebookFull = svgLazyLoader('socialNetworks/facebook_full')
const FacebookOutline = svgLazyLoader('socialNetworks/facebook_outline')
const InstagramColor = svgLazyLoader('socialNetworks/instagram_color')
const InstagramFull = svgLazyLoader('socialNetworks/instagram_full')
const InstagramOutline = svgLazyLoader('socialNetworks/instagram_outline')
const LikeFull = svgLazyLoader('socialNetworks/like_full')
const LikeOutline = svgLazyLoader('socialNetworks/like_outline')
const MailFull = svgLazyLoader('socialNetworks/mail_full')
const MailOutline = svgLazyLoader('socialNetworks/mail_outline')
const MessengerFull = svgLazyLoader('socialNetworks/messenger_full')
const MessengerOutline = svgLazyLoader('socialNetworks/messenger_outline')
const PinterestColor = svgLazyLoader('socialNetworks/pinterest_color')
const PinterestFull = svgLazyLoader('socialNetworks/pinterest_full')
const PinterestOutline = svgLazyLoader('socialNetworks/pinterest_outline')
const PrintFull = svgLazyLoader('socialNetworks/print_full')
const PrintOutline = svgLazyLoader('socialNetworks/print_outline')
const TiktokFull = svgLazyLoader('socialNetworks/tiktok_full')
const TiktokOutline = svgLazyLoader('socialNetworks/tiktok_outline')
const WhatsappFull = svgLazyLoader('socialNetworks/whatsapp_full')
const WhatsappOutline = svgLazyLoader('socialNetworks/whatsapp_outline')
const XColor = svgLazyLoader('socialNetworks/x_color')
const XFull = svgLazyLoader('socialNetworks/x_full')
const XOutline = svgLazyLoader('socialNetworks/x_outline')

const ALL_SOCIAL_NETWORKS_ICONS = [
  'apple_message_full',
  'apple_message_outline',
  'facebook_color',
  'facebook_full',
  'facebook_outline',
  'instagram_color',
  'instagram_full',
  'instagram_outline',
  'like_full',
  'like_outline',
  'mail_full',
  'mail_outline',
  'messenger_full',
  'messenger_outline',
  'pinterest_color',
  'pinterest_full',
  'pinterest_outline',
  'print_full',
  'print_outline',
  'tiktok_full',
  'tiktok_outline',
  'whatsapp_full',
  'whatsapp_outline',
  'x_color',
  'x_full',
  'x_outline',
] as const
export type SocialNetworksIcon = (typeof ALL_SOCIAL_NETWORKS_ICONS)[number]

const isSocialNetworksIcon = (socialNetworksKey: string): socialNetworksKey is SocialNetworksIcon =>
  ALL_SOCIAL_NETWORKS_ICONS.includes(socialNetworksKey as SocialNetworksIcon)

const ICONS: LazyIconsGroup<SocialNetworksIcon> = {
  apple_message_full: AppleMessageFull,
  apple_message_outline: AppleMessageOutline,
  facebook_color: FacebookColor,
  facebook_full: FacebookFull,
  facebook_outline: FacebookOutline,
  instagram_color: InstagramColor,
  instagram_full: InstagramFull,
  instagram_outline: InstagramOutline,
  like_full: LikeFull,
  like_outline: LikeOutline,
  mail_full: MailFull,
  mail_outline: MailOutline,
  messenger_full: MessengerFull,
  messenger_outline: MessengerOutline,
  pinterest_color: PinterestColor,
  pinterest_full: PinterestFull,
  pinterest_outline: PinterestOutline,
  print_full: PrintFull,
  print_outline: PrintOutline,
  tiktok_full: TiktokFull,
  tiktok_outline: TiktokOutline,
  whatsapp_full: WhatsappFull,
  whatsapp_outline: WhatsappOutline,
  x_color: XColor,
  x_full: XFull,
  x_outline: XOutline,
}

export const getSocialNetworksIcon = (socialNetworksKey: string | undefined): LazyIcon | undefined =>
  socialNetworksKey && isSocialNetworksIcon(socialNetworksKey) ? ICONS[socialNetworksKey] : undefined

export const getSocialNetworksIcons = (): LazyIconsGroup<SocialNetworksIcon> => ICONS
