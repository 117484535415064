// Les fonctions ci-dessous indiquent si on peut utiliser les objets du navigateur
// (dans certains contextes comme Node ou SSR ce n'est pas possible).
// Il y a plusieurs fonctions pour minimiser le couplage.

// Est-ce que l'objet 'window' est accessible ? (Node ? SSR ?)
export const canUseWindow = (): boolean => typeof window !== 'undefined'

// Est-ce que l'objet 'window.document' est accessible ? (Node ? SSR ?)
export const canUseDom = (): boolean =>
  canUseWindow() && Boolean(window.document) && typeof window.document.createElement === 'function'

// Est-ce que l'objet 'window.navigator' est accessible ? (Node ? SSR ?)
export const canUseNavigator = (): boolean => canUseWindow() && typeof window.navigator !== 'undefined'
