import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { iconButtonClasses } from '@mui/material/IconButton'
import { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { HALF_RADIUS } from '@DS/styles/constants/radius'
import { SPACING_0, SPACING_1 } from '@DS/styles/constants/spacing'
import { highContrast } from '@Styles/a11y'
import { getFocusVisibleStyle } from '@Styles/focus'

export const inputWrapper = css({
  display: 'flex',
  flex: '100%',
  flexDirection: 'column',
})

export const input = (theme: Theme, isError = false, isFilled = false, disabled = false): SerializedStyles =>
  css(
    {
      [`.${inputAdornmentClasses.positionEnd}`]: {
        marginLeft: SPACING_0,
      },
      [`.${inputLabelClasses.root}.${inputLabelClasses.error}:not(.${inputLabelClasses.shrink})`]: {
        color: theme.palette.text.secondary,
      },
      [`.${outlinedInputClasses.adornedEnd}`]: {
        paddingRight: SPACING_0,
      },
      [`.${outlinedInputClasses.notchedOutline}`]: {
        ...(!disabled && {
          border: `solid 1px ${isFilled ? theme.palette.text.primary : theme.customPalette.onElements.outline}`,
        }),
      },
      [`.${outlinedInputClasses.root}.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: theme.palette.text.disabled,
      },
      caretColor: isError ? theme.palette.error.main : theme.palette.primary.main,
      width: '100%',
    },
    highContrast({
      border: 'solid 1px transparent',
    })
  )

export const inputButton = (theme: Theme, disabled = false): SerializedStyles =>
  css({
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [`.${iconButtonClasses.root}.${iconButtonClasses.disabled}`]: {
      color: theme.palette.text.disabled,
    },
    color: theme.palette.text[disabled ? 'disabled' : 'primary'],
    cursor: 'pointer',
  })

export const buttonFocus = (theme: Theme, isFocused = false): SerializedStyles =>
  css({
    '&::before': isFocused ? getFocusVisibleStyle(theme.palette.primary.main, HALF_RADIUS, '0') : {},
  })

export const inputButtonIcon = (theme: Theme, disabled = false): SerializedStyles =>
  css({
    ...size(rem(24)),
    fill: theme.palette.text[disabled ? 'disabled' : 'primary'],
  })

export const inputRules = css({
  listStyle: 'none',
  margin: 0,
  padding: 0,
})

export const inputRule = css({
  '&:last-child': {
    marginBottom: 0,
  },
  display: 'flex',
  marginBottom: SPACING_0,
})

export const invalidRuleLabel = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.text.secondary,
  })

export const ruleIcon = css({
  paddingRight: SPACING_0,
  ...size(rem(16)),
  minWidth: rem(16),
})

export const validRuleIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.success.main,
  })

export const invalidRuleIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.text.secondary,
  })

export const overrideHelperText: CSSProperties = {
  marginLeft: 0,
  marginRight: 0,
}

export const helperText = (theme: Theme): SerializedStyles =>
  css({
    color: theme.palette.error.main,
    display: 'flex',
  })

export const helperTextAlertIcon = (theme: Theme): SerializedStyles =>
  css({
    fill: theme.palette.error.main,
    marginRight: SPACING_1,
    minWidth: rem(16),
    ...size(rem(16)),
  })
