import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { IconButton, IconButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ReactComponent as RemoveInputIcon } from '@Svg/navigationAndAction/remove_input.svg'

import * as styles from './styles'

const RemoveInputButton: FC<IconButtonProps> = ({ ...rest }) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  const [isFocusVisible, setIsFocusVisible] = useState(false)

  return (
    <IconButton
      {...rest}
      aria-label={formatMessage({
        id: 'input_clearButton_ariaLabel',
      })}
      css={[styles.removeButton(theme), styles.buttonFocus(theme, isFocusVisible)]}
      disableFocusRipple
      size="large"
      onFocusVisible={() => setIsFocusVisible(true)}
      onBlur={() => setIsFocusVisible(false)}
    >
      <RemoveInputIcon />
    </IconButton>
  )
}

export default RemoveInputButton
